<template>
  <div class="text-container" >
  <el-card >
    <div class="text-container">
      <hr/>

      <h2> 物理</h2>

      <p>虽然选考物理可以选择专业的范围非常大，而且名校对物理的青睐愈加明显，但是选考物理的人数并没有想象的多。</p>
      <p>原因有两点：第一，原则上讲，机械、电子、土木这类专业，物理无疑是最对口的科目，但是部分高校出于招生的考虑，扩大了选科的范围，加入了化学，甚至生物等科目。虽然选考物理的选择余地最大，但如果放弃物理，选择另外三门科目，其选择面综合起来，未必比选物理小。其次，物理难度大，对学生的逻辑、抽象思维都有较高要求，学生对自己的能力也会有一定的评估判断，不能简单说，选择物理就可以上名校。</p>
      <hr/>

      <h2> 化学</h2>

      <p>总体认可度很高，杭州市第一，在上海也在第二梯队（与生物、历史差距很小），比较符合预期。</p>
      <p>化学可以选择专业的范围较广，甚至涵盖了软件工程、计算机等明显属于物理范围的专业，假如没有选择物理，再不选择化学，那么学生的选择余地会非常小。同时，化学的难度比起物理较低，大部分学生是可以搞定的，回想以前的旧高考，正是物理科目导致很多学生被迫选择文科，并不是说文科不好，只是选择专业的余地太小。</p>
      <hr/>

      <h2> 地理、历史、政治、生物</h2>

      <p>上海地理选择的人数最多，一个很重要的原因是，高二就可以参加等级性考试，到时学生会有大量时间准备其他科目，所以代表性较低。一般的，这4科的选择人数相差不会太大，生物可选择的专业可被化学大部分覆盖，只是读医药的同学，还是建议两科都选；地理、历史、政治，这3科是旧高考的文科，专业选择余地小，但是科目相对简单，选考人数会比较稳定。</p>

    </div>
  </el-card>
  </div>
</template>

<script >
export default {
  name: "frontIndex"
}
</script>

<style scoped>
/*设置文本区域的范围*/
.text-container {
  width: 84%; /* 设置文本区域宽度 */
  margin: 0 auto; /* 居中文本区域 */
  /*padding: 10px; !* 可选：添加内边距来制造空白 *!*/
}
p {
  text-indent: 20px; /* 设置首行缩进为 2 个字母的宽度 */
  text-align: left; /* 将文本对齐方式设置为左对齐 */
}

</style>