<template>
  <el-card  shadow="always">

    <el-form
        ref="ruleFormRef"
        :model="ruleForm"
        status-icon
        :rules="rules"
        label-width="100px"
        class="demo-ruleForm"
    >

    <div class="header">
      <el-text class="align-left" type="primary">修改密码</el-text>
    </div >
    <el-divider/>


      <el-form-item  prop="oldPassword" class="password">
        <div class="input-label">
          <label slot="label">旧密码</label>
        </div>
        <el-input v-model="ruleForm.oldPassword"  style="font-size: medium;width: 1000px"  type="password" autocomplete="off" placeholder="请输入旧密码" clearable />
      </el-form-item>


      <el-form-item  prop="newPassword" class="password">
        <div class="input-label">
          <label slot="label">新密码</label>
        </div>
        <el-input v-model="ruleForm.newPassword"  style="font-size: medium;width: 1000px"  type="password" autocomplete="off" placeholder="请输入新密码" clearable />
      </el-form-item>

      <el-form-item  prop="confirmPassword" class="password" >
        <div class="input-label">
          <label slot="label">确认密码</label>
        </div>
        <el-input v-model="ruleForm.confirmPassword"  style="font-size: medium;width: 1000px"  type="password" autocomplete="off" placeholder="请输入确认密码" clearable />
      </el-form-item>

      <el-form-item class="button">
        <el-button @click="submitForm()" color="#178071" size="large">
          <el-icon><Document /></el-icon>保存
        </el-button>

        <el-button @click="resetForm()" color="#d1c48f" size="large">
          <el-icon><Flag /></el-icon>重置
        </el-button>

      </el-form-item>


    </el-form>


  </el-card>
</template>

<script>
import {reactive, ref} from "vue";
import {ElMessageBox} from "element-plus";
import axios from "axios";

export default {
  name: "ChangePassword",
  setup(){
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入旧密码'))
      }else {
        // 如果密码不为空，则触发回调函数，表示验证通过
        callback();
      }
    }


    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入新密码'))
      }else {
        // 如果密码不为空，则触发回调函数，表示验证通过
        callback();
      }
    }

    const validatePass3 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入确认密码'))
      } else {
        callback()
      }
    }

    const ruleForm = reactive({
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    })

    const rules = reactive({
      oldPassword:[{validator: validatePass, trigger: 'blur'}],
      newPassword: [{validator: validatePass2, trigger: 'blur'}],
      confirmPassword: [{validator: validatePass3, trigger: 'blur'}],
    })

    const submitForm=()=>{

      //const router = useRouter();
      if((ruleForm.newPassword !== '' && ruleForm.oldPassword !== '' && ruleForm.confirmPassword !== '') && ruleForm.newPassword === ruleForm.confirmPassword  ){
        axios.post('/user/updateUserPassword', {
          oldPassword: ruleForm.oldPassword,
          newPassword: ruleForm.newPassword,

        }).then(resp => {
          // console.log(resp.data)
          if(resp.data.code === 200){

            ElMessageBox.confirm('修改密码成功', '提示', {
              confirmButtonText: '确定',
              //cancelButtonText: '取消', // 添加取消按钮
              showCancelButton: false,
              closeOnClickModal: false,
              type: 'warning',
              draggable: true,
            }).then(()=>{
              ruleForm.oldPassword = "";
              ruleForm.newPassword = "";
              ruleForm.confirmPassword = "";
            }).catch(() => {
              // 用户点击了取消按钮或关闭按钮后的处理逻辑
            });



          }else{
            console.log(resp.data)


            ElMessageBox.confirm(resp.data.message, '提示', {
              confirmButtonText: '确定',
              //cancelButtonText: '取消', // 添加取消按钮
              showCancelButton: false,
              closeOnClickModal: false,
              type: 'warning',
              draggable: true,
            }).then(()=>{
              ruleForm.oldPassword = "";
              ruleForm.newPassword = "";
              ruleForm.confirmPassword = "";
            }).catch(() => {
              // 用户点击了取消按钮或关闭按钮后的处理逻辑
            });


          }
        })
            .catch(failResponse => {})
      }
      else if(ruleForm.oldPassword === ''){
        ElMessageBox.confirm('旧密码为空', '提示', {
          confirmButtonText: '确定',
          //cancelButtonText: '取消', // 添加取消按钮
          showCancelButton: false,
          closeOnClickModal: false,
          type: 'warning',
          draggable: true,
        }).then(() => {
          // 用户点击了确定按钮后的处理逻辑
        }).catch(() => {
          // 用户点击了取消按钮或关闭按钮后的处理逻辑
        });
      }else if(ruleForm.newPassword === ''){
        ElMessageBox.confirm('新密码为空', '提示', {
          confirmButtonText: '确定',
          //cancelButtonText: '取消', // 添加取消按钮
          showCancelButton: false,
          closeOnClickModal: false,
          type: 'warning',
          draggable: true,
        }).then(() => {
          // 用户点击了确定按钮后的处理逻辑
        }).catch(() => {
          // 用户点击了取消按钮或关闭按钮后的处理逻辑
        });
      }else if(ruleForm.confirmPassword === ''){
        ElMessageBox.confirm('确认密码为空', '提示', {
          confirmButtonText: '确定',
          //cancelButtonText: '取消', // 添加取消按钮
          showCancelButton: false,
          closeOnClickModal: false,
          type: 'warning',
          draggable: true,
        }).then(() => {
          // 用户点击了确定按钮后的处理逻辑
        }).catch(() => {
          // 用户点击了取消按钮或关闭按钮后的处理逻辑
        });
      }
      else if(ruleForm.newPassword !== ruleForm.confirmPassword){
        ElMessageBox.confirm('新密码和确认密码不一致', '提示', {
          confirmButtonText: '确定',
          //cancelButtonText: '取消', // 添加取消按钮
          showCancelButton: false,
          closeOnClickModal: false,
          type: 'warning',
          draggable: true,
        }).then(() => {
          // 用户点击了确定按钮后的处理逻辑
        }).catch(() => {
          // 用户点击了取消按钮或关闭按钮后的处理逻辑
        });
      }

    }
    const resetForm =()=>{
      ruleForm.oldPassword = "";
      ruleForm.newPassword = "";
      ruleForm.confirmPassword = "";
    }
    return{
      ruleForm,
rules,
      resetForm,
submitForm
    }
  }
}
</script>

<style scoped>
.header {
  display: flex;
  align-items: flex-start;
}
.password {
  display: flex;
  align-items: flex-start;

  margin-bottom: 45px;

}

.button {
  display: flex;
  align-items: flex-start;
  margin-left: 100px;
  margin-bottom: 45px;

}
.align-left {
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  margin-right: 7px;
}
.input-label {
  text-align: right;
  margin-right: 30px; /* 可根据需要调整间距 */
  font-size: 16px;
  color: #81bb96;
  width: 70px;
}
</style>