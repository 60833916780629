<template>
  <div class="menu-container">
    <el-menu
        class="el-menu-admin"
        router
        mode="vertical"
        active-text-color="#ffd04b"
        background-color="#545c64"

        :default-active="currentPath"
        text-color="#fff"

    >
      <div style="height: 20px;"></div>



      <el-sub-menu v-for="(item, i) in adminMenus" :key="i" :index="(i).toString()" class="menu-item" style="text-align: left;margin-bottom: 10px" >

        <template #title >
          <component :is="item.iconCls" style="width: 16px;height: 16px;margin-right: 15px"></component>
        <span  style="font-size: 17px;">{{item.nameZh}}</span>
        </template>

        <template v-if="item.children && item.children.length">

            <!-- 使用 v-if 来判断是否需要创建 el-menu-item -->
            <el-menu-item v-for="child in item.children" :key="child.path" :index="child.path" >
              <component :is="child.iconCls" style="width: 16px;height: 16px;margin-right: 7px"></component>
              {{ child.nameZh }}
            </el-menu-item>

          </template>

      </el-sub-menu>


<!--
<el-menu-item :key="child.path" :index="child.path" v-if="child.name !== 'Logout'">
              <component :is="child.iconCls" style="width: 16px;height: 16px;margin-right: 7px"></component>
              {{ child.nameZh }}
            </el-menu-item>
            <template v-else>

      <el-menu-item   >
        <component :is="child.iconCls" style="width: 16px;height: 16px;margin-right: 7px"></component>
        {{ child.nameZh }}
      </el-menu-item>-->



    </el-menu>


  </div>
</template>

<script>
import { computed } from "vue";
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import axios from "axios";
import {ElMessage, ElMessageBox} from "element-plus";
import router from "@/router";

export default {
  name: "Menu",
  setup() {
    const route = useRoute()
    const store = useStore();
    const adminMenus = computed(() => {
      console.log("路由来了")
      console.log(store.state.Menus);
      return store.state.Menus;
    });

    const currentPath = computed(() => {
      return route.path;
    });
    const store1 = useStore()
    const logout = () => {
      axios.get('/com/logout').then(resp => {
        if (resp && resp.data.code === 200) {
          //resp.data.result
          ElMessageBox.confirm('确认退出系统吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消', // 添加取消按钮
            // showCancelButton: false,
            type: 'warning',
            draggable: true,
          }).then(() => {
            store1.commit('logout');
            store1.state.username = ''
            store1.state.Menus = ''
            store1.state.flag = false
            router.push('/index');
            //location.reload();
          }).catch(() => {
            ElMessage({
              type: 'info',
              message: '取消退出账户',
            })
          })
        }
      }).catch(failResponse => {});
    };

    return {
      adminMenus,
      currentPath,
      logout
    };
  },
};
</script>

<style scoped>
.el-menu-admin {
  border-radius: 5px;
  height: 100%;
}
</style>