<template>
<el-card>

  <el-dialog
      title="编辑测评记录"
      v-model="editVisible"
      :before-close="editHandleDialogClose"
  >

    <!--selectedUser：被选中的用户(在点击编辑按钮时通过获得)     prop对应属性 -->
    <el-form :model="selectedQuesUser" style="text-align: left" ref="dataForm" label-width="100px"
             :rules="rules" status-icon>


      <el-form-item prop="quesName" label="测评名称"  >
        <el-select :teleported="false" v-model="selectedQuesUser.quesName" clearable placeholder="选择测评" class="input">

          <el-option
              v-for="item in selectQuesOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          />
        </el-select>
      </el-form-item>

      <el-form-item prop="userName" label="用户" >
        <el-select :teleported="false" v-model="selectedQuesUser.userName" clearable placeholder="选择用户" class="input">

          <el-option
              v-for="item in selectUnameOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          />
        </el-select>
      </el-form-item>

      <el-form-item label="测评时间" prop="addDate" required>

        <el-date-picker
            v-model="selectedQuesUser.addDate"
            type="datetime"
            label="Pick a date and time"
            placeholder="请选择日期和时间"
            style="width: 100%"
            format="YYYY/MM/DD HH:mm:ss"
            value-format="YYYY-MM-DD HH:mm:ss"
        />
      </el-form-item>
      <el-form-item prop="score" label="测评结果" class="item">
        <el-input v-model="selectedQuesUser.score" autocomplete="off" class="input" clearable></el-input>
        <!--        <el-input v-model="selectedUser.uname" autocomplete="off" class="input"></el-input>-->
      </el-form-item>
      <el-form-item prop="result" label="专业推荐" class="item">
        <el-input v-model="selectedQuesUser.result" autocomplete="off" class="input" clearable></el-input>
        <!--        <el-input v-model="selectedUser.uname" autocomplete="off" class="input"></el-input>-->
      </el-form-item>



      <el-form-item class="button">
        <el-button @click="submitEditForm(selectedQuesUser)" color="#178071" size="large">
          <el-icon><Document /></el-icon>保存
        </el-button>

        <el-button @click="resetForm()" color="#d1c48f" size="large">
          <el-icon><Flag /></el-icon>重置
        </el-button>

      </el-form-item>
    </el-form>





  </el-dialog>

  <div style="margin-bottom: 30px" class="align-left-container">

    <el-select v-model="selectQues" clearable placeholder="选择测评名称" style="margin-right: 10px">
      <el-option
          v-for="item in selectQuesOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
      />
    </el-select>
    <el-select v-if="roleId !== 3" v-model="selectUser" clearable placeholder="选择用户名称" style="margin-right: 10px">
      <el-option
          v-for="item in selectUserOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
      />
    </el-select>

    <el-button @click="searchForm()"  size="default" type="primary">
      <el-icon><Search /></el-icon>搜索
    </el-button>
  </div>
  <el-divider />

  <div class="button1">
    <AddQuesUser v-if="roleId !== 3"  :message1="selectQuesOptions" :message2="selectUnameOptions"></AddQuesUser>
<!--    <AddQuesItem :message="selectQuesOptions"></AddQuesItem>-->
    <!--  <el-button @click="addUser()" color="#67b18e" size="large">-->
    <!--    <el-icon><Plus /></el-icon>新增普通用户-->
    <!--  </el-button>-->

    <el-button v-if="roleId !== 3" @click="deleteBatchQuesUsers()" color="#cb5765" size="large">
      <el-icon><Delete /></el-icon>批量删除
    </el-button>
  </div>
  <div >

    <el-table :data="quesUsers" border stripe style="width: 100%;"
              @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="50" class="my-table"/>
      <el-table-column prop="Id"  label="Id" width="60" class="my-table"/>

      <el-table-column prop="quesName" label="测评名称" width="120" class="my-table"/>
      <el-table-column prop="userName" label="用户" width="120" class="my-table"/>
      <el-table-column prop="addDate" label="测评时间" width="220" class="my-table"/>




      <el-table-column prop="score" label="测评结果" width="450" class="my-table"/>
      <el-table-column prop="result" label="专业推荐" width="450" class="my-table"/>


      <el-table-column

          label="操作"
          class="my-table">
        <template #default="{ row }">

          <el-button
              :disabled="roleId === 3"
              @click="editQuesUser(row)"
              type="text"
              size="default">
            编辑
          </el-button>

          <el-button
              :disabled="roleId === 3"
              @click="deleteQuesUser(row)"
              type="text"
              size="default">
            移除
          </el-button>

        </template>
      </el-table-column>

    </el-table>
  </div>



  <el-pagination style="float: right;margin-top: 50px;margin-bottom: 80px"
                 background
                 layout="total, prev, pager, next, jumper"
                 @current-change="handleCurrentChange"
                 :page-size="pageSize"
                 :current-page="currentPage"
                 :total="parseInt(total)"
  >
  </el-pagination>
</el-card>
</template>

<script>
import AddQuesUser from "@/components/back/admin/evaluation/AddQuesUser";
const {ref, onMounted, reactive} = require("vue");
import axios from "axios";
import {ElMessageBox} from "element-plus";
export default {
  name: "Record",
  components: {AddQuesUser},
  setup(){
    let pageSize = ref(4)
    let total = ref(0)
    let quesUsers = ref([]);
    let  currentPage = ref(1)
    let search = ref(0)
    let selectQuesOptions = ref([])
    let selectUnameOptions = ref([])
    let selectUserOptions = ref([])
    let selectQues = ref('')
    let selectUser = ref('')
    let quesUserSelection = ref([])
    let editVisible = ref(false)
    let selectedQuesUser = ref({});
    let selectedQuesUserBackup = ref({});
    const roleId = ref(0);
    const listQuesUser=()=>{

      axios.get('/sys/quesUserInformation/' + pageSize.value + '/1').then(resp => {
        if(resp &&  resp.data.code === 200){

          console.log("resp.data.result",resp.data.result)
          roleId.value = resp.data.result.roleId
          quesUsers.value = resp.data.result.list.content
          resp.data.result.uname.forEach(item =>{
            selectUnameOptions.value.push({
              value: item,
              label: item
            })
            })
          quesUsers.value.forEach((item, index) => {
            item.Id = index + 1;
          });
          resp.data.result.quesName.forEach(item =>{
            selectQuesOptions.value.push({
              value: item,
              label: item
            })
          })
          resp.data.result.userName.forEach(item =>{
            selectUserOptions.value.push({
              value: item,
              label: item
            })
          })

          // resp.data.result.quesName.forEach(item =>{
          //   selectQuesOptions.value.push({
          //     value: item,
          //     label: item
          //   })
          // })

          total.value = resp.data.result.list.totalElements


        }
      })
    }
    onMounted(() => {
      listQuesUser()

    });


    const handleCurrentChange=(page)=>{
      currentPage.value = page
      if(search.value === 0){
        axios.get('/sys/quesUserInformation/' + pageSize.value + '/' + page).then(resp =>{
          if(resp &&  resp.data.code === 200){
            quesUsers.value = resp.data.result.list.content

            quesUsers.value.forEach((item, index) => {
              item.Id = (page - 1) * pageSize.value + index + 1;
            });
            resp.data.result.quesName.forEach(item =>{
              selectQuesOptions.value.push({
                value: item,
                label: item
              })
            })
            resp.data.result.userName.forEach(item =>{
              selectUserOptions.value.push({
                value: item,
                label: item
              })
            })
            total.value = resp.data.result.list.totalElements
            //console.log(resp.data.result)
          }
        })
      }
      else if(search.value === 1){

        axios.get('/sys/searchQuesUsers/' + pageSize.value + '/' + page, {
          params: {
            quesName: selectQues.value,
            userName: selectUser.value

          }}).then(resp => {
          if(resp &&  resp.data.code === 200){

            quesUsers.value = resp.data.result.content
            quesUsers.value.forEach((item, index) => {
              item.Id = (page - 1) * pageSize.value + index + 1;
            });
            total.value = resp.data.result.totalElements
            // resp.data.result.quesName.forEach(item =>{
            //   selectQuesOptions.value.push({
            //     value: item,
            //     label: item
            //   })
            // })

            console.log("普通用户")
            console.log(resp.data.result)
          }
        })
      }

    }

    const searchForm=()=>{
      search.value = 1
      currentPage.value = 1
      console.log(selectQues.value, selectUser.value)
      axios.get('/sys/searchQuesUsers/' + pageSize.value + '/1', {
        params: {
          quesName: selectQues.value,
          userName: selectUser.value
        }}).then(resp => {
        if(resp &&  resp.data.code === 200){
          console.log("sous返回的数据",resp.data)
          quesUsers.value = resp.data.result.content
          quesUsers.value.forEach((item, index) => {
            item.Id = index + 1;
          });
          total.value = resp.data.result.totalElements
          console.log("普通用户")
          console.log(resp.data.result)
        }
      })
    }


    const deleteQuesUser =(quesUser)=>{
console.log("id", quesUser.id)
      ElMessageBox.confirm("确定要删除该记录吗？", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消', // 添加取消按钮
        //showCancelButton: false,
        closeOnClickModal: false,
        type: 'warning',
        draggable: true,
      }).then(()=>{
console.log(quesUser)
        axios.put('/sys/deleteQuesUser',
            {id: quesUser.id}
        ).then(resp =>{
          if(resp &&  resp.data.code === 200){
            ElMessageBox.confirm(resp.data.result, '提示', {
              confirmButtonText: '确定',
              //cancelButtonText: '取消', // 添加取消按钮
              showCancelButton: false,
              closeOnClickModal: false,
              type: 'warning',
              draggable: true,
            }).then(()=>{

              window.location.reload()
            }).catch(() => {
              // 用户点击了取消按钮或关闭按钮后的处理逻辑
            });
          }
        })
      }).catch(() => {
        // 用户点击了取消按钮或关闭按钮后的处理逻辑
      });


    }

    const handleSelectionChange = (val) => {
      quesUserSelection.value = val.map(row => row.id);
      console.log(quesUserSelection.value)
    }
    const deleteBatchQuesUsers = () =>{
      ElMessageBox.confirm("确定要删除这些测评记录吗？", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消', // 添加取消按钮
        //showCancelButton: false,
        closeOnClickModal: false,
        type: 'warning',
        draggable: true,
      }).then(()=>{

        axios.put('/sys/batchDeleteQuesUsers',
            quesUserSelection.value
        ).then(resp =>{
          if(resp &&  resp.data.code === 200){
            ElMessageBox.confirm(resp.data.result, '提示', {
              confirmButtonText: '确定',
              //cancelButtonText: '取消', // 添加取消按钮
              showCancelButton: false,
              closeOnClickModal: false,
              type: 'warning',
              draggable: true,
            }).then(()=>{

              window.location.reload()
            }).catch(() => {
              // 用户点击了取消按钮或关闭按钮后的处理逻辑
            });
          }
        })
      }).catch(() => {
        // 用户点击了取消按钮或关闭按钮后的处理逻辑
      });

    }

    const editQuesUser =(quesUser)=>{
      //console.log("quesItem", quesItem.name)
     // quesItemName.value = quesItem.name



      selectedQuesUser.value = {
        ...quesUser
      }
      // selectedSpec.value = { ...Spec } // 使用对象扩展运算符将 user 的属性值复制到新的对象中
      selectedQuesUserBackup.value ={
        ...quesUser
      }

      editVisible.value = true
    }
    const dataForm = ref(null);
    const editHandleDialogClose=(done)=> {

      done();

      dataForm.value.resetFields();
    }
    const resetForm = () => {
      // AddQuesItem ={
      //
      //
      // };
      // 重置表单并清除验证错误
      dataForm.value.resetFields();

    }
    const validateQuesName = (rule, value, callback) => {
      //手机号码校验(第一位为1，后十位为0-9任意数字)
      if (value.length === 0) {
        callback(new Error('请选择至少一个测评')); // 至少选择一个选项
      } else {
        callback(); // 验证通过
      }

    }
    const validateUserName = (rule, value, callback) => {
      //手机号码校验(第一位为1，后十位为0-9任意数字)
      if (value.length === 0) {
        callback(new Error('请选择至少一个用户')); // 至少选择一个选项
      } else {
        callback(); // 验证通过
      }

    }
    const rules = reactive({
      // quesName:[
      //   {required:true, message: '请输入题目', trigger: 'blur'},
      //   {min:2, message: '长度应该在2个字符之上',trigger: ['blur', 'change']},
      // ],
      quesName:[{required:true, message: '请选择测评名称', trigger: 'blur'},
        {
          validator: validateQuesName,
          trigger: 'change', // 触发验证的时机
        },],
      userName:[{required:true, message: '请选择测评用户', trigger: 'blur'},
        {
          validator: validateUserName,
          trigger: 'change', // 触发验证的时机
        }],
      addDate:[{required:true, message: '请选择测评时间', trigger: 'blur'},],
      score:[
        {required:true, message: '请输入测评结果', trigger: 'blur'},
        {min:1, message: '长度应该在1个字符之上',trigger: ['blur', 'change']},
      ],
      result:[
        {required:true, message: '请输入专业推荐', trigger: 'blur'},
        {min:1, message: '长度应该在1个字符之上',trigger: ['blur', 'change']},
      ]

    })

    const submitEditForm=(addQuesUser)=>{
console.log("addQuesUser",addQuesUser)
      //const router = useRouter();
      ElMessageBox.confirm('确定修改该测评记录？', '提示', {
        confirmButtonText: '确定',
        //cancelButtonText: '取消', // 添加取消按钮
        showCancelButton: false,
        closeOnClickModal: false,
        type: 'warning',
        draggable: true,
      }).then(()=>{

        axios.put(`/sys/modifyQuesUser`,addQuesUser
        ).then(resp => {
          // console.log(resp.data)
          if(resp.data.code === 200){

            ElMessageBox.confirm('修改测评记录成功', '提示', {
              confirmButtonText: '确定',
              //cancelButtonText: '取消', // 添加取消按钮
              showCancelButton: false,
              closeOnClickModal: false,
              type: 'warning',
              draggable: true,
            }).then(()=>{

              editVisible.value = false
              listQuesUser()
              currentPage.value = 1
            }).catch(() => {
              // 用户点击了取消按钮或关闭按钮后的处理逻辑
            });



          }else{
            console.log(resp.data)
            ElMessageBox.confirm(resp.data.message, '提示', {
              confirmButtonText: '确定',
              //cancelButtonText: '取消', // 添加取消按钮
              showCancelButton: false,
              closeOnClickModal: false,
              type: 'warning',
              draggable: true,
            }).then(()=>{

            }).catch(() => {
              // 用户点击了取消按钮或关闭按钮后的处理逻辑
            });


          }
        })
            .catch(failResponse => {})



      }).catch(() => {
        // 用户点击了取消按钮或关闭按钮后的处理逻辑
      });



    }



    return{

      listQuesUser,
      quesUsers,
      total,
      pageSize,
      currentPage,
      handleCurrentChange,
      search,
      selectQuesOptions,
      selectUserOptions,
      selectQues,
      selectUser,
      searchForm,
      deleteQuesUser,
      quesUserSelection,
      handleSelectionChange,
      deleteBatchQuesUsers,
      editQuesUser,
      editVisible,
      selectedQuesUser,
      selectedQuesUserBackup,
      selectUnameOptions,
      resetForm,
      dataForm,
      editHandleDialogClose,
      rules,
      submitEditForm,
      roleId

    }
  }


}
</script>

<style scoped>
.align-left-container {
  display: flex;
  align-items: flex-start;
}
.button1 {
  display: flex;
  align-items: flex-start;
  /*margin-left: 30px;*/
  margin-bottom: 20px;

}
</style>