<template>

  <body id="register">

  <!--  注册表 model="loginForm"-->
  <el-form :model="loginForm" :rules="rules" class="login-container" label-position="left" label-width="0px" v-loading="loading">

    <!--    名称-->
    <h3 class="login_title">用户注册</h3>

    <!--    子项：用户名-->
    <el-form-item prop="uname">
      <el-input type="text" v-model="loginForm.uname" auto-complete="off" placeholder="用户名"></el-input>
    </el-form-item>
    <!--    子项：真实姓名-->
    <el-form-item prop="userName">
      <el-input type="text" v-model="loginForm.userName" auto-complete="off" placeholder="姓名"></el-input>
    </el-form-item>
    <!--    子项：密码-->
    <el-form-item prop="userPassword">
      <el-input type="password" v-model="loginForm.userPassword" auto-complete="off" placeholder="密码"></el-input>
    </el-form-item>
    <el-form-item prop="confirmPassword">
      <el-input type="password" v-model="loginForm.confirmPassword" auto-complete="off" placeholder="确认密码"></el-input>
    </el-form-item>



    <!--    子项：电话 号码-->
    <el-form-item prop="userPhone">
      <el-input type="text" v-model="loginForm.userPhone" auto-complete="off" placeholder="手机号"></el-input>
    </el-form-item>

    <!--    子项：电子邮箱-->
    <el-form-item prop="userEmail">
      <el-input type="text" v-model="loginForm.userEmail" auto-complete="off" placeholder="电子邮箱"></el-input>
    </el-form-item>

    <!--    立即登录按钮-->
    <el-form-item style="width: 100%;">
      <el-button type="primary" style="width: 30%;background: #505458;border: none" class="register-button" v-on:click="register">注册</el-button>
      <router-link class="qxk-link" :to="{path:'/login'}">

        <el-button type="primary" style="width: 100%;border: none;" >立即登录</el-button>
      </router-link>
    </el-form-item>


  </el-form>

  </body>

</template>

<script>

import {useRouter} from "vue-router";

export default {
  name: "Register",
  data(){
    return{
      //用户名和密码的规则
      rules:{

        userPassword: [{required: true, message: '密码不能为空', trigger: 'blur'}],
        uname: [{required: true, message: '用户名不能为空', trigger: 'blur'}],
        userName: [{required: true, message: '姓名不能为空', trigger: 'blur'}],
        // userPhone: [{required: true, message: '手机号不能为空', trigger: 'blur'}],
        // userEmail: [{required: true, message: '电子邮箱不能为空', trigger: 'blur'}],
        confirmPassword: [{required: true, message: '确认密码不能为空', trigger: 'blur'}]
      },
      checked: true,
      //数据
      loginForm:{
        uname: '',//用户名
        userName: '',//姓名
        userPassword: '',//密码
        confirmPassword: '',//确认密码
        userPhone: '',//手机号
        userEmail: ''//电子邮箱

      },
      loading: false
    }
  },
  methods:{
    //注册
    register(){
      var _this = this
      //const router = useRouter();
      if(this.uname !== "" && this.userName !== ""
          &&(this.loginForm.userPassword !== '' && this.loginForm.confirmPassword !== '')
          && (this.loginForm.userPassword === this.loginForm.confirmPassword)  ){
        this.$axios.post('/com/register', {
          uname: this.loginForm.uname,
          userPassword: this.loginForm.userPassword,
          userName: this.loginForm.userName,
          userPhone: this.loginForm.userPhone,
          userEmail: this.loginForm.userEmail
        }).then(resp => {
              // console.log(resp.data)
              if(resp.data.code === 200){

                this.$alert('注册成功', '提示', {
                  confirmButtonText: '确定'
                }).then(resp =>{
                  _this.$router.replace('/login')
                })

              }else{
                console.log(resp.data)
                this.$alert(resp.data.message, '提示', {
                  confirmButtonText: '确定'
                })
              }
            })
            .catch(failResponse => {})
      }
      else if(this.loginForm.uname === ''){
        this.$alert('用户名为空', '提示', {
          confirmButtonText: '确定'
        })
      }
      else if(this.loginForm.userName === ''){
        this.$alert('姓名为空', '提示', {
          confirmButtonText: '确定'
        })
      }
      else if(this.loginForm.userPassword === ''){
        this.$alert('密码为空', '提示', {
          confirmButtonText: '确定'
        })
      }else if(this.loginForm.confirmPassword === ''){
        this.$alert('确认密码为空', '提示', {
          confirmButtonText: '确定'
        })
      }
      else if(this.loginForm.userPassword !== this.loginForm.confirmPassword){
        this.$alert('输入的密码不一致', '提示', {
          confirmButtonText: '确定'
        })
      }

    }
  }
}
</script>

<style>
#register {
  background: url("../../../public/register.png") no-repeat center;
  height: 100%;
  width: 100%;
  background-size: cover;
  position: fixed;
}
body{
  margin: -5px 0px;
}
.register-button {
  background: #505458;
  border: none;
  margin-right: 155px; /* 可选：为按钮之间添加一些间距 */
}
.login-container {
  border-radius: 15px;
  background-clip: padding-box;
  margin: 90px auto;
  width: 350px;
  padding: 35px 35px 15px 35px;
  background: #fff;
  border: 1px solid #eaeaea;
  box-shadow: 0 0 25px #cac6c6;
}
.login_title {
  margin: 0px auto 40px auto;
  text-align: center;
  color: #505458;
}
.login_remember {
  margin: 0px 0px 35px 0px;
  text-align: left;
}
</style>

