

<template>

  <div class="radar-area">
    <el-card>
      <!--    <strong>所得测评结果</strong>-->
      <!--    <span class="spacer"></span>-->
      <!--    <span class="spacer"></span>-->

      <el-row>
        <el-col :span="12">
          <strong>测评结果图：</strong>
          <Radar></Radar>
        </el-col>
        <el-col :span="12">
          <strong>相关推荐：</strong>

          <!--        <div v-for="(result, index) in result1.slist " :key="result.id">-->
          <!--          <div style="display: flex; align-items: center;">-->

          <el-table :data="result2.list" border style="width: 100%">
            <el-table-column prop="index" label="序号" />
<!--            &lt;!&ndash;                <el-table-column prop="index" label="Index" width="180">&ndash;&gt;-->
<!--            &lt;!&ndash;                  <template slot-scope="scope">{{ scope.$index + 1 }}</template>&ndash;&gt;-->
<!--            &lt;!&ndash;                </el-table-column>&ndash;&gt;-->
            <el-table-column prop="name" label="学科名称"/>
            <el-table-column prop="score" label="学科分数" />
          </el-table>

          <el-table :data="[result2.slist]" border style="width: 100%">
            <el-table-column label="高考6选3——科目推荐">
              <template #default="scope">
                {{ scope.row }}
              </template>
            </el-table-column>
          </el-table>
          <!--              <strong>{{index + 1}}.</strong>-->
          <!--              <span class="spacer"></span>-->
          <!--              <strong>{{result.name}}</strong>-->
          <!--              <strong>{{result.kemu}}</strong>-->
          <!--            </div>-->
          <!--          </div>-->


        </el-col>
      </el-row>


    </el-card>
  </div>


</template>

<script>

import Radar from "@/components/front/common/Radar";

import CountDown from "@/components/front/common/CountDown";


export default {
  name: "QxkResult",
  components: {CountDown, Radar},
  data () {
    return {
      result2: [],
    }
  },
  created() {
    this.result2 =  JSON.parse(this.$route.query.result)
    console.log(this.result2)
    // console.log("this.result1")
    // console.log(this.result1)
    // console.log("this.result1")
    // $bus.emit("data2', this.result1)
  },
  mounted() {
    this.slistWithIndex();
  },methods: {
    slistWithIndex() {
      this.result2.list = this.result2.list.map((item, index) => ({ ...item, index: index + 1 }));
    },
  },
}
</script>

<style scoped>
.radar-area {
  width: 84%; /* 设置文本区域宽度 */
  /*margin: 0 auto; !* 居中文本区域 *!*/
  /*padding: 10px; !* 可选：添加内边距来制造空白 *!*/
  /*height: 750px;*/
  margin-left: auto;
  margin-right: auto;
}
.spacer {
  margin-top: 30px; /* 调整间距大小 */
}
</style>