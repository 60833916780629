<template>

  <div class="note-area">

<el-card>
  <el-row>
  <el-col :span="2.5"  style="height: 40px">
    <strong>搜索选科信息：</strong>

  </el-col>
  </el-row>
  <div class="input-area">

  <el-input
      v-model="keywords"
      clearable
      style="width: 300px"
      size="large"
      class="w-50 m-2 left-align-input"
      placeholder="请输入需要查询的选科信息"
      :prefix-icon="SearchIcon"
  />

      <el-button type="primary" round style="width: 5%;margin-left: 10px;" v-on:click="search"><el-icon><Search /></el-icon>搜索</el-button>


  </div>

  <el-row>
    <el-col :span="12">
      <div class="spacer"></div>
    </el-col>
  </el-row>

  <el-row>
    <el-col>
      <note-result :id="this.list.Id" :my-message="this.list" :display="this.display"></note-result>
    </el-col>
  </el-row>

</el-card>


  </div>

</template>

<script >
import { Search } from '@element-plus/icons-vue';
import NoteResult from "@/components/front/notes/NoteResult";
export default {
  name: "ListNotes",
  components: {NoteResult},
  data(){
    return{
      keywords : '',
      list: [],
      display : false,
    }
  },
  computed: {
    SearchIcon() {
      return Search;
    }
  },
  methods:{
    search(){
      this.$axios.get('/com/listNote?keywords=' + this.keywords).then(resp => {
        console.log(this.keywords)
        if (resp && resp.data.code === 200) {

          console.log(resp.data.result)
          this.list = resp.data.result
          if(this.list.length !== 0){
            this.list.forEach((item, index) => {
              item.Id = index + 1;
            });
          }

          this.display = true
          // this.$router.push({ path: '/listQxk/getQxkItem/qxkResult',
          //   query: { result: JSON.stringify(resp.data.result) } });
          // this.$router.push('/listQues/getQues/quesResult');
          // $bus.emit('data1', resp.data.result)
          // $bus.emit('data2', resp.data.result)
        }else{
          this.$message({
            message: `搜索失败！`,
            type: 'error'
          });
        }
      })
    }
  }

}

</script>

<style scoped>
.input-area {
  text-align: left !important; /* 使用 !important 来确保样式生效 */
}
.note-area {
  width: 84%; /* 设置文本区域宽度 */
  /*margin: 0 auto; !* 居中文本区域 *!*/
  /*padding: 10px; !* 可选：添加内边距来制造空白 *!*/
  /*height: 750px;*/

  margin-left: auto;
  margin-right: auto;
}
.spacer {
  height: 50px; /* 调整间距大小 */
}
</style>