

<!--是图片上传这个组件，需要单独设置属性才能带上 cookie，不带 cookie 后端就拿不到 sessionId，就会被 shiro 拦截。-->
<!--相比之前添加了 with-credentials-->
<template>
  <!--  钩子函数可以让我们在Vue实例的不同阶段执行自定义代码,以便我们可以在应用程序的不同阶段进行操作-->
  <!--action:  表示上传文件的后端接口地址,上传的地址,也就是你上传的路径-->
  <!--
  on-preview 点击文件列表中已上传的文件时的钩子，图片预览需要用
  on-remove 文件列表移除文件时的钩子,图片删除时要用
  before-remove:  删除文件之前的钩子，参数为上传的文件和文件列表，若返回 false 或者返回 Promise 且被 reject，则停止删除。
  on-success: 文件上传成功时的钩子
  on-exceed:  文件超出个数限制时的钩子
  limit:  最大允许上传个数
  multiple: 是否支持多选文件,是个布尔属性,写了就可以多个上传了(按住ctrl然后点击文件就可以多选了)
  :file-list已上传文件列表-->
  <el-upload
      class="avatar-uploader"
      ref="upload"
      action="http://localhost:8443/user/avatar"
      with-credentials

      :on-preview="handlePreview"
      :on-remove="handleRemove"
      :before-remove="beforeRemove"
      :on-success="handleSuccess"
      multiple

      :on-exceed="handleExceed"
      :file-list="fileList">
    <el-button size="small" type="primary">点击上传头像</el-button>
<!--    :limit="1"-->
<!--    <el-icon-->
<!--        style="font-size: 25px;"-->
<!--    ><Plus /></el-icon>-->
<!--    <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>-->
  </el-upload>
<!--  <el-dialog-->
<!--      v-model="dialogVisiblesj"-->
<!--      title="预览"-->
<!--      destroy-on-close-->
<!--  >-->
<!--    <img-->
<!--        :src="dialogImageUrsj"-->
<!--        style="-->
<!--									display: block;-->
<!--									max-width: 500px;-->
<!--									margin: 0 auto;-->
<!--									height: 500px;-->
<!--								"-->
<!--    />-->
<!--  </el-dialog>-->


</template>

<script>
export default {
  name: 'uploadImg',
  data () {
    return {
      dialogVisiblesj: false,
      dialogImageUrsj: "",
      fileList: [],
      url: ''
    }
  },
  methods: {
    //file是正在进行处理的文件


    //√ on-remove 文件列表移除文件时的钩子,图片删除时要用
    //在控制台打印相关信息
    handleRemove (file, fileList) {
      console.log(file, fileList)
    },
    //√ on-preview 点击文件列表中已上传的文件时的响应，图片预览需要用，在控制台可以看到图片的信息
    handlePreview (file) {
      console.log(file)
      // 此处的 file 是整个文件
      // console.log(file.response)
    },
    //√ on-exceed:  文件超出个数限制时的钩子
    handleExceed (files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，需要删除当前文件才能继续上传`)
    },
    //√ 对 before-remove:  删除文件之前的钩子，参数为上传的文件和文件列表，
    // 若返回 false 或者返回 Promise 且被 reject，则停止删除。
    //点击删除图片时，会弹出提示框
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    //√ on-success: 文件上传成功时的钩子，显示上传成功
    //response是后端传来的响应：URL
    handleSuccess (response) {
      this.url = response
      // console.log(this.url)
      this.$emit('onUpload', this.url)//使用父组件的方法uploadImg
      this.$message.warning('上传成功')
      this.$refs.upload.clearFiles()

    },
    //editform调用了，清除所有文件
    clear () {
      this.$refs.upload.clearFiles()
    }
  }
}
</script>

<style scoped>

/*.avatar-uploader {*/
/*  width: 150px;*/
/*  height: 150px !important;*/
/*  overflow: hidden;*/
/*}*/
</style>

