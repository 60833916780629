import { createRouter, createWebHistory } from 'vue-router'

import frontIndex from "@/components/front/frontIndex"
import HomeView from "@/views/HomeView";
import FrontAbout from "@/components/front/FrontAbout";



import ListNotes from "@/components/front/notes/ListNotes";
import Register from "@/components/registerAndLogin/Register";
import Login from "@/components/registerAndLogin/Login";

import Radar from "@/components/front/common/Radar";

import ListQues from "@/components/front/ques/ListQues";
import QuesResult from "@/components/front/ques/QuesResult";
import CountDown from "@/components/front/common/CountDown";
import ListQxk from "@/components/front/qxk/ListQxk";
import GetQuesItem from "@/components/front/ques/GetQuesItem";
import GetQxkItem from "@/components/front/qxk/GetQxkItem";
import QxkResult from "@/components/front/qxk/QxkResult";
import NoteResult from "@/components/front/notes/NoteResult";
import BackView from "@/views/BackView";
import Welcome from "@/components/back/admin/Welcome";
import Error404 from "@/components/page/Error404";
import Limit from "@/components/page/Limit";
import Result from "@/components/back/admin/result/Result";
import Theme from "@/components/back/admin/evaluation/Theme";
import Topic from "@/components/back/admin/evaluation/Topic";
import Record from "@/components/back/admin/evaluation/Record";
import Theme108 from "@/components/back/admin/evaluation/Theme108";
import Topic108 from "@/components/back/admin/evaluation/Topic108";
import Record108 from "@/components/back/admin/evaluation/Record108";
import Speciality from "@/components/back/admin/sys/Speciality";
import QuesAndAnswers from "@/components/back/admin/sys/QuesAndAnswers";
import OrdinaryUsers from "@/components/back/admin/user/OrdinaryUsers";
import Administrators from "@/components/back/admin/user/Administrators";
import Profile from "@/components/back/admin/setting/Profile";
import ChangePassword from "@/components/back/admin/setting/ChangePassword";
import Logout from "@/components/back/admin/setting/Logout";




export const routes = [
  {
    path: '/',
    name: 'Default',
    redirect: '/home',
    component: HomeView
  },
  {
    path: '/home',
    name: 'Home',
    component: HomeView,
    redirect: '/index',
    children :[
      {
        path: '/index',
        name: 'frontIndex',
        component: frontIndex
      },
      {
        path: '/about',
        name: 'about',
        component: FrontAbout
      },
      {
        path: '/listQues',
        name: 'ListQues',
        component: ListQues
      },
      {
        path: '/listQues/getQuesItem',
        name: 'GetQuesItem',
        component: GetQuesItem,
        children:[

          {
            path: '/CountDown',
            name: 'CountDown',
            component: CountDown
          }
        ]
      },
      {
        path: '/listQues/getQuesItem/quesResult',
        name: 'quesResult',
        component: QuesResult,
        children:[

          {
            path: '/radar',
            name: 'radar',
            component: Radar
          }
        ]
      }
      ,
      {
        path: '/listQxk',
        name: 'ListQxk',
        component: ListQxk
      },
      {
        path: '/listQxk/getQxkItem',
        name: 'GetQxkItem',
        component: GetQxkItem,

      },
      {
        path: '/listQxk/getQxkItem/qxkResult',
        name: 'qxkResult',
        component: QxkResult,
        children:[

          {
            path: '/radar',
            name: 'radar',
            component: Radar
          }
        ]
      },
      {
        path: '/listNotes',
        name: 'listNotes',
        component: ListNotes,
        children:[

          {
            path: '/listNotes/noteResult',
            name: 'noteResult',
            component: NoteResult
          }
        ]
      },


    ]
  },
  // {
  //   path: '/admin',
  //   name: 'BackView',
  //   component: BackView,
  //   meta: {
  //     requireAuth: true
  //    },
  //     children :[
  //       //
  //       // {
  //       //   path: '/admin/welcome',
  //       //   name: 'Welcome',
  //       //   component: Welcome,
  //       //   meta: {
  //       //     requireAuth: true
  //       //   }
  //       // },
  //       // {
  //       //   path: '/admin/result',
  //       //   name: 'Result',
  //       //   component: Result,
  //       //   meta: {
  //       //     requireAuth: true
  //       //   }
  //       // // },
  //       // // {
  //       // //   path: '/admin/evaluation/theme',
  //       // //   name: 'Theme',
  //       // //   component: Theme,
  //       // //   meta: {
  //       // //     requireAuth: true
  //       // //   }
  //       // },
  //       // {
  //       //   path: '/admin/evaluation/topic',
  //       //   name: 'Topic',
  //       //   component: Topic,
  //       //   meta: {
  //       //     requireAuth: true
  //       //   }
  //       // },
  //       // {
  //       //   path: '/admin/evaluation/record',
  //       //   name: 'Record',
  //       //   component: Record,
  //       //   meta: {
  //       //     requireAuth: true
  //       //   }
  //       // },
  //
  //       // {
  //       //   path: '/admin/welcome',
  //       //   name: 'Welcome',
  //       //   component: Welcome,
  //       //   meta: {
  //       //     requireAuth: true
  //       //   }
  //       // },
  //       // {
  //       //   path: '/admin/evaluation/theme108',
  //       //   name: 'Theme108',
  //       //   component: Theme108,
  //       //   meta: {
  //       //     requireAuth: true
  //       //   }
  //       // },
  //       // {
  //       //   path: '/admin/evaluation/topic108',
  //       //   name: 'topic108',
  //       //   component: Topic108,
  //       //   meta: {
  //       //     requireAuth: true
  //       //   }
  //       // },
  //       // {
  //       //   path: '/admin/evaluation/record108',
  //       //   name: 'Record108',
  //       //   component: Record108,
  //       //   meta: {
  //       //     requireAuth: true
  //       //   }
  //       // },
  //       // {
  //       //   path: '/admin/sys/speciality',
  //       //   name: 'Speciality',
  //       //   component: Speciality,
  //       //   meta: {
  //       //     requireAuth: true
  //       //   }
  //       // },
  //       // {
  //       //   path: '/admin/sys/quesAndAnswers',
  //       //   name: 'QuesAndAnswers',
  //       //   component: QuesAndAnswers,
  //       //   meta: {
  //       //     requireAuth: true
  //       //   }
  //       // },
  //       // {
  //       //   path: '/admin/user/ordinaryUsers',
  //       //   name: 'OrdinaryUsers',
  //       //   component: OrdinaryUsers,
  //       //   meta: {
  //       //     requireAuth: true
  //       //   }
  //       // },
  //       // {
  //       //   path: '/admin/user/administrators',
  //       //   name: 'Administrators',
  //       //   component: Administrators,
  //       //   meta: {
  //       //     requireAuth: true
  //       //   }
  //       // },
  //       // {
  //       //   path: '/admin/setting/profile',
  //       //   name: 'Profile',
  //       //   component: Profile,
  //       //   meta: {
  //       //     requireAuth: true
  //       //   }
  //       // },
  //       // {
  //       //   path: '/admin/setting/changePassword',
  //       //   name: 'ChangePassword',
  //       //   component: ChangePassword,
  //       //   meta: {
  //       //     requireAuth: true
  //       //   }
  //       // },
  //       // {
  //       //   path: '/admin/setting/logout',
  //       //   name: 'Logout',
  //       //   component: Logout,
  //       //   meta: {
  //       //     requireAuth: true
  //       //   }
  //       // }
  //
  //     //
  //     //
  //     //   ]
  //     //   // {
  //     //   //   path: '/admin/evaluation/theme',
  //     //   //   name: 'Theme',
  //     //   //   component: ()=> import('@/components/back/admin/evaluation/Theme.vue'),
  //     //   //   meta: {
  //     //   //     requireAuth: true
  //     //   //   }
  //     //   // },
  //     ]
  //
  // }
  // ,
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/error404',
    name: 'error404',
    component: Error404
  },
  {
    path: '/limit',
    name: 'limit',
    component: Limit
  }
  // {
  //   path:'/:catchAll(.*)',
  //   name:'error404',
  //   component: Error404
  // }

]







const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router




























