<template>
  <el-button color="#67b18e" size="large" @click="dialogFormVisible = true" style="margin-right: 15px"
               >
    <el-icon><Plus /></el-icon>批量添加测评
  </el-button>


  <el-dialog
      title="新增测评"
      v-model="dialogFormVisible"
      :before-close="handleDialogClose">

    <!--selectedUser：被选中的用户(在点击编辑按钮时通过获得)     prop对应属性 -->
    <el-form :model="addQuesTheme" style="text-align: left" ref="dataForm" label-width="100px"
             :rules="rules" status-icon>

      <el-form-item prop="name" label="测评" class="item">
        <el-input v-model="addQuesTheme.name" autocomplete="off" class="input"></el-input>
        <!--        <el-input v-model="selectedUser.uname" autocomplete="off" class="input"></el-input>-->
      </el-form-item>

      <el-form-item prop="shi" label="时长(分)" class="item">
        <el-input v-model="addQuesTheme.shi" autocomplete="off" class="input"></el-input>
        <!--        <el-input v-model="selectedUser.uname" autocomplete="off" class="input"></el-input>-->
      </el-form-item>



      <el-form-item class="button">
        <el-button @click="submitForm(addQuesTheme)" color="#178071" size="large">
          <el-icon><Document /></el-icon>保存
        </el-button>

        <el-button @click="resetForm()" color="#d1c48f" size="large">
          <el-icon><Flag /></el-icon>重置
        </el-button>

      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import {reactive, ref} from "vue";
import {ElMessageBox} from "element-plus";
import axios from "axios";

export default {
  name: "AddQuesTheme",
  setup(){

    let dialogFormVisible = ref(false)

    let addQuesTheme = ref({
      name:"",
      shi:""
    });

    const resetForm = () => {
      addQuesTheme ={
        name:"",
        shi:""
      };
      // 重置表单并清除验证错误
      dataForm.value.resetFields();

    }
    const dataForm = ref(null);
    const handleDialogClose=(done)=> {

      done();

      addQuesTheme = ref({
        name: '',
        shi: '',
      });

      dataForm.value.resetFields();
    }


    const rules = reactive({
      name:[
        {required:true, message: '请输入测评名称', trigger: 'blur'},
        {min:2, message: '长度应该在2个字符之上',trigger: ['blur', 'change']},
      ],
      shi: [
        {required:true, message: '请输入时长', trigger: 'blur'},
        {min:1, message: '长度应该在1个字符之上',trigger: ['blur', 'change']},
      ]
    })


    const submitForm=(addQuesTheme)=>{

      if(addQuesTheme.name !== '' && addQuesTheme.shi !== ''){
        //const router = useRouter();
        ElMessageBox.confirm('确定创建新的测评？', '提示', {
          confirmButtonText: '确定',
          //cancelButtonText: '取消', // 添加取消按钮
          showCancelButton: false,
          closeOnClickModal: false,
          type: 'warning',
          draggable: true,
        }).then(()=>{

          axios.post('/sys/registerQuesTheme', addQuesTheme).then(resp => {
            // console.log(resp.data)
            if(resp.data.code === 200){
              dialogFormVisible.value = false
              console.log(resp.data)

              ElMessageBox.confirm(resp.data.result, '提示', {
                confirmButtonText: '确定',
                //cancelButtonText: '取消', // 添加取消按钮
                showCancelButton: false,
                closeOnClickModal: false,
                type: 'warning',
                draggable: true,
              }).then(()=>{

                addQuesTheme ={
                  name:"",
                  shi:""
                }
                Object.keys(rules).forEach((prop) => {
                  dataForm.value.clearValidate(prop);
                }); // 清除表单验证错误信息
                window.location.reload()
              }).catch(() => {
                // 用户点击了取消按钮或关闭按钮后的处理逻辑
              });



            }else{
              console.log(resp.data)
              ElMessageBox.confirm(resp.data.message, '提示', {
                confirmButtonText: '确定',
                //cancelButtonText: '取消', // 添加取消按钮
                showCancelButton: false,
                closeOnClickModal: false,
                type: 'warning',
                draggable: true,
              }).then(()=>{

              }).catch(() => {
                // 用户点击了取消按钮或关闭按钮后的处理逻辑
              });


            }
          })
              .catch(failResponse => {})

        }).catch(() => {
          // 用户点击了取消按钮或关闭按钮后的处理逻辑
        });
      }else{
        ElMessageBox.confirm("请填写所有表项！！！", '提示', {
          confirmButtonText: '确定',
          //cancelButtonText: '取消', // 添加取消按钮
          showCancelButton: false,
          closeOnClickModal: false,
          type: 'warning',
          draggable: true,
        })
      }

    }

    return{

      dialogFormVisible,
      addQuesTheme,
      resetForm,
      handleDialogClose,
      rules,
      dataForm,
      submitForm
    }
  }
}
</script>

<style scoped>
.input {
  margin-left: 30px; /* input 左侧外边距 */
}
.button {
  display: flex;
  align-items: flex-start;
  margin-left: 130px;
  margin-bottom: 45px;

}
</style>