<template>
  <div class="text-container">
  <el-card>
            <h2>关于我们</h2>

            <p style="white-space: normal; text-align: center;">
              <img src="http://img.gaosan.com/upload/2014-03-04/7fc0e2ac-cb82-4e49-8d4c-00786212958b.jpg">
            </p>

            <p style="white-space: normal;">
              <strong>关于我们</strong>
            </p>
            <p style="white-space: normal;">
              高中学业选科系统是国内较早深耕高中学习类新闻服务的高考资讯平台，于2009年12月上线，每年为超过5000万学生及家长提供学习服务。年PGC内容量20w+，为学生和家长提供精品的政策资讯内容和学习功能服务，现已成为国内较全面的综合性中学生教育类平台，在行业内广受学生及家长的称誉。</p>
            <p style="white-space: normal;">
              蝶变高考APP作为吉林省春雨秋风科技有限公司未来的品牌培育计划。收归全品牌旗下的内容流量端，打造最小信息熵聚合形式的群落生态，缔成专属于中学生的一站式学习品牌。而此次对旗下流量端的整合，只是为初高中生打造梦想乌托邦的第一步。</p>
            <p style="white-space: normal;">
              高中学业选科系统拥有国内教育行业优质的内容积累和生产力，网站提供了包含全国各个省份的高考资讯信息、专业库、大学库、学科试题、真题答案等等，几乎涵盖了学生从入学到升学全部的内容需求。</p>
            <p style="white-space: normal;">
              而在更契合移动生活节奏的APP和小程序，上线了适合高中生学习生活的各类功能模块：志愿填报、新高考选科、蝶变电台、蝶变文库资料随心下载、小蝶刷题等等可利用碎片化时间提升学习成绩的实用功能，真正做到了便捷学习生活的方方面面。</p>
            <p style="white-space: normal;">
              蝶变高考APP已上线“高考问一问”功能板块，在新高考选科、学习、升学、心理等方面，由专业老师进行咨询辅导，打通专业的沟通桥梁，解决学生及家长对于学习生涯的困惑和迷茫。</p>
            <p style="white-space: normal;">
              另外，蝶变高考APP同样开通了当下主流的短视频和图文新媒体平台账号，为高考学子答疑解惑，每天会关注高中生相关问题，提供更符合年轻一代的信息内容，陪伴高中生度过整个高中生活。</p>
            <p style="white-space: normal;">我们致力于让每一个考生通过蝶变高考APP获取有价值的高考信息，并获取学习帮助，让蝶变高考APP真正成为你人生的始发站。</p>

  </el-card>
  </div>
</template>

<script>
export default {
  name: "FrontAbout"
}
</script>

<style scoped>
.text-container {
  width: 84%; /* 设置文本区域宽度 */
  margin: 0 auto; /* 居中文本区域 */
  /*padding: 10px; !* 可选：添加内边距来制造空白 *!*/
}
p {
  text-indent: 20px; /* 设置首行缩进为 2 个字母的宽度 */
  text-align: left; /* 将文本对齐方式设置为左对齐 */
}
</style>