<template>

  <div class="home" >
    <div class="scroll-container">
      <nav-menu></nav-menu>

    <div id="header-div">
      <div style="height: 0px"></div>
      <carousel ></carousel>
    </div>

      <router-view class="router-view"></router-view>
    <space></space>

      </div>
  </div>


</template>

<script>
// @ is an alias to /src


import NavMenu from "@/components/front/common/NavMenu";
import Carousel from "@/components/front/common/Carousel";
import Space from "@/components/front/common/Space";
export default {
  name: 'HomeView',
  components: {
    Space,
    NavMenu,
    Carousel

  }
}
</script>

<style>
#header-div {
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  padding-bottom: 0px;
  padding-left: 0px;

}

.home {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh; /* 设置容器高度为浏览器可见部分的高度 */
  z-index: -1; /* 将背景图层级设置为低于内容 */
  background: url('../../public/background.png') no-repeat center;
  background-size: cover;
}

.scroll-container {
  overflow-y: auto; /* 设置为可滚动 */
  height: 100%; /* 让可滚动容器填充整个 .home 容器 */
}
/*.router-view {*/
/*  margin-bottom: 200px;  !* 自动扩展以填充空间 *!*/
/*}*/
/*.space {*/
/*  margin-top: 100px; !* 调整这个数值来控制 Space 组件与 <router-view> 之间的距离 *!*/
/*  height: 200px; !* 根据需要调整高度 *!*/
/*}*/
</style>