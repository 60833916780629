<template>
  <div>
    <div class="ques-area">
      <el-card style="text-align: left">

        <div v-for="ques in quesData" :key="ques.id">
          <div style="float: left; width: 85%;height: 100px;">
            <router-link class="ques-link" :to="{path:'listQues/getQuesItem', query:{id:ques.id, shi:ques.shi}}">
              <strong>{{ques.Id}}. &nbsp;</strong>
              <strong>{{ques.name}}</strong>
              <span class="spacer"></span>
              <el-button type="primary">开始答题</el-button>
            </router-link>

            <el-divider content-position="left">答题时长：{{ques.shi}} min</el-divider>

          </div>
        </div>
      </el-card>

<div class="page">
        <el-card shadow="Never"  class="pagination-card" style="width: 100%; height: 120%;">

          <el-pagination style="float: right;"
              background
              layout="total, prev, pager, next, jumper"
              @current-change="handleCurrentChange"
              :page-size="pageSize"
              :total="parseInt(total)">
          </el-pagination>

        </el-card>
</div>
    </div>
  </div>
<!--  <el-table class="table" :data="tableData" stripe >-->
<!--&lt;!&ndash;    <el-table-column prop="date" label="Date" width="180" />&ndash;&gt;-->
<!--    <el-table-column prop="name" label="测评" width="180" />-->
<!--&lt;!&ndash;    <el-table-column prop="address" label="Address" />&ndash;&gt;-->
<!--  </el-table>-->
</template>

<script>
export default {
  name: "ListQues",
  data () {//存放键值对，用来存储vue实例或者组件里面的数值
    return {
      quesData: [],
      pageSize: 4,
      total: 0
    }
  },
  mounted() {
    this.listQues()
  },
  methods :{
    listQues(){
      var _this = this;
      this.$axios.get('/com/ques/' + this.pageSize + '/1').then(resp =>{
        if(resp &&  resp.data.code === 200){
          _this.quesData = resp.data.result.content
          _this.total = resp.data.result.totalElements

          _this.quesData.forEach((item, index) => {
            item.Id = index + 1;
          });
          console.log(resp.data.result)
        }
      })
    },
    handleCurrentChange(page){
      var _this = this;
      this.$axios.get('/com/ques/' + this.pageSize + '/' + page).then(resp =>{
        if(resp &&  resp.data.code === 200){
          _this.quesData = resp.data.result.content
          _this.total = resp.data.result.totalElements
          //console.log(resp.data.result)
          _this.quesData.forEach((item, index) => {
            item.Id = (page - 1) * this.pageSize + index + 1;
          });
        }
      })
    }
  }
}
</script>

<style scoped>
.ques-area {
  width: 84%; /* 设置文本区域宽度 */
  /*margin: 0 auto; !* 居中文本区域 *!*/
  /*padding: 10px; !* 可选：添加内边距来制造空白 *!*/
  /*height: 750px;*/
  margin-left: auto;
  margin-right: auto;
}
.page {
  /*width: 84%; !* 设置文本区域宽度 *!*/
  /*!*margin: 0 auto; !* 居中文本区域 *!*!*/
  /*!*padding: 10px; !* 可选：添加内边距来制造空白 *!*!*/
  height: 60px;
  /*margin-left: auto;*/
  /*margin-right: auto;*/
}
.spacer {
  margin-left: 50px; /* 调整间距大小 */
}
/*.articles-area {*/
/*  width: 990px;*/
/*  height: 750px;*/
/*  margin-left: auto;*/
/*  margin-right: auto;*/
/*}*/

.ques-link {
  text-decoration: none;
  color: #606266;
}

.ques-link:hover {
  color: #409EFF;
}

</style>