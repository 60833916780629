<template>
  <el-card class="admin-header" >

    <span style="font-size: 32px;font-weight: bold;position: absolute;">退出系统</span>

  </el-card>
</template>

<script>
export default {
  name: "Logout"
}
</script>

<style scoped>

</style>