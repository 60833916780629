<template>
<el-card>
  <el-dialog
      title="编辑测评题目"
      v-model="editVisible"
      :before-close="editHandleDialogClose"
  >

    <!--selectedUser：被选中的用户(在点击编辑按钮时通过获得)     prop对应属性 -->
    <el-form :model="selectedQuesItem" style="text-align: left" ref="dataForm" label-width="100px"
             :rules="rules" status-icon>


      <el-form-item prop="coursesel" label="科目" >
        <el-select :teleported="false" v-model="selectedQuesItem.coursesel" clearable placeholder="选择科目" class="input">

          <el-option
              v-for="item in selectOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          />
        </el-select>
      </el-form-item>

      <el-form-item prop="quesName" label="所属测评" >
        <el-select :teleported="false" v-model="selectedQuesItem.quesName" clearable placeholder="选择测评" class="input">

          <el-option
              v-for="item in selectQuesOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          />
        </el-select>
      </el-form-item>


      <el-form-item prop="name" label="题目" class="item">
        <el-input v-model="selectedQuesItem.name" autocomplete="off" class="input" clearable></el-input>
        <!--        <el-input v-model="selectedUser.uname" autocomplete="off" class="input"></el-input>-->
      </el-form-item>


      <el-form-item prop="option1" label="选项1" class="item">
        <el-input v-model="selectedQuesItem.option1" autocomplete="off" class="input" clearable></el-input>
        <!--        <el-input v-model="selectedUser.uname" autocomplete="off" class="input"></el-input>-->
      </el-form-item>
      <el-form-item prop="score1" label="分值1" class="item">
        <el-input v-model="selectedQuesItem.score1" autocomplete="off" class="input" clearable></el-input>
        <!--        <el-input v-model="selectedUser.uname" autocomplete="off" class="input"></el-input>-->
      </el-form-item>

      <el-form-item prop="option2" label="选项2" class="item">
        <el-input v-model="selectedQuesItem.option2" autocomplete="off" class="input" clearable></el-input>
        <!--        <el-input v-model="selectedUser.uname" autocomplete="off" class="input"></el-input>-->
      </el-form-item>
      <el-form-item prop="score2" label="分值2" class="item">
        <el-input v-model="selectedQuesItem.score2" autocomplete="off" class="input" clearable></el-input>
        <!--        <el-input v-model="selectedUser.uname" autocomplete="off" class="input"></el-input>-->
      </el-form-item>

      <el-form-item prop="option3" label="选项3" class="item">
        <el-input v-model="selectedQuesItem.option3" autocomplete="off" class="input" clearable></el-input>
        <!--        <el-input v-model="selectedUser.uname" autocomplete="off" class="input"></el-input>-->
      </el-form-item>
      <el-form-item prop="score3" label="分值3" class="item">
        <el-input v-model="selectedQuesItem.score3" autocomplete="off" class="input" clearable></el-input>
        <!--        <el-input v-model="selectedUser.uname" autocomplete="off" class="input"></el-input>-->
      </el-form-item>

      <el-form-item prop="option4" label="选项4" class="item">
        <el-input v-model="selectedQuesItem.option4" autocomplete="off" class="input" clearable></el-input>
        <!--        <el-input v-model="selectedUser.uname" autocomplete="off" class="input"></el-input>-->
      </el-form-item>
      <el-form-item prop="score4" label="分值4" class="item">
        <el-input v-model="selectedQuesItem.score4" autocomplete="off" class="input" clearable></el-input>
        <!--        <el-input v-model="selectedUser.uname" autocomplete="off" class="input"></el-input>-->
      </el-form-item>

      <el-form-item prop="option5" label="选项5" class="item">
        <el-input v-model="selectedQuesItem.option5" autocomplete="off" class="input" clearable></el-input>
        <!--        <el-input v-model="selectedUser.uname" autocomplete="off" class="input"></el-input>-->
      </el-form-item>
      <el-form-item prop="score5" label="分值5" class="item">
        <el-input v-model="selectedQuesItem.score5" autocomplete="off" class="input" clearable></el-input>
        <!--        <el-input v-model="selectedUser.uname" autocomplete="off" class="input"></el-input>-->
      </el-form-item>

      <el-form-item class="button">
        <el-button @click="submitEditForm(selectedQuesItem)" color="#178071" size="large">
          <el-icon><Document /></el-icon>保存
        </el-button>

        <el-button @click="resetForm()" color="#d1c48f" size="large">
          <el-icon><Flag /></el-icon>重置
        </el-button>

      </el-form-item>
    </el-form>





  </el-dialog>

  <div style="margin-bottom: 30px" class="align-left-container">
    <el-input v-model="inputName" placeholder="输入题目名称" clearable style="width: 200px;margin-right: 5px"/>
    <el-select v-model="selectQues" clearable placeholder="选择测评名称" style="margin-right: 10px">
      <el-option
          v-for="item in selectQuesOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
      />
    </el-select>
    <el-select v-model="selectValue" clearable placeholder="选择科目" style="margin-right: 10px">
      <el-option
          v-for="item in selectOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
      />
    </el-select>

    <el-button @click="searchForm()"  size="default" type="primary">
      <el-icon><Search /></el-icon>搜索
    </el-button>
  </div>
  <el-divider />

  <div class="button1">
    <AddQuesItem :message="selectQuesOptions"></AddQuesItem>
    <!--  <el-button @click="addUser()" color="#67b18e" size="large">-->
    <!--    <el-icon><Plus /></el-icon>新增普通用户-->
    <!--  </el-button>-->

    <el-button @click="deleteBatchQuesItems()" color="#cb5765" size="large">
      <el-icon><Delete /></el-icon>批量删除
    </el-button>
  </div>
  <div >

    <el-table :data="quesItems" border stripe style="width: 100%;"
              @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="50" class="my-table"/>
      <el-table-column prop="id"  label="Id" width="60" class="my-table"/>


      <el-table-column prop="name" label="题目名称" width="220" class="my-table"/>

      <el-table-column prop="quesName" label="所属测评" width="120" class="my-table"/>
      <el-table-column prop="coursesel" label="科目" width="80" class="my-table"/>

      <el-table-column prop="option1" label="选项1" width="100" class="my-table"/>
      <el-table-column prop="score1" label="分值1" width="80" class="my-table"/>
      <el-table-column prop="option2" label="选项2" width="100" class="my-table"/>
      <el-table-column prop="score2" label="分值2" width="80" class="my-table"/>
      <el-table-column prop="option3" label="选项3" width="100" class="my-table"/>
      <el-table-column prop="score3" label="分值3" width="80" class="my-table"/>
      <el-table-column prop="option4" label="选项4" width="100" class="my-table"/>
      <el-table-column prop="score4" label="分值4" width="80" class="my-table"/>
      <el-table-column prop="option5" label="选项5" width="100" class="my-table"/>
      <el-table-column prop="score5" label="分值5" width="80" class="my-table"/>

      <el-table-column
          label="操作"
           class="my-table">
        <template #default="{ row }">

          <el-button
              @click="editQuesItem(row)"
              type="text"
              size="default">
            编辑
          </el-button>

          <el-button
              @click="deleteQuesItem(row)"
              type="text"
              size="default">
            移除
          </el-button>

        </template>
      </el-table-column>

    </el-table>
  </div>


  <el-pagination style="float: right;margin-top: 50px;margin-bottom: 80px"
                 background
                 layout="total, prev, pager, next, jumper"
                 @current-change="handleCurrentChange"
                 :page-size="pageSize"
                 :current-page="currentPage"
                 :total="parseInt(total)"
                 >
  </el-pagination>
</el-card>
</template>

<script>
import AddQuesItem from "@/components/back/admin/evaluation/AddQuesItem";
const {ref, onMounted, reactive} = require("vue");
import axios from "axios";
import {ElMessageBox} from "element-plus";
export default {
  name: "Topic",
  components: {AddQuesItem},
  setup(){
    let quesItems = ref([]);
    let pageSize = ref(4)
    let total = ref(0)
    let search = ref(0)
    let inputName = ref('');
    let selectValue = ref('')
    let selectQues = ref('')
    let selectQuesOptions = ref([])
    let  currentPage = ref(1)
    let quesItemSelection = ref([])
    let editVisible = ref(false)
    let selectedQuesItem = ref({});
    let selectedQuesItemBackup = ref({});
    let quesItemName = ref('')
    const selectOptions = [
      {
        value: '物理',
        label: '物理',
      },
      {
        value: '化学',
        label: '化学',
      },
      {
        value: '生物',
        label: '生物',
      },
      {
        value: '政治',
        label: '政治',
      }
      ,{
        value: '地理',
        label: '地理',
      },
      {
        value: '历史',
        label: '历史',
      }
      ]
    const validateCoursesel = (rule, value, callback) => {
      //手机号码校验(第一位为1，后十位为0-9任意数字)
      if (value.length === 0) {
        callback(new Error('请选择至少一个科目')); // 至少选择一个选项
      } else {
        callback(); // 验证通过
      }

    }
    const rules = reactive({
      name:[
        {required:true, message: '请输入题目', trigger: 'blur'},
        {min:2, message: '长度应该在2个字符之上',trigger: ['blur', 'change']},
      ],
      coursesel: [
        {required:true, message: '请选择科目', trigger: 'blur'},
        {
          validator: validateCoursesel,
          trigger: 'change', // 触发验证的时机
        },
      ],
      option1:[
        {required:true, message: '请输入选项1', trigger: 'blur'},
        {min:1, message: '长度应该在1个字符之上',trigger: ['blur', 'change']},
      ],
      score1:[
        {required:true, message: '请输入分值1', trigger: 'blur'},
        {min:1, message: '长度应该在1个字符之上',trigger: ['blur', 'change']},
      ],
      option2:[
        {required:true, message: '请输入选项2', trigger: 'blur'},
        {min:1, message: '长度应该在1个字符之上',trigger: ['blur', 'change']},
      ],
      score2:[
        {required:true, message: '请输入分值2', trigger: 'blur'},
        {min:1, message: '长度应该在1个字符之上',trigger: ['blur', 'change']},
      ],
      option3:[
        {required:true, message: '请输入选项3', trigger: 'blur'},
        {min:1, message: '长度应该在1个字符之上',trigger: ['blur', 'change']},
      ],
      score3:[
        {required:true, message: '请输入分值3', trigger: 'blur'},
        {min:1, message: '长度应该在1个字符之上',trigger: ['blur', 'change']},
      ],
      option4:[
        {required:true, message: '请输入选项4', trigger: 'blur'},
        {min:1, message: '长度应该在1个字符之上',trigger: ['blur', 'change']},
      ],
      score4:[
        {required:true, message: '请输入分值4', trigger: 'blur'},
        {min:1, message: '长度应该在1个字符之上',trigger: ['blur', 'change']},
      ],
      option5:[
        {required:true, message: '请输入选项5', trigger: 'blur'},
        {min:1, message: '长度应该在1个字符之上',trigger: ['blur', 'change']},
      ],
      score5:[
        {required:true, message: '请输入分值5', trigger: 'blur'},
        {min:1, message: '长度应该在1个字符之上',trigger: ['blur', 'change']},
      ]
    })

    const handleCurrentChange=(page)=>{
      currentPage.value = page
      if(search.value === 0){
        axios.get('/sys/quesItemInformation/' + pageSize.value + '/' + page).then(resp =>{
          if(resp &&  resp.data.code === 200){
            quesItems.value = resp.data.result.list.content
            quesItems.value.forEach((item, index) => {
              item.id = (page - 1) * pageSize.value + index + 1;
            });
            resp.data.result.quesName.forEach(item =>{
              selectQuesOptions.value.push({
                value: item,
                label: item
              })
            })
            total.value = resp.data.result.list.totalElements
            //console.log(resp.data.result)
          }
        })
      }
      else if(search.value === 1){
        console.log(inputName.value)
        axios.get('/sys/searchQuesItems/' + pageSize.value + '/' + page, {
          params: {
            name: inputName.value,
            quesName: selectQues.value,
            coursesel: selectValue.value

          }}).then(resp => {
          if(resp &&  resp.data.code === 200){

            quesItems.value = resp.data.result.content
            quesItems.value.forEach((item, index) => {
              item.id = (page - 1) * pageSize.value + index + 1;
            });
            total.value = resp.data.result.totalElements
            // resp.data.result.quesName.forEach(item =>{
            //   selectQuesOptions.value.push({
            //     value: item,
            //     label: item
            //   })
            // })

            console.log("普通用户")
            console.log(resp.data.result)
          }
        })
      }

    }

    const listQuesItem=()=>{

      axios.get('/sys/quesItemInformation/' + pageSize.value + '/1').then(resp => {
        if(resp &&  resp.data.code === 200){
          console.log("返回数据")
          console.log(resp.data)
          quesItems.value = resp.data.result.list.content
          quesItems.value.forEach((item, index) => {
            item.id = index + 1;
          });
          console.log("quesItems.value",quesItems.value)

          resp.data.result.quesName.forEach(item =>{
            selectQuesOptions.value.push({
              value: item,
              label: item
            })
          })

          total.value = resp.data.result.list.totalElements


        }
      })
    }
    const dataForm = ref(null);
    const editHandleDialogClose=(done)=> {

      done();

      dataForm.value.resetFields();
    }
    const searchForm=()=>{
      search.value = 1
currentPage.value = 1
      console.log(inputName.value, selectQues.value, selectValue.value)
      axios.get('/sys/searchQuesItems/' + pageSize.value + '/1', {
        params: {
          name: inputName.value,
          quesName: selectQues.value,
          coursesel: selectValue.value
        }}).then(resp => {
        if(resp &&  resp.data.code === 200){


          quesItems.value = resp.data.result.content
          quesItems.value.forEach((item, index) => {
            item.id = index + 1;
          });
          total.value = resp.data.result.totalElements
          console.log("普通用户")
          console.log(resp.data.result)
        }
      })
    }
    onMounted(() => {
      listQuesItem()

    });

    const deleteQuesItem =(quesItems)=>{

      ElMessageBox.confirm("确定要删除该题目吗？", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消', // 添加取消按钮
        //showCancelButton: false,
        closeOnClickModal: false,
        type: 'warning',
        draggable: true,
      }).then(()=>{

        axios.put('/sys/deleteQuesItem',
            {name: quesItems.name}
        ).then(resp =>{
          if(resp &&  resp.data.code === 200){
            ElMessageBox.confirm(resp.data.result, '提示', {
              confirmButtonText: '确定',
              //cancelButtonText: '取消', // 添加取消按钮
              showCancelButton: false,
              closeOnClickModal: false,
              type: 'warning',
              draggable: true,
            }).then(()=>{

              window.location.reload()
            }).catch(() => {
              // 用户点击了取消按钮或关闭按钮后的处理逻辑
            });
          }
        })
      }).catch(() => {
        // 用户点击了取消按钮或关闭按钮后的处理逻辑
      });


    }
    const handleSelectionChange = (val) => {
      quesItemSelection.value = val.map(row => row.name);
      console.log(quesItemSelection.value)
    }

    const deleteBatchQuesItems = () =>{
      ElMessageBox.confirm("确定要删除这些题目吗？", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消', // 添加取消按钮
        //showCancelButton: false,
        closeOnClickModal: false,
        type: 'warning',
        draggable: true,
      }).then(()=>{

        axios.put('/sys/batchDeleteQuesItems',
            quesItemSelection.value
        ).then(resp =>{
          if(resp &&  resp.data.code === 200){
            ElMessageBox.confirm(resp.data.result, '提示', {
              confirmButtonText: '确定',
              //cancelButtonText: '取消', // 添加取消按钮
              showCancelButton: false,
              closeOnClickModal: false,
              type: 'warning',
              draggable: true,
            }).then(()=>{

              window.location.reload()
            }).catch(() => {
              // 用户点击了取消按钮或关闭按钮后的处理逻辑
            });
          }
        })
      }).catch(() => {
        // 用户点击了取消按钮或关闭按钮后的处理逻辑
      });

    }

    const editQuesItem =(quesItem)=>{
      //console.log("quesItem", quesItem.name)
      quesItemName.value = quesItem.name

      //
      // quesItem.value = {
      //   ...quesItem.value,
      //   ques : selectQues.value
      // }
      // console.log(quesItem)
      // quesItemAdded.value = quesItem.value.added.map(item => ({
      //   ...item,
      //   option1AndScore1: `${item.option1} (${item.score1})`,
      //   option1AndScore2: `${item.option2} (${item.score2})`,
      //   option1AndScore3: `${item.option3} (${item.score3})`,
      //   option1AndScore4: `${item.option4} (${item.score4})`,
      //   option1AndScore5: `${item.option5} (${item.score5})`
      // }));

      selectedQuesItem.value = {
        ...quesItem
      }
      // selectedSpec.value = { ...Spec } // 使用对象扩展运算符将 user 的属性值复制到新的对象中
      selectedQuesItemBackup.value ={
        ...quesItem
      }

      editVisible.value = true
    }
    const resetForm = () => {
      // AddQuesItem ={
      //
      //
      // };
      // 重置表单并清除验证错误
      dataForm.value.resetFields();

    }



    const submitEditForm=(addQuesItem)=>{

      //const router = useRouter();
      ElMessageBox.confirm('确定修改该题目？', '提示', {
        confirmButtonText: '确定',
        //cancelButtonText: '取消', // 添加取消按钮
        showCancelButton: false,
        closeOnClickModal: false,
        type: 'warning',
        draggable: true,
      }).then(()=>{

        axios.put(`/sys/modifyQuesItem?quesItemName=${quesItemName.value}`,addQuesItem
        ).then(resp => {
          // console.log(resp.data)
          if(resp.data.code === 200){

            ElMessageBox.confirm('修改题目成功', '提示', {
              confirmButtonText: '确定',
              //cancelButtonText: '取消', // 添加取消按钮
              showCancelButton: false,
              closeOnClickModal: false,
              type: 'warning',
              draggable: true,
            }).then(()=>{

              editVisible.value = false
              listQuesItem()
              currentPage.value = 1
            }).catch(() => {
              // 用户点击了取消按钮或关闭按钮后的处理逻辑
            });



          }else{
            console.log(resp.data)
            ElMessageBox.confirm(resp.data.message, '提示', {
              confirmButtonText: '确定',
              //cancelButtonText: '取消', // 添加取消按钮
              showCancelButton: false,
              closeOnClickModal: false,
              type: 'warning',
              draggable: true,
            }).then(()=>{

            }).catch(() => {
              // 用户点击了取消按钮或关闭按钮后的处理逻辑
            });


          }
        })
            .catch(failResponse => {})



      }).catch(() => {
        // 用户点击了取消按钮或关闭按钮后的处理逻辑
      });



    }

    return{
      quesItems,
      listQuesItem,
      pageSize,
      total,
      handleCurrentChange,
      inputName,
      selectValue,
      selectOptions,
      selectQues,
      selectQuesOptions,
      searchForm,
currentPage,
      deleteQuesItem,
      quesItemSelection,
      handleSelectionChange,
      deleteBatchQuesItems,
      editQuesItem,
      editVisible,
      selectedQuesItem,
      selectedQuesItemBackup,
      rules,
      editHandleDialogClose,
      dataForm,
      resetForm,
      submitEditForm,
      quesItemName

    }
  }
}
</script>

<style scoped>
.align-left-container {
  display: flex;
  align-items: flex-start;
}
.my-table {
  height: 800px; /* 你想要的行高值 */

}
.button1 {
  display: flex;
  align-items: flex-start;
  /*margin-left: 30px;*/
  margin-bottom: 20px;

}
</style>