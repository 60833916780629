<template>
<el-card>


  <!--点击编辑按钮后呈现的界面  最开始默认是不可见的    -->
  <el-dialog
      title="修改用户信息"
      v-model="dialogFormVisible"
      :before-close="handleDialogClose">

    <!--selectedUser：被选中的用户(在点击编辑按钮时通过获得)     prop对应属性 -->
    <el-form :v-model="selectedUser" style="text-align: left" ref="dataForm" label-width="100px">
      <el-form-item prop="photo" label="头像"  class="item">
        <el-image :src="selectedUser.photo" :fit="fit" class="input"

                  :style="{ 'max-width': '100px', 'max-height': '100px' }"/>
        <el-button type="primary" @click="resetPhoto(selectedUser)" class="input">{{notify_photo}}</el-button>
      </el-form-item>
      <el-form-item prop="uname" label="账号" class="item">
       <span class="input1">{{selectedUser.uname}}</span>
<!--        <el-input v-model="selectedUser.uname" autocomplete="off" class="input"></el-input>-->
      </el-form-item>

      <el-form-item prop="status" label="用户状态" class="item" v-if="isSuper">
        <el-switch v-model="status" class="input" active-text="普通用户" inactive-text="管理员"
                   style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"/>
      </el-form-item>


      <el-form-item prop="userName" label="姓名" class="item">
        <el-input v-model="selectedUser.userName" autocomplete="off" class="input"></el-input>
      </el-form-item>

      <!--        重置密码功能-->
      <el-form-item label="密码"  prop="userPassword" class="item" >
        <el-button type="warning" @click="resetPassword(selectedUser.uname)" class="input">{{notify_password}}</el-button>
      </el-form-item>
      <el-form-item prop="userGender" label="性别" class="item">
        <el-input v-model="selectedUser.userGender" autocomplete="off " class="input"></el-input>
      </el-form-item>
      <el-form-item prop="userPhone" label="电话" class="item">
        <el-input v-model="selectedUser.userPhone" autocomplete="off" class="input"></el-input>
      </el-form-item>
      <el-form-item prop="userBirth" label="出生日期" class="item">
        <el-input v-model="selectedUser.userBirth" autocomplete="off" class="input"></el-input>
      </el-form-item>
      <el-form-item prop="userEmail" label="邮箱" class="item">
        <el-input v-model="selectedUser.userEmail" autocomplete="off" class="input"></el-input>
      </el-form-item>
      <el-form-item prop="userAddress" label="地址" class="item">
        <el-input v-model="selectedUser.userAddress" autocomplete="off" class="input"></el-input>
      </el-form-item>

      <el-form-item class="button">
        <el-button @click="submitForm(selectedUser)" color="#178071" size="large">
          <el-icon><Document /></el-icon>保存
        </el-button>

        <el-button @click="resetForm(selectedUser)" color="#d1c48f" size="large">
          <el-icon><Flag /></el-icon>重置
        </el-button>

      </el-form-item>
<!--      <el-form-item label="修改用户角色" label-width="120px" prop="roles">-->
<!--        &lt;!&ndash;<el-checkbox-group> 是多选框组来实现角色的显示与编辑，selectedRolesIds 是与多选框双向绑定的，我们通过点选就可以改变这个 Array 的值          &ndash;&gt;-->
<!--        <div style="margin-top: 20px">-->
<!--          <el-radio-group v-model="selectedUser.rid">-->
<!--            <el-radio label="1" border>普通用户</el-radio>-->
<!--            <el-radio label="2" border>管理员</el-radio>-->
<!--          </el-radio-group>-->
<!--        </div>-->
<!--      </el-form-item>-->

    </el-form>

    <!--取消和确定按钮-->
<!--    <div slot="footer" class="dialog-footer">-->
<!--      <el-button @click="dialogFormVisible = false">取 消</el-button>-->
<!--      <el-button type="primary" @click="onSubmit(selectedUser)">确 定</el-button>-->
<!--    </div>-->
  </el-dialog>


  <div style="margin-bottom: 30px" class="align-left-container">
    <el-input v-model="inputUname" placeholder="输入账号" clearable style="width: 200px;margin-right: 5px"/>
    <el-input v-model="inputUserName" placeholder="输入姓名" clearable style="width: 200px;margin-right: 5px"/>
    <el-select v-model="selectValue" clearable placeholder="选择性别" style="margin-right: 10px">
      <el-option
          v-for="item in selectOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
      />
    </el-select>
    <el-button @click="searchForm()"  size="default" type="primary">
      <el-icon><Search /></el-icon>搜索
    </el-button>
  </div>
  <el-divider />

<div class="button1">
  <add-user @onSubmit="listUsers()"></add-user>
<!--  <el-button @click="addUser()" color="#67b18e" size="large">-->
<!--    <el-icon><Plus /></el-icon>新增普通用户-->
<!--  </el-button>-->

  <el-button @click="deleteUsers()" color="#cb5765" size="large">
    <el-icon><Delete /></el-icon>批量删除
  </el-button>
</div>



  <div >
  <el-table :data="users" border stripe style="width: 100%;"
            @selection-change="handleSelectionChange" >
    <el-table-column type="selection" width="60" class="my-table"/>
    <el-table-column prop="id"  label="Id" width="60" class="my-table"/>
    <el-table-column prop="photo" label="头像" width="120" class="my-table">
      <template #default="{ row }">
      <el-image :src="row.photo" :fit="fit"

                :style="{ 'max-width': '100px', 'max-height': '100px' }"/>
      </template>
    </el-table-column>

    <el-table-column prop="uname" label="账号" width="120" class="my-table"/>

    <el-table-column prop="userName" label="姓名" width="120" class="my-table"/>
    <el-table-column prop="userGender" label="性别" width="80" class="my-table"/>
    <el-table-column prop="userPhone" label="电话" width="250" class="my-table"/>
    <el-table-column prop="userEmail" label="邮箱" class="my-table"/>
    <el-table-column prop="userAddress" label="地址" width="180" class="my-table"/>
    <el-table-column
        label="操作"
        width="150" class="my-table">
      <template #default="{ row }">

        <el-button
            @click="editUser(row)"
            type="text"
            size="default">
          编辑
        </el-button>

        <el-button
            @click="deleteUser(row)"
            type="text"
            size="default">
          移除
        </el-button>

      </template>
    </el-table-column>

  </el-table>
  </div>
  <el-pagination style="float: right;margin-top: 50px;margin-bottom: 80px"
                 background
                 layout="total, prev, pager, next, jumper"
                 @current-change="handleCurrentChange"
                 :page-size="pageSize"

                 :total="parseInt(total)">
  </el-pagination>
</el-card>
</template>

<script>
import {onMounted, reactive, ref, toRefs} from "vue";
import axios from "axios";
import {ElMessageBox} from "element-plus";
import AddUser from "@/components/back/admin/user/AddUser";

export default {
  name: "OrdinaryUsers",
  components: {AddUser},
  setup(){
    let isSuper = ref(false)
    let users = ref([]);
let inputUname = ref('');
let inputUserName = ref('');
let selectValue = ref('')
    let status = ref(true)
    const selectOptions = [
      {
        value: '男',
        label: '男',
      },
      {
        value: '女',
        label: '女',
      }]
    const fit = ref('scale-down')
    let total = ref(0)
    let pageSize = ref(4)
    let dialogFormVisible = ref(false)
    let selectedUser = ref({});
    let selectedUserBackup = ref({});
    let rid = ref('')
    let notify_password = ref('重置密码')
    let notify_photo = ref('重置头像')
    let search = ref(0)
    let userSelection = ref([])

    const searchForm=()=>{
      search.value = 1
      console.log(inputUname.value, inputUserName.value, selectValue.value)
      axios.get('/user/searchOrdinaryUsers/' + pageSize.value + '/1', {
        params: {
          uname: inputUname.value,
          userName: inputUserName.value,
          userGender: selectValue.value
        }}).then(resp => {
        if(resp &&  resp.data.code === 200){


          users.value = resp.data.result.content
          users.value.forEach((item, index) => {
            item.id = index + 1;
          });
          total.value = resp.data.result.totalElements
          console.log("普通用户")
          console.log(resp.data.result)
        }
      })
    }
    const listUsers=()=>{

      axios.get('/user/userInformation/' + pageSize.value + '/1').then(resp => {
        if(resp &&  resp.data.code === 200){
          console.log("返回数据")
          rid.value = resp.data.result.rid
          console.log("rid",rid)
          if(rid.value === 1){
            isSuper.value = true
          }
          users.value = resp.data.result.users.content
          users.value.forEach((item, index) => {
            item.id = index + 1;
          });
          total.value = resp.data.result.users.totalElements
          console.log("普通用户")
          console.log(resp.data.result)
        }
      })
    }
    const handleCurrentChange=(page)=>{
      if(search.value === 0){//默认
        axios.get('/user/userInformation/' + pageSize.value + '/' + page).then(resp =>{
          if(resp &&  resp.data.code === 200){
            users.value = resp.data.result.users.content
            users.value.forEach((item, index) => {
              item.id = (page - 1) * pageSize.value + index + 1;
            });
            total.value = resp.data.result.users.totalElements
            //console.log(resp.data.result)
          }
        })
      }else if(search.value === 1){//搜索条件
        console.log(inputUname.value, inputUserName.value, selectValue.value)
        axios.get('/user/searchOrdinaryUsers/' + pageSize.value + '/' + page, {
          params: {
            uname: inputUname.value,
            userName: inputUserName.value,
            userGender: selectValue.value
          }}).then(resp => {
          if(resp &&  resp.data.code === 200){


            users.value = resp.data.result.content
            users.value.forEach((item, index) => {
              item.id = (page - 1) * pageSize.value + index + 1;
            });
            total.value = resp.data.result.totalElements
            console.log("普通用户")
            console.log(resp.data.result)
          }
        })
      }

    }

    const handleDialogClose=(done)=> {

        done(); // 关闭弹出框
      notify_password.value ='重置密码'
      notify_photo.value = '重置头像'
      status.value = true
    }

    const editUser =(user)=>{
      dialogFormVisible.value = true
      selectedUser.value = { ...user } // 使用对象扩展运算符将 user 的属性值复制到新的对象中
      selectedUserBackup.value = { ...user }
      console.log("selectedUser",selectedUser)

    }
    const deleteUser =(user)=>{

      ElMessageBox.confirm("确定要删除该用户吗？", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消', // 添加取消按钮
        //showCancelButton: false,
        closeOnClickModal: false,
        type: 'warning',
        draggable: true,
      }).then(()=>{
        console.log("修改用户",user)
        axios.put('/user/deleteUser',
            {uname: user.uname}
        ).then(resp =>{
          if(resp &&  resp.data.code === 200){
            ElMessageBox.confirm(resp.data.result, '提示', {
              confirmButtonText: '确定',
              //cancelButtonText: '取消', // 添加取消按钮
              showCancelButton: false,
              closeOnClickModal: false,
              type: 'warning',
              draggable: true,
            }).then(()=>{

             window.location.reload()
            }).catch(() => {
              // 用户点击了取消按钮或关闭按钮后的处理逻辑
            });
          }
        })
      }).catch(() => {
        // 用户点击了取消按钮或关闭按钮后的处理逻辑
      });


    }
    const resetForm =()=>{

      selectedUser.value = selectedUserBackup.value
      notify_password.value ='重置密码'
      notify_photo.value = '重置头像'
      status.value = true
    }

    const submitForm =(user)=>{
          ElMessageBox.confirm("确定要修改用户信息吗？", '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消', // 添加取消按钮
            //showCancelButton: false,
            closeOnClickModal: false,
            type: 'warning',
            draggable: true,
          }).then(()=>{
            console.log("修改用户",user)
            axios.put(`/user/updateInformation?status=${status.value}`,
                user
            ).then(resp =>{
              if(resp &&  resp.data.code === 200){
                ElMessageBox.confirm(resp.data.result, '提示', {
                  confirmButtonText: '确定',
                  //cancelButtonText: '取消', // 添加取消按钮
                  showCancelButton: false,
                  closeOnClickModal: false,
                  type: 'warning',
                  draggable: true,
                }).then(()=>{
                  notify_password.value ='重置密码'
                  notify_photo.value = '重置头像'
                  dialogFormVisible.value = false
                  window.location.reload()
                }).catch(() => {
                  // 用户点击了取消按钮或关闭按钮后的处理逻辑
                });
              }
            })
          }).catch(() => {
            // 用户点击了取消按钮或关闭按钮后的处理逻辑
          });

    }

    const resetPassword =(username)=>{

      axios.put('/user/resetPassword',
          {uname: username}
      ).then(resp =>{
        if(resp &&  resp.data.code === 200){
          ElMessageBox.confirm("密码已重置为123456", '提示', {
            confirmButtonText: '确定',
            //cancelButtonText: '取消', // 添加取消按钮
            showCancelButton: false,
            closeOnClickModal: false,
            type: 'warning',
            draggable: true,
          }).then(()=>{
            notify_password.value = "密码已重置为123456"
          }).catch(() => {
            // 用户点击了取消按钮或关闭按钮后的处理逻辑
          });
        }
      })
    }

    const resetPhoto =(user)=>{

      user.photo = 'http://localhost:8443/user/photo/default.png'
notify_photo.value = '头像已重置为初始头像'
    }

    const handleSelectionChange = (val) => {
      userSelection.value = val.map(row => row.uname);
      console.log(userSelection.value)
    }

    const deleteUsers = () =>{
      ElMessageBox.confirm("确定要删除这些用户吗？", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消', // 添加取消按钮
        //showCancelButton: false,
        closeOnClickModal: false,
        type: 'warning',
        draggable: true,
      }).then(()=>{

        axios.put('/user/batchDeleteUsers',
            userSelection.value
        ).then(resp =>{
          if(resp &&  resp.data.code === 200){
            ElMessageBox.confirm(resp.data.result, '提示', {
              confirmButtonText: '确定',
              //cancelButtonText: '取消', // 添加取消按钮
              showCancelButton: false,
              closeOnClickModal: false,
              type: 'warning',
              draggable: true,
            }).then(()=>{

              window.location.reload()
            }).catch(() => {
              // 用户点击了取消按钮或关闭按钮后的处理逻辑
            });
          }
        })
      }).catch(() => {
        // 用户点击了取消按钮或关闭按钮后的处理逻辑
      });

    }
        onMounted(() => {
      listUsers();
    });

        return{
          users,
          listUsers,
          fit,
          handleCurrentChange,
          pageSize,
          total,
          dialogFormVisible,
          selectedUser,
          editUser,
          resetPassword,
          resetPhoto,
          notify_password,
          notify_photo,
          resetForm,
          selectedUserBackup,
          handleDialogClose,
          submitForm,
          inputUname,
          inputUserName,
          selectOptions,
          selectValue,
          searchForm,
          search,
          deleteUser,
          handleSelectionChange,
          deleteUsers,
          status,
          isSuper,
    }
  }
}
</script>

<style scoped>
.item{
  width: 600px;
}
.input {
  margin-left: 30px; /* input 左侧外边距 */
}
.input1 {
  margin-left: 40px; /* input 左侧外边距 */
}
.button {
  display: flex;
  align-items: flex-start;
  margin-left: 30px;
  margin-bottom: 45px;

}
.button1 {
  display: flex;
  align-items: flex-start;
  /*margin-left: 30px;*/
  margin-bottom: 20px;

}
.my-table {
  height: 200px; /* 你想要的行高值 */

}
.align-left-container {
  display: flex;
  align-items: flex-start;
}
</style>