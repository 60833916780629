import { createStore } from 'vuex'

export default createStore({
  state: {
    flag : false,
    username: window.localStorage.getItem('username') == null ? '' :
        JSON.parse(window.localStorage.getItem('username' || '[]')),
    Menus:[]

  },
  getters: {
  },
  mutations: {
    initMenu(state, menus){
      state.Menus = menus
      window.localStorage.setItem('menus', JSON.stringify(menus))
    },

    saveMenu(state, menus){
      state.Menus = menus
    }
,
    login(state, data){
      state.username = data
      window.localStorage.setItem('username', JSON.stringify(data))
    },
    logout(){
      //state.user = []
      //state.isLogin = false
      // state.flag = true
      window.localStorage.removeItem('username')
      window.localStorage.removeItem('menus')
      window.localStorage.removeItem('allMenusPath')
      window.localStorage.removeItem('userMenusPath')
    }
  },
  actions: {
  },
  modules: {
  }
});

