
import { createApp } from 'vue'
import Vue from 'vue'
import App from './App.vue'
import router, {routes} from './router'
import store from './store'
import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import * as Icons from '@element-plus/icons'

import 'element-plus/dist/index.css'





// 设置反向代理，前端请求默认发送到 http://localhost:8080/api
//import Axios from 'axios';//引入axios
import axios from 'axios'

axios.defaults.baseURL = 'http://localhost:8443'
//axios.defaults.withCredentials = true
axios.defaults.withCredentials = true

const app = createApp(App)

app.config.globalProperties.$axios=axios //全局配置axios

import BackView from "@/views/BackView";
import Result from "@/components/back/admin/result/Result";
import Theme from "@/components/back/admin/evaluation/Theme";
import Topic from "@/components/back/admin/evaluation/Topic";
import Record from "@/components/back/admin/evaluation/Record";
import Theme108 from "@/components/back/admin/evaluation/Theme108";
import Topic108 from "@/components/back/admin/evaluation/Topic108";
import Record108 from "@/components/back/admin/evaluation/Record108";
import Speciality from "@/components/back/admin/sys/Speciality";
import QuesAndAnswers from "@/components/back/admin/sys/QuesAndAnswers";
import OrdinaryUsers from "@/components/back/admin/user/OrdinaryUsers";
import Administrators from "@/components/back/admin/user/Administrators";
import Profile from "@/components/back/admin/setting/Profile";
import ChangePassword from "@/components/back/admin/setting/ChangePassword";
import Logout from "@/components/back/admin/setting/Logout";
import Welcome from "@/components/back/admin/Welcome";





app.use(ElementPlus)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

// 注册全局组件
Object.keys(Icons).forEach(key => {
    app.component(key, Icons[key]);
});


// const exportText = (data) => {
//     const name = "文件名称";
//
//     const element = document.createElement('a');
//     element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data));
//     element.setAttribute('download', name);
//     element.style.display = 'none';
//     document.body.appendChild(element); // 添加到DOM树
//     element.click();
//     document.body.removeChild(element); // 从DOM树中移除
// }


const initMenu = async (router, store) => {
    // console.log("initMenu开始----------------------------")
    //  if(store.state.Menus.length > 0){
    //      return
    //  }

    // try{
        await axios.get('/menu').then(resp => {
            // console.log(resp)
            if (resp && resp.status === 200) {
                let data = resp.data

                let cleanedData = JSON.stringify(data).replace(/\n|\r|\s/g, '');

// 将清理后的字符串拼接成数组形式的字符串
                let dataArrayString = `${cleanedData}`;
                // console.log("dataArrayString",dataArrayString)
                // exportText(dataArrayString)
                //  let fmtRoutes = formatRoutes(JSON.parse(dataArrayString) )
                let fmtRoutes = formatRoutes(resp.data )
                // console.log("resp.data",resp.data)


                fmtRoutes.forEach((route) => {

                    router.addRoute(route);
                })
                //console.log(router)
                //router.addRoute(fmtRoutes[1])
                // for (let i = 0; i < fmtRoutes.length; i++) {
                //     // let route = fmtRoutes[i];
                //     // router.addRoute(route);
                //     console.log(fmtRoutes[i])
                // }
                // router.addRoute(fmtRoutes[0])
                // router.addRoute(fmtRoutes[1])
                // router.addRoute(fmtRoutes[2])

                //console.log('路由2')

                // console.log("router")
                // console.log(router)
                //router.addRoute(fmtRoutes)
                //console.log("ababaabab")
                //console.log(router.options.routes);


                store.commit('initMenu', JSON.parse(dataArrayString))
                //console.log('hahahaha')
                // console.log("函数")
                // console.log(store.state.Menus)
                //return fmtRoutes;
                //console.log("JSON.parse(window.localStorage.getItem('menus'))",JSON.parse(window.localStorage.getItem('menus')))
            }
        })
    // }catch (error) {
    //     // 处理Axios错误（网络错误等）
    //     console.error('请求错误:', error);
    //
    // }
    // console.log("initMenu结束----------------------------")
}

const formatRoutes = (routes) =>{
    let fmtRoutes = []
    routes.forEach(route =>{
        if(route.children){
            route.children = formatRoutes(route.children)
        }
        let fmtRoute ={
            path: route.path,
            component:() => import(`@/${route.component}.vue`)
            //'/src/components/back/admin/Welcome'
            //component: () => route.component
            ,
            name: route.name,
            nameZh: route.nameZh,
            iconCls: route.iconCls,
            meta:{
                requireAuth: true
            },
            children: route.children
        }
        fmtRoutes.push(fmtRoute)
    })
    return fmtRoutes
}

// const rrroute = () =>{
//     if(window.localStorage.getItem('menus') != null){
//
//         const route_data = JSON.parse(window.localStorage.getItem('menus'));
//
//         let fmtRoutes = formatRoutes(route_data)
//
//
//
//         fmtRoutes.forEach((route) => {
//
//             router.addRoute(route);
//         })
//
//     }
//
// }
const initMenu_not = async (router, store) => {
    // try {
        // console.log("刚登陆1")
        await axios.get('/menu').then(resp => {
            // console.log(resp)
            if (resp && resp.status === 200) {
                // console.log("刚登陆2")
                let data = resp.data

                let cleanedData = JSON.stringify(data).replace(/\n|\r|\s/g, '');
                // let dataArrayString = `${cleanedData}`;
                let fmtRoutes = formatRoutes(resp.data )
                // console.log("resp.data",resp.data)

                fmtRoutes.forEach((route) => {

                    router.addRoute(route);
                })
                store.commit('initMenu', JSON.parse(cleanedData))
            }
        })

    // }catch (error){
    //     console.error('请求错误:', error);
    // }

}



const initMenu_login = async (router, store) => {
    try {
        await axios.get('/admin/auth').then(resp => {
            // console.log("menu",resp)
            if (resp && resp.status === 200) {
                // console.log("已登录，准备从本地获取菜单")

                let fmtRoutes = formatRoutes(JSON.parse(window.localStorage.getItem('menus')))
                // console.log("fmtRoutes1",fmtRoutes)
                fmtRoutes.forEach((route) => {
                    router.addRoute(route);
                })

                store.commit('saveMenu', fmtRoutes)
                //store.state.Menus = fmtRoutes
                // console.log("已登录，获取菜单完毕")

            }else{

            }
        })
    } catch (error) {
        // 处理Axios错误（网络错误等）
        console.error('请求错误1:', error);
      //  store.commit('logout')
    }

}




app.use(store)
async function init() {
    // window.localStorage.getItem('username') !== null&& window.localStorage.getItem('menus') === null


    if(window.localStorage.getItem('menus') !== null){
        await initMenu_login(router, store)
    }else if(window.localStorage.getItem('username') !== null && window.localStorage.getItem('menus') === null){

        await initMenu_not(router, store)
    }

    //await initMenu(router, store)

}
async function call() {
    await init()

    app.use(router).mount('#app')
    // console.log("刚登陆3")
}
let allPath = []





async function getMenusPath() {
    if(window.localStorage.getItem('allMenusPath') === null){
        await axios.get('/getAllMenuPath').then(resp => {
            // console.log("menuPath", resp)
            if (resp && resp.status === 200) {
               window.localStorage.setItem('allMenusPath', JSON.stringify(resp.data.result))
            }
        })
    }
    // try {
        if(window.localStorage.getItem('username') !== null){
            if(window.localStorage.getItem('userMenusPath') === null){
                await axios.get('/admin/getUserMenuPath').then(resp => {
                    // console.log("menuPath", resp)
                    if (resp && resp.status === 200) {
                        window.localStorage.setItem('userMenusPath', JSON.stringify(resp.data.result))
                    }
                })
            }else{

            }
        }

    // } catch (error) {
    //     // 处理Axios错误（网络错误等）
    //     console.error('请求错误:', error);
    // }
}
call()
getMenusPath()



let fmtRoutes = []
const getStaticPath = (routes) =>{

    routes.forEach(route =>{
        if(route.children){
            fmtRoutes.push(route.path)
           getStaticPath(route.children)
        }else{

            fmtRoutes.push(route.path)
        }

        // console.log(fmtRoute.path)
        //console.log("fmtRoute")

        //console.log(fmtRoute.component())
    })

    return fmtRoutes
}
// let hasRoles = true
//判断是否是静态路由的路径
function isStaticRoutePath(path) {

    const staticRoutes = Array.from(new Set(getStaticPath(routes)));
    fmtRoutes = []
// console.log("staticRoutes", staticRoutes)
    return staticRoutes.includes(path);
}

router.beforeEach(     async (to, from, next) => {

// console.log("进入路由守护了")
    // console.log("to", to)


    //     try{
    //     await axios.get('/com/handleError').then(resp => {
    //
    //         if (resp && resp.status === 200) {
    //
    //             next()
    //         }else{
    //             window.location.reload()
    //             console.log("后台已关闭")
    //         }
    //     })
    //
    // } catch (error) {
    //     // 处理Axios错误（网络错误等）
    //     // console.error('请求错误:', error);
    // }



    // if(window.localStorage.getItem('menus') != null){
    //
    //     const route_data = JSON.parse(window.localStorage.getItem('menus'));
    //
    //     let fmtRoutes = formatRoutes(route_data)
    //
    //
    //     console.log("00000000000000000")
    //     fmtRoutes.forEach((route) => {
    //
    //         router.addRoute(route);
    //     })
    //
    //     console.log("1111111111111111")
    //     console.log("to",to)
    // }
    if (window.localStorage.getItem('username') === null) {
       // await getMenusPath()
if(window.localStorage.getItem('allMenusPath') !== null){
    // console.log("allPath不为空")
    allPath = JSON.parse(window.localStorage.getItem('allMenusPath'))
}
        // console.log("allPath",allPath)
        //未登录
        if (isStaticRoutePath(to.path)) {
            //console.log("true---------------------")
            next()
        }else if(allPath.includes(to.path)){
            next('/login')
        }
        else {
            // console.log("false---------------------")
            next('/error404')
        }
    } else {
            try {
                await axios.get('/admin/auth').then(resp => {
                    if (resp && resp.status === 200) {
                        // window.localStorage.setItem('allMenusPath', JSON.stringify(resp.data.result.allMenusPath))
                        // window.localStorage.setItem('userMenusPath', JSON.stringify(resp.data.result.userMenusPath))
                        let allMenusPath = JSON.parse(window.localStorage.getItem('allMenusPath'))
                        let userMenusPath = JSON.parse(window.localStorage.getItem('userMenusPath'))
                        if (isStaticRoutePath(to.path)) {
                            if(to.path === "/login"){
                                next('/admin/welcome')
                            }
                            next()
                            //console.log("true---------------------")
                        }else if(userMenusPath.includes(to.path)){//用户的动态路由
                            next()
                        }else if(allMenusPath.includes(to.path)){//所有用户的动态路由
                            next('/limit')
                        }else{//错误的路由
                            next('/error404')
                        }
                            }else{
                        next('/login')
                        // console.log("访问后端失败")
                        store.commit('logout')
                    }
                })
            } catch (error) {
                // 处理Axios错误（网络错误等）
                console.error('请求错误2:', error);
            }




        // console.log("2没通过 ")
        // store.commit('logout')
        // store.state.username = ''
        // store.state.Menus = ''
        // store.state.flag = false
        // router.replace('/login')

    }

    // if (store.state.username && to.path.startsWith('/admin')) {
    //     console.log("到5了")
    //     //await new Promise(resolve => setTimeout(resolve, 1000));
    //     //router.removeRoute();
    //     // //init()
    //     // http://localhost:8888/admin/welcomeinitMenu(router, store);
    //
    //     // next({...to, replace: true});
    //
    //     //router.push(to)
    //     //next()
    //     // axios.get('/menu').then(resp => {
    //     //     console.log(resp)
    //     //     if (resp && resp.status === 200) {
    //     //         console.log("刚登陆2")
    //     //         let data = resp.data
    //     //
    //     //         let cleanedData = JSON.stringify(data).replace(/\n|\r|\s/g, '');
    //     //
    //     //
    //     //         let dataArrayString = `${cleanedData}`;
    //     //
    //     //         let fmtRoutes = formatRoutes(resp.data)
    //     //         console.log("resp.data", resp.data)
    //     //
    //     //
    //     //         fmtRoutes.forEach((route) => {
    //     //
    //     //             router.addRoute(route);
    //     //         })
    //     //
    //     //         store.commit('initMenu', JSON.parse(dataArrayString))
    //     //
    //     //     }
    //     // })
    //     //console.log("2222222222222222")
    //     //console.log(store.state.Menus)
    //     // if (hasRoles) {
    //     //     let fmtRoute;
    //     //     console.log("加载")
    //     //     fmtRoute = JSON.parse(localStorage.getItem("menus"));
    //     //     fmtRoute.forEach(item => router.addRoute(item))
    //     //     hasRoles = false
    //     //     router.push({...to, replace: true})
    //     // }
    // }
    // if(to.path === "/admin/welcome"){
    //     next("/admin")
    //
    //
    // }


    // if (to.meta.requireAuth) {
    //     if (store.state.username) {
    //
    //         axios.get('/com/authentication').then(resp => {
    //             if (resp) {
    //                 console.log("1通过 ")
    //
    //                 next()
    //             }
    //         }).catch(error => {
    //
    //             store.commit('logout')
    //             store.state.username = ''
    //             store.state.Menus = ''
    //             store.state.flag = false
    //             router.replace('/login')
    //         })
    //     } else {
    //         console.log("3通过 ")
    //         next({
    //             path: '/login',
    //             query: {redirect: to.fullPath}
    //         })
    //     }
    // }
    // else if (store.state.username && to.path.startsWith("/login")) {//已登录，访问login页面
    //     axios.get('/com/authentication').then(resp => {
    //         if (resp) {
    //             console.log("2通过 ")
    //             next({
    //                 //name: 'backIndex'
    //                 path: '/admin/welcome'
    //             });
    //
    //         }
    //     }).catch(error => {
    //         console.log("2没通过 ")
    //         store.commit('logout')
    //         store.state.username = ''
    //         store.state.Menus = ''
    //         store.state.flag = false
    //         router.replace('/login')
    //         //location.reload()
    //     })
        // } else if (store.state.username && !store.state.flag) {
        //     axios.get('/com/authentication').then(resp => {
        //         if (resp) {
        //
        //             console.log(to)
        //             console.log("4通过 ")
        //
        //             next()
        //         }
        //     }).catch(error => {
        //         console.log("4没通过 ")
        //         store.commit('logout')
        //         store.state.username = ''
        //         store.state.Menus = ''
        //         next()
        //     })
        //
        //
        // } else if (store.state.username && store.state.flag) {
        //     store.state.username = ''
        //     store.state.Menus = ''
        //     next()
    // }
    // else {
    //     next()
    // }

})
axios.interceptors.response.use(
    response => {
        return response
    },
    error => {
        if (error) {
            // console.error('请求错误:', error);
           // store.commit('logout')
            // if (error.message === 'Network Error') {
            //     window.location.reload() //
            // }
            // window.location.href = '/index';
            // window.location.reload()
            // router.replace('/index')
            // router.replace('/login')
        }
        // 返回接口返回的错误信息
        //return Promise.reject(error)
    })