<template>

  <el-card class="card-container">

    <div class="image-container" style="width: 200px;">

      <router-link class="qxk-link" :to="{ path: '/index' }">
        <el-image src="/backhead.png" :fit="fitMode"/>

      </router-link>

      <span class="hide-on-small-screen"
            style="font-size: 32px;font-weight: bold;position: absolute;margin-top: 30px;right: 40%;">高中学业选科系统</span>
      <!--          -->

      <div class="flex justify-space-between mb-4 flex-wrap gap-4" >
        <div class="right-aligned-container" v-if="windowWidth >= 500" >
          <div class="menu-button-container" >


            <el-button size="large" text key="" type="" style="height: 80px;">

              <el-dropdown trigger="click" @command="handleCommand" >
                <div>
                  <el-image :src="img[0].imgSrc" class="menu-item-image" :fit="fitMode"
                            style="margin-top:0px; margin-right: 8px"
                            :style="{ 'max-width': '50px', 'max-height': '50px' }"/>

                  <span class="menu-item-text" style="font-weight: bold;">{{ img[0].navItem }}{{ img[0].name }}</span>
                </div>

                <template #dropdown >
                  <el-dropdown-menu  style="width: 180px;">
                    <!--                  <el-dropdown-item>Action 1</el-dropdown-item>-->
                    <!--                  <el-dropdown-item>Action 2</el-dropdown-item>-->
                    <!--                  <el-dropdown-item>Action 3</el-dropdown-item>-->
                    <!--                  <el-dropdown-item disabled>Action 4</el-dropdown-item>-->
                    <el-dropdown-item command="logout" >
                      <el-icon>
                        <SwitchButton/>
                      </el-icon>
                      退出系统
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>

              </el-dropdown>
            </el-button>
          </div>
        </div>
      </div>


      <!--          <el-icon style="font-size: 32px;font-weight: bold;position: absolute;margin-top: 40px;margin-left: 1600px;">-->
      <!--            <div @click="logout">-->
      <!--              <CloseBold/>-->
      <!--            </div>-->
      <!--          </el-icon>-->
      <!--                    <i class="el-icon-switch-button" v-on:click="logout" style="position: absolute;margin-top: 40px;margin-left: 500px;"></i>-->
    </div>

  </el-card>

</template>

<script>
//通过export方式导出，在导入时要加{ }，export default则不需要


import {onMounted, ref} from "vue";
import axios from "axios";
import {useStore} from 'vuex'
import {useRouter} from "vue-router";
import {ElMessage, ElMessageBox} from "element-plus";
import store from '@/store';


export default {
  name: "header",
  setup() {

    const windowWidth = ref(window.innerWidth);
    const username = ref(store.state.username);
    console.log(1)
    console.log(username)
    const handleResize = () => {
      windowWidth.value = window.innerWidth;
    };
    window.addEventListener('resize', handleResize);
    const fitMode = ref('scale-down');
    const router = useRouter();

    const store1 = useStore()

    const img = ref([{name: '', navItem: '欢迎您：', imgSrc: "", path: "/index"}]);

    const logout = () => {

      ElMessageBox.confirm('确认退出系统吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消', // 添加取消按钮
        // showCancelButton: false,
        type: 'warning',

      }).then(() => {
        axios.get('/com/logout').then(resp => {
          if (resp && resp.data.code === 200) {
            //resp.data.result
            store1.commit('logout');
            store1.state.username = ''
            store1.state.Menus = ''
            store1.state.flag = false
            router.push('/index');
            //location.reload();
          }
        }).catch(failResponse => {
        });

      }).catch(() => {
        ElMessage({
          type: 'info',
          message: '取消退出账户',
        })
      })


    };

    const getTypeAndImg = async () => {
      try {
        const resp = await axios.get('/com/status');
        if (resp && resp.data.code === 200) {
          console.log(resp.data);
          img.value[0].name = resp.data.result.name
          img.value[0].imgSrc = resp.data.result.img
          // 更新响应式变量的值
          console.log("hhhhhh")
          username.value = resp.data.result.name;
        }
      } catch (error) {
        // 处理错误
      }
    };
    const handleCommand = (command) => {
      if (command == "logout") {
        logout();
        // ElMessageBox.confirm('确认退出系统吗？', '提示', {
        //   confirmButtonText: '确定',
        //   cancelButtonText: '取消',
        //   type: 'warning',
        // })
        //     .then(() => {
        //       logout();
        //     })
        //     .catch(() => {
        //
        //     });
      }
    }
    onMounted(() => {
      getTypeAndImg();
    });

    return {
      fitMode,
      logout,
      windowWidth,
      username,
      img,
      handleCommand

    };
  },
  // methods:{
  //   // logout(){
  //   //   var _this = this
  //   //   this.$axios.get('/logout').then(resp => {
  //   //     if(resp && resp.data.code === 200){
  //   //       _this.$store.commit('logout')
  //   //       //_this.$router.replace('/index')
  //   //       _this.$router.replace('/index')
  //   //       // 清空路由，防止路由重复加载
  //   //       const newRouter = createRouter()
  //   //       _this.$router.matcher = newRouter.matcher
  //   //     }
  //   //   }).catch(failResponse => {})
  //   // }
  // }
};
</script>

<style scoped>
.admin-header {
  height: 80px;
  opacity: 0.85;
  line-height: 40px;
  min-width: 900px;
}

.el-icon-switch-button {
  cursor: pointer;
  outline: 0;
}

.card-container {
  width: 100%;
  background-color: #c6e2ff;
  opacity: 0.75;
  height: 80px;
  display: flex;
  justify-content: flex-start; /* 左对齐 */
  align-items: center;
}

.right-aligned-container {
  position: absolute;
  top: 0;
  right: 1%;
  display: flex;
  align-items: center;
}

.menu-item-text {
  font-weight: bold;
  vertical-align: middle; /* 调整垂直对齐方式 */
  display: inline-block; /* 使 span 成为块级元素 */
  margin-top: -40px; /* 根据实际情况微调位置 */
}

@media (max-width: 850px) {
  .hide-on-small-screen {
    display: none;
  }
}

</style>








