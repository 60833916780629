<template>
  <div>
    <div id="myChart" :style="{ width: '600px', height: '400px' }"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: 'Radar',
  data () {
    return {
      result2: [],

    }
  },
  // setup(){
  //   onMounted(() => {
  //     $bus.on('data2',(val)=>{
  //       console.log('接收到消息',val)
  //       console.log(val)
  //       console.log("a")
  //
  //       //this.result2 = val;
  //       console.log(this.result2)
  //       console.log("b")
  //     })
  //   })
  // },
  created() {
    this.result2 =  JSON.parse(this.$route.query.result)
    // $bus.on('data2', (val) => {
    //   console.log("2")
    //   this.result = val;
    //   console.log(this.result)
    //   console.log("3")
    //
    // });
  },
  mounted() {

    // console.log(this.result)

    // this.drawLine();

    // console.log(this.result2)
        this.drawLine();
    // console.log("6")

  },

  methods: {
    drawLine() {

      // console.log("88")
      //
      // console.log(this.result2)
      // console.log("99")
      let myChart = echarts.init(document.getElementById('myChart'));
      let dataMax = [
        { name: '物理', max: '100' },
        { name: '化学', max: '100' },
        { name: '生物', max: '100' },
        { name: '政治', max: '100' },
        { name: '地理', max: '100' },
        { name: '历史', max: '100' },
      ];
      let option = {
        radar: {
          axisName: {
            show: true,
            color: 'red',
          },
          indicator: dataMax,
          shape: 'circle',
        },
        series: [
          {
            type: 'radar',
            label: {
              show: true,
            },
            areaStyle: {},
            data: [
              {
                name: '测评成绩',
                value: [
                  parseInt(this.result2["wuli"]),
                  this.result2["huaxue"],
                  this.result2["shengwu"],
                  this.result2["zhengzhi"],
                  this.result2["dili"],
                  this.result2["lishi"],
                ],
              },
            ],
          },
        ],
      };

      myChart.setOption(option);
    },
  },
}
</script>

<style>
/* 可以添加样式 */
</style>
