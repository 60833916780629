<template>
  <el-card class="card-carousel" >
    <el-carousel class="push" :interval="4000" arrow="always">
      <el-carousel-item v-for="item in items" >
<!--        <a :href=item.link target="_blank">-->

          <img :src=item.img  class="carousel_img">

<!--        <h3>{{item.title}}</h3>-->
      </el-carousel-item>
    </el-carousel>
  </el-card>
</template>

<script>
export default {
  name: "Carousel",
  data: function (){
    return{
      items:[{
        id: 1,
        //相对路径，直接从public文件夹下开始
        img:' http://localhost:8888/carousel/1.jpg'
      },
        {
          id: 2,
          img:'http://localhost:8888/carousel/2.jpg'
        },
        {
          id: 3,
          img:'http://localhost:8888/carousel/3.jpg'
        },
        {
          id: 4,
          img:'http://localhost:8888/carousel/4.jpg'
        }]
    }
  }
}
</script>

<style scoped>
/*.el-carousel__item h3 {*/
/*  color: black;*/
/*  font-size: 15px;*/
/*  !*line-height: 300px;*!*/
/*  bottom: 43px;*/
/*  float: left;*/
/*  position: relative;*/
/*}*/

.card-carousel {
  /*margin: 0 auto;*/

  /*margin-left: 20px;*/
  width: 84%;
  /*width: 1300px;*/
  height: 320px;
}

.push {
  /*margin-left: -15px;*/
}

.el-carousel__item:nth-child(2n) {
  background: black;
}

.el-carousel__item:nth-child(2n+1) {
  background: black;
}

.carousel_img {
  height: 100%;
  width: 100%;
  text-align: center;
}
</style>