<template>
  <div class="common-layout">
    <el-container>

      <el-header class="custom-header" style="height: 80px;">
        <Header></Header>
      </el-header>

      <el-container  >
<!--          style="height: 120%;margin-top: 0px;width: 250px"-->
          <el-aside class="aside" style=";width: 13%;">
            <div class="el-menu-container">
              <Menu style="height: 1000px;"></Menu>
            </div>
          </el-aside>


        <el-main class="main-container">
          <router-view class="router-view" ></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import { ref } from "vue";
import Header from "@/components/back/common/header";

import Menu from "@/components/back/admin/Menu";
import router from "@/router";
import store from "@/store";

export default {
  name: "BackView",
  components: {Menu,  Header},
  setup() {
    const fitMode = ref('scale-down');

    return {
      fitMode,
    };
  },
};
</script>

<style scoped>
.custom-header {
  width: 100%;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: none;
}
.main-container {
  background-image: url('../../public/back.jpg');
  background-size: cover;
  background-repeat: no-repeat;

}
</style>
