<template>

  <el-dialog

      title="导入测评题目（请上传Excel文件）"
      v-model="dialogFormVisible"
      :before-close="handleDialogClose"
class = "elDialog"

  >


    <el-form  :model="importQuesItem" style="text-align: left" ref="dataForm" label-width="100px"
              status-icon >

      <el-form-item prop="ques" label="测评主题" class="item">
        <span class="input">{{quesName}}</span>
        <!--        <el-input v-model="AddQuesItem.ques" autocomplete="off" class="input"></el-input>-->
        <!--        <el-input v-model="selectedUser.uname" autocomplete="off" class="input"></el-input>-->
      </el-form-item>



      <el-upload
          class="upload-demo"
          drag
          action="#"
          ref="dialogRef"
          :auto-upload="false"
          :on-change="uploadByJsqd"
          :file-list="fileLists"
          :limit="2"
          with-credentials
          multiple
      >
        <div   v-if="visibleIcon">
        <el-icon class="el-icon--upload"><upload-filled /></el-icon>
        <div class="el-upload__text">
          拖拽到此处或 <em>点击添加</em>
        </div>
        </div>

        <div class="el-upload__text" v-else>
          <div class="menu-item-content">
            <el-image src="/excel.jpg" class="menu-item-image" :fit="fitMode" style="margin-bottom: 8px; margin-right: 8px" :style="{ 'max-width': '80px', 'max-height': '80px' }"/>
          </div>
          {{fileName}}
        </div>

        <template #tip>
          <div class="el-upload__tip">
            Excel文件的大小不能超过2MB
          </div>
        </template>

      </el-upload>





      <el-form-item class="button">
        <el-button @click="uploadFile()" color="#178071" size="large">
          <el-icon><Document /></el-icon>上传
        </el-button>

        <el-button @click="resetFile()" color="#d1c48f" size="large">
          <el-icon><Flag /></el-icon>重置
        </el-button>

      </el-form-item>
    </el-form>


  </el-dialog>

</template>

<script>
import {ref, watch} from "vue";
import { ElMessage} from "element-plus"

const {ElMessageBox} = require("element-plus");
const axios = require("axios");

export default {
  name: "ImportQuesItem",
  props: {
    isVisible: Boolean, // 声明接收名为 "message" 的 prop
    message: String,
  },
  emits: ['update:isImportVisible', "changeImportVisible"], // 声明事件用于更新初始可见性状态
  setup(props, { emit }){

    console.log(props.message)
    let dialogFormVisible = ref(props.isVisible)
    let quesName = ref(props.message)
    let importQuesItem = ref({})
    let visibleIcon = ref(true)
    const fitMode = ref('scale-down');
    watch(() => props.isVisible, (newValue) => {
      console.log("props.isImportVisible",props.isVisible);
      dialogFormVisible.value = newValue;


    });

    watch(() => props.message, (newValue) => {
      console.log(props.message);
      quesName.value = newValue;
      console.log("测评名hhh",quesName.value)
      // listQuesItem()
    });





    const handleDialogClose=(done)=> {

      done();


      // dataForm.value.resetFields();

      // props.isVisible = false
      // console.log(props.isVisible)
      // console.log(dialogFormVisible.value)

      emit("changeVisible", false);
      //emitFunctions("changeVisible", false)
    }

    let fileLists = ref([])
    console.log("kakakk", fileLists)
    const beforeAvatarUpload=(file)=>{

      let fileArr = file.name.split('.')
      let suffix = fileArr[fileArr.length - 1]
      //只能导入.xls和.xlsx文件
      if (!/(xls|xlsx)/i.test(suffix)) {
        ElMessage.warning('上传的文件格式不正确！')
        // if (fileLists.length === 0) {
        //   fileLists.value = [];
        // } else {
        //   fileLists.value.splice(1);
        // }
        return false
      }
      //不能导入大小超过2Mb的文件
      if (file.size > 2 * 1024 * 1024) {
        ElMessage.warning('文件过大，请上传小于2MB的文件〜')

        return false
      }
      return true

    }
    let fileName = ref('')
    let formData = new FormData();
    const uploadByJsqd = (file, fileList) => {

      if (beforeAvatarUpload(file)) {
        if(fileList.length > 1){
          fileList.splice(0, 1)
          ElMessage.success('文件覆盖成功！')
        }else {
          ElMessage.success('文件添加成功！')
        }

        fileName.value = file.name;
        //fileList.value.name = file.name;
          // fileList.value.url = "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100";


        // importDevice：请求接口 formData：传递参数
        // 请替换成你的实际接口调用

        // importDevice(formData).then((res) => {
        //   ElMessage({
        //     message: res.msg,
        //     type: "success",
        //   });
        //   // 调用表格方法，刷新页面
        //   getList(); // 请替换成你的实际刷新方法
        // });
      }else{
        console.log("错误文件")
        if(fileList.length === 1){
          console.log("第一个是错误文件")
          fileList.splice(0)
        }else if(fileList.length === 2){
          console.log("第二个是错误文件")
          fileList.splice(1)
        }

      }

      if(fileList.length !== 0){
        formData.append("file", fileList[0].raw);
        console.log("fileList[0].raw",fileList[0].raw)
        visibleIcon.value = false
      }else{
        visibleIcon.value = true
      }

      console.log("fileList",fileList)

    };

   const resetFile= () =>{


     fileLists.value = []
     visibleIcon.value = true
     formData.delete('file')
   }
    const dialogRef = ref(null);
    const uploadFile= () =>{

      ElMessageBox.confirm("确定要上传文件吗？", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消', // 添加取消按钮
        //showCancelButton: false,
        closeOnClickModal: false,
        type: 'warning',
// 设置挂载点
        appendTo: dialogRef.value.$el,
        //划重点
      }).then(()=>{
       // formData
       console.log("formData123", formData)



        // axios.get('http://localhost:8443/sys/success').then(resp => {
        //   if(resp &&  resp.data.code === 200){
        //     console.log("普通用户")
        //     ElMessageBox.confirm(resp.data.result, '提示', {
        //             confirmButtonText: '确定',
        //             //cancelButtonText: '取消', // 添加取消按钮
        //             showCancelButton: false,
        //             closeOnClickModal: false,
        //             type: 'warning',
        //             draggable: true,
        //           })
        //   }
        // })
        if (formData.get('file') !== null) {
          // formData 包含名为 'file' 的字段，可以执行相应的操作
          axios.post('http://localhost:8443/sys/excel', formData).then(resp =>{
            if(resp &&  resp.data.code === 200){
              ElMessage({
                message: '上传成功！',
                type: 'success',
              })
                resetFile()


            }
          })
        } else {
          // formData 不包含名为 'file' 的字段

          ElMessage({
            message: '请添加文件！',
            type: 'error',
          })

        }


      }).catch(() => {
        // 用户点击了取消按钮或关闭按钮后的处理逻辑
      });

    }

    return{

      dialogFormVisible,
      quesName,
      handleDialogClose,
      importQuesItem,
      uploadByJsqd,
      fileLists,
      resetFile,
      visibleIcon,
      fitMode,
      fileName,
      uploadFile,
      dialogRef,
      formData


    }
  }
}
</script>

<style scoped>
.button {
  display: flex;
  align-items: flex-start;
  margin-left: 269px;
  margin-bottom: 45px;

}

</style>