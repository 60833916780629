<template>
<!--  <el-button  type="text"-->
<!--             size="default">-->
<!--    配置-->
<!--  </el-button>-->
  <el-dialog
      title="新增测评题目"
      v-model="dialogFormVisible"
      :before-close="handleDialogClose"
      :lock-scroll="true"
      :close-on-click-modal="false">

    <!--selectedUser：被选中的用户(在点击编辑按钮时通过获得)     prop对应属性 -->
    <el-form :model="AddQuesItem" style="text-align: left" ref="dataForm" label-width="100px"
             :rules="rules" status-icon>

      <el-form-item prop="ques" label="所属测评" class="item">
        <span class="input">{{quesName}}</span>
<!--        <el-input v-model="AddQuesItem.ques" autocomplete="off" class="input"></el-input>-->
        <!--        <el-input v-model="selectedUser.uname" autocomplete="off" class="input"></el-input>-->
      </el-form-item>
      <el-form-item prop="coursesel" label="科目" >
      <el-select :teleported="false" v-model="AddQuesItem.coursesel" clearable placeholder="选择科目" class="input">

        <el-option
            v-for="item in selectOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        />
      </el-select>
      </el-form-item>
      <el-form-item prop="name" label="题目" class="item">
        <el-input v-model="AddQuesItem.name" autocomplete="off" class="input" clearable></el-input>
        <!--        <el-input v-model="selectedUser.uname" autocomplete="off" class="input"></el-input>-->
      </el-form-item>


      <el-form-item prop="option1" label="选项1" class="item">
        <el-input v-model="AddQuesItem.option1" autocomplete="off" class="input" clearable></el-input>
        <!--        <el-input v-model="selectedUser.uname" autocomplete="off" class="input"></el-input>-->
      </el-form-item>
      <el-form-item prop="score1" label="分值1" class="item">
        <el-input v-model="AddQuesItem.score1" autocomplete="off" class="input" clearable></el-input>
        <!--        <el-input v-model="selectedUser.uname" autocomplete="off" class="input"></el-input>-->
      </el-form-item>

      <el-form-item prop="option2" label="选项2" class="item">
        <el-input v-model="AddQuesItem.option2" autocomplete="off" class="input" clearable></el-input>
        <!--        <el-input v-model="selectedUser.uname" autocomplete="off" class="input"></el-input>-->
      </el-form-item>
      <el-form-item prop="score2" label="分值2" class="item">
        <el-input v-model="AddQuesItem.score2" autocomplete="off" class="input" clearable></el-input>
        <!--        <el-input v-model="selectedUser.uname" autocomplete="off" class="input"></el-input>-->
      </el-form-item>

      <el-form-item prop="option3" label="选项3" class="item">
        <el-input v-model="AddQuesItem.option3" autocomplete="off" class="input" clearable></el-input>
        <!--        <el-input v-model="selectedUser.uname" autocomplete="off" class="input"></el-input>-->
      </el-form-item>
      <el-form-item prop="score3" label="分值3" class="item">
        <el-input v-model="AddQuesItem.score3" autocomplete="off" class="input" clearable></el-input>
        <!--        <el-input v-model="selectedUser.uname" autocomplete="off" class="input"></el-input>-->
      </el-form-item>

      <el-form-item prop="option4" label="选项4" class="item">
        <el-input v-model="AddQuesItem.option4" autocomplete="off" class="input" clearable></el-input>
        <!--        <el-input v-model="selectedUser.uname" autocomplete="off" class="input"></el-input>-->
      </el-form-item>
      <el-form-item prop="score4" label="分值4" class="item">
        <el-input v-model="AddQuesItem.score4" autocomplete="off" class="input" clearable></el-input>
        <!--        <el-input v-model="selectedUser.uname" autocomplete="off" class="input"></el-input>-->
      </el-form-item>

      <el-form-item prop="option5" label="选项5" class="item">
        <el-input v-model="AddQuesItem.option5" autocomplete="off" class="input" clearable></el-input>
        <!--        <el-input v-model="selectedUser.uname" autocomplete="off" class="input"></el-input>-->
      </el-form-item>
      <el-form-item prop="score5" label="分值5" class="item">
        <el-input v-model="AddQuesItem.score5" autocomplete="off" class="input" clearable></el-input>
        <!--        <el-input v-model="selectedUser.uname" autocomplete="off" class="input"></el-input>-->
      </el-form-item>

      <el-form-item class="button">
        <el-button @click="submitForm(AddQuesItem)" color="#178071" size="large">
          <el-icon><Document /></el-icon>保存
        </el-button>

        <el-button @click="resetForm()" color="#d1c48f" size="large">
          <el-icon><Flag /></el-icon>重置
        </el-button>

      </el-form-item>
    </el-form>

    <el-divider>
      <el-icon><star-filled /></el-icon>
    </el-divider>

    <el-row justify="space-between">
<el-col :span="12">
  <el-tag   type="success" size="large"  effect="dark" align="left" style="margin-bottom: 20px">已经添加的选科题目数量为： {{added}}题</el-tag>
</el-col>

<el-col :span="12">
  <el-button @click="deleteBatchQuesItem()" color="#cb5765"  style="margin-bottom: 20px" size="default">
    <el-icon><Delete /></el-icon>批量删除
  </el-button>
</el-col>

    </el-row>


      <el-table :data="quesItemAdded" border stripe style="width: 100%;" align="left"
                @selection-change="handleSelectionChangeAdded">

      <el-table-column type="selection" width="40" />
      <el-table-column prop="name" label="题目"  width="190"/>
      <el-table-column prop="coursesel" label="科目" width="60"/>
      <el-table-column prop="option1AndScore1" label="选项1(分)" width="90"/>
      <el-table-column prop="option1AndScore2" label="选项2(分)" width="90"/>
      <el-table-column prop="option1AndScore3" label="选项3(分)" width="90"/>
      <el-table-column prop="option1AndScore4" label="选项4(分)" width="100"/>
      <el-table-column prop="option1AndScore5" label="选项5(分)" width="100"/>
      <el-table-column
          label="操作"
          width="160" class="my-table">
        <template #default="{ row }">
          <el-button
              @click="editQuesItem(row)"
              color="#79bbff"
              size="small">
            <el-icon><Edit /></el-icon>编辑
          </el-button>
          <el-button
              @click="deleteQuesItem(row)"
color="#c45656"
              size="small">
            <el-icon><Delete /></el-icon>删除
          </el-button>


        </template>
      </el-table-column>
    </el-table>




    <el-row justify="space-between">
      <el-col :span="12">
        <el-tag  size="large"  effect="dark" align="left" style="margin-bottom: 20px; margin-top: 50px">没有添加的选科题目数量为： {{notAdded}}题</el-tag>
      </el-col>

      <el-col :span="12">
        <el-button @click="addBatchQuesItem()" color="#67b18e"  style="margin-bottom: 20px; margin-top: 50px" size="default">
          <el-icon><Plus /></el-icon>批量添加测评
        </el-button>
      </el-col>

    </el-row>
    <el-table :data="quesItemNotAdded" border stripe style="width: 100%;" align="left"
              @selection-change="handleSelectionChangeNotAdded" >

      <el-table-column type="selection" width="40" />


      <el-table-column prop="name" label="题目"  width="190"/>

      <el-table-column prop="coursesel" label="科目" width="60"/>

      <el-table-column prop="option1AndScore1" label="选项1(分)" width="90"/>
      <el-table-column prop="option1AndScore2" label="选项2(分)" width="90"/>
      <el-table-column prop="option1AndScore3" label="选项3(分)" width="90"/>
      <el-table-column prop="option1AndScore4" label="选项4(分)" width="100"/>
      <el-table-column prop="option1AndScore5" label="选项5(分)" width="100"/>
      <el-table-column
          label="操作"
          width="160" class="my-table">
        <template #default="{ row }">
          <el-button
              @click="editQuesItem(row)"
              color="#79bbff"
              size="small">
            <el-icon><Edit /></el-icon>编辑
          </el-button>
          <el-button
              @click="AddQuesItemNotAdded(row)"
              type="success"
              size="small">
            <el-icon><Plus /></el-icon>添加
          </el-button>

        </template>
      </el-table-column>
    </el-table>



  </el-dialog>



  <el-dialog
      title="编辑测评题目"
      v-model="editVisible"
      :before-close="editHandleDialogClose"
      >

    <!--selectedUser：被选中的用户(在点击编辑按钮时通过获得)     prop对应属性 -->
    <el-form :model="selectedQuesItem" style="text-align: left" ref="dataForm" label-width="100px"
             :rules="rules" status-icon>


      <el-form-item prop="coursesel" label="科目" >
        <el-select :teleported="false" v-model="selectedQuesItem.coursesel" clearable placeholder="选择科目" class="input">

          <el-option
              v-for="item in selectOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item prop="name" label="题目" class="item">
        <el-input v-model="selectedQuesItem.name" autocomplete="off" class="input" clearable></el-input>
        <!--        <el-input v-model="selectedUser.uname" autocomplete="off" class="input"></el-input>-->
      </el-form-item>


      <el-form-item prop="option1" label="选项1" class="item">
        <el-input v-model="selectedQuesItem.option1" autocomplete="off" class="input" clearable></el-input>
        <!--        <el-input v-model="selectedUser.uname" autocomplete="off" class="input"></el-input>-->
      </el-form-item>
      <el-form-item prop="score1" label="分值1" class="item">
        <el-input v-model="selectedQuesItem.score1" autocomplete="off" class="input" clearable></el-input>
        <!--        <el-input v-model="selectedUser.uname" autocomplete="off" class="input"></el-input>-->
      </el-form-item>

      <el-form-item prop="option2" label="选项2" class="item">
        <el-input v-model="selectedQuesItem.option2" autocomplete="off" class="input" clearable></el-input>
        <!--        <el-input v-model="selectedUser.uname" autocomplete="off" class="input"></el-input>-->
      </el-form-item>
      <el-form-item prop="score2" label="分值2" class="item">
        <el-input v-model="selectedQuesItem.score2" autocomplete="off" class="input" clearable></el-input>
        <!--        <el-input v-model="selectedUser.uname" autocomplete="off" class="input"></el-input>-->
      </el-form-item>

      <el-form-item prop="option3" label="选项3" class="item">
        <el-input v-model="selectedQuesItem.option3" autocomplete="off" class="input" clearable></el-input>
        <!--        <el-input v-model="selectedUser.uname" autocomplete="off" class="input"></el-input>-->
      </el-form-item>
      <el-form-item prop="score3" label="分值3" class="item">
        <el-input v-model="selectedQuesItem.score3" autocomplete="off" class="input" clearable></el-input>
        <!--        <el-input v-model="selectedUser.uname" autocomplete="off" class="input"></el-input>-->
      </el-form-item>

      <el-form-item prop="option4" label="选项4" class="item">
        <el-input v-model="selectedQuesItem.option4" autocomplete="off" class="input" clearable></el-input>
        <!--        <el-input v-model="selectedUser.uname" autocomplete="off" class="input"></el-input>-->
      </el-form-item>
      <el-form-item prop="score4" label="分值4" class="item">
        <el-input v-model="selectedQuesItem.score4" autocomplete="off" class="input" clearable></el-input>
        <!--        <el-input v-model="selectedUser.uname" autocomplete="off" class="input"></el-input>-->
      </el-form-item>

      <el-form-item prop="option5" label="选项5" class="item">
        <el-input v-model="selectedQuesItem.option5" autocomplete="off" class="input" clearable></el-input>
        <!--        <el-input v-model="selectedUser.uname" autocomplete="off" class="input"></el-input>-->
      </el-form-item>
      <el-form-item prop="score5" label="分值5" class="item">
        <el-input v-model="selectedQuesItem.score5" autocomplete="off" class="input" clearable></el-input>
        <!--        <el-input v-model="selectedUser.uname" autocomplete="off" class="input"></el-input>-->
      </el-form-item>

      <el-form-item class="button">
        <el-button @click="submitEditForm(selectedQuesItem)" color="#178071" size="large">
          <el-icon><Document /></el-icon>保存
        </el-button>

        <el-button @click="resetForm()" color="#d1c48f" size="large">
          <el-icon><Flag /></el-icon>重置
        </el-button>

      </el-form-item>
    </el-form>





  </el-dialog>


</template>

<script>
import {onMounted, reactive, ref, watch} from "vue";
import { defineEmits } from 'vue';
import axios from "axios";
import  {ElMessageBox} from "element-plus";
import { ElMessage } from 'element-plus'
export default {
  name: "ThemeConfiguration",
  props: {
    isVisible: Boolean, // 声明接收名为 "message" 的 prop
    message: String,
  },
  emits: ['update:isVisible', "changeVisible"], // 声明事件用于更新初始可见性状态
  setup(props, { emit }){
console.log(props.message)
    let dialogFormVisible = ref(props.isVisible)
let quesName = ref(props.message)
    let quesItemName = ref('')
    let added = ref(0)
    let notAdded = ref(0)
    const selectOptions = [
      {
        value: '物理',
        label: '物理',
      },
      {
        value: '化学',
        label: '化学',
      },
      {
        value: '生物',
        label: '生物',
      },
      {
        value: '政治',
        label: '政治',
      },
      {
        value: '地理',
        label: '地理',
      },
      {
        value: '历史',
        label: '历史',
      }


      ]
    let quesItem = ref([])
let quesItemAdded = ref([])
    let quesItemNotAdded = ref([])

    watch(() => props.isVisible, (newValue) => {
      console.log("props.isVisible",props.isVisible);
      dialogFormVisible.value = newValue;


    });


    watch(() => props.message, (newValue) => {
      console.log(props.message);
      quesName.value = newValue;
      console.log("测评名",quesName.value)
      listQuesItem()
    });
     //const emitFunctions = defineEmits(['changeVisible'])


    let AddQuesItem = ref({

      name:"",

    });
let quesItemAddedSelection = ref([])
    let quesItemNotAddedSelection = ref([])
let editVisible = ref(false)
    let selectedQuesItem = ref({});
    let selectedQuesItemBackup = ref({});

    const editQuesItem =(quesItem)=>{
      //console.log("quesItem", quesItem.name)
      quesItemName.value = quesItem.name
      editVisible.value = true

      // quesItemAdded.value = quesItem.value.added.map(item => ({
      //   ...item,
      //   option1AndScore1: `${item.option1} (${item.score1})`,
      //   option1AndScore2: `${item.option2} (${item.score2})`,
      //   option1AndScore3: `${item.option3} (${item.score3})`,
      //   option1AndScore4: `${item.option4} (${item.score4})`,
      //   option1AndScore5: `${item.option5} (${item.score5})`
      // }));

      selectedQuesItem.value = {
        ...quesItem
      }
      // selectedSpec.value = { ...Spec } // 使用对象扩展运算符将 user 的属性值复制到新的对象中
      selectedQuesItemBackup.value ={
        ...quesItem
      }
    }

    const handleSelectionChangeAdded = (val) => {
      quesItemAddedSelection.value = val.map(row => row.name);
      console.log(quesItemAddedSelection.value)
    }
    const handleSelectionChangeNotAdded = (val) => {
      quesItemNotAddedSelection.value = val.map(row => row.name);
      console.log(quesItemNotAddedSelection.value)
    }
    const resetForm = () => {
      // AddQuesItem ={
      //
      //
      // };
      // 重置表单并清除验证错误
      dataForm.value.resetFields();

    }
    const dataForm = ref(null);
    const handleDialogClose=(done)=> {

      done();

      //dialogFormVisible = false;
      AddQuesItem = ref({

        name: '',

      });

      dataForm.value.resetFields();

      // props.isVisible = false
      // console.log(props.isVisible)
      // console.log(dialogFormVisible.value)

      emit("changeVisible", false);
      //emitFunctions("changeVisible", false)
    }


    const validateCoursesel = (rule, value, callback) => {
      //手机号码校验(第一位为1，后十位为0-9任意数字)
      if (value.length === 0) {
        callback(new Error('请选择至少一个科目')); // 至少选择一个选项
      } else {
        callback(); // 验证通过
      }

    }

    const rules = reactive({
      name:[
        {required:true, message: '请输入题目', trigger: 'blur'},
        {min:2, message: '长度应该在2个字符之上',trigger: ['blur', 'change']},
      ],
      coursesel: [
        {required:true, message: '请选择科目', trigger: 'blur'},
        {
          validator: validateCoursesel,
          trigger: 'change', // 触发验证的时机
        },
      ],
      option1:[
        {required:true, message: '请输入选项1', trigger: 'blur'},
        {min:1, message: '长度应该在1个字符之上',trigger: ['blur', 'change']},
      ],
        score1:[
      {required:true, message: '请输入分值1', trigger: 'blur'},
      {min:1, message: '长度应该在1个字符之上',trigger: ['blur', 'change']},
    ],
      option2:[
        {required:true, message: '请输入选项2', trigger: 'blur'},
        {min:1, message: '长度应该在1个字符之上',trigger: ['blur', 'change']},
      ],
      score2:[
        {required:true, message: '请输入分值2', trigger: 'blur'},
        {min:1, message: '长度应该在1个字符之上',trigger: ['blur', 'change']},
      ],
      option3:[
        {required:true, message: '请输入选项3', trigger: 'blur'},
        {min:1, message: '长度应该在1个字符之上',trigger: ['blur', 'change']},
      ],
      score3:[
        {required:true, message: '请输入分值3', trigger: 'blur'},
        {min:1, message: '长度应该在1个字符之上',trigger: ['blur', 'change']},
      ],
      option4:[
        {required:true, message: '请输入选项4', trigger: 'blur'},
        {min:1, message: '长度应该在1个字符之上',trigger: ['blur', 'change']},
      ],
      score4:[
        {required:true, message: '请输入分值4', trigger: 'blur'},
        {min:1, message: '长度应该在1个字符之上',trigger: ['blur', 'change']},
      ],
      option5:[
        {required:true, message: '请输入选项5', trigger: 'blur'},
        {min:1, message: '长度应该在1个字符之上',trigger: ['blur', 'change']},
      ],
      score5:[
        {required:true, message: '请输入分值5', trigger: 'blur'},
        {min:1, message: '长度应该在1个字符之上',trigger: ['blur', 'change']},
      ]
    })


    const listQuesItem=()=>{
console.log("开始", quesName.value)
      axios.get('/sys/quesItemInformation/'+quesName.value).then(resp => {
        console.log("开始1")
        if(resp &&  resp.data.code === 200){
console.log(resp.data)
          quesItem.value = resp.data.result
          console.log("quesItem.value",quesItem.value)
          quesItemAdded.value = quesItem.value.added.map(item => ({
            ...item,
            option1AndScore1: `${item.option1} (${item.score1})`,
            option1AndScore2: `${item.option2} (${item.score2})`,
            option1AndScore3: `${item.option3} (${item.score3})`,
            option1AndScore4: `${item.option4} (${item.score4})`,
            option1AndScore5: `${item.option5} (${item.score5})`
          }));
console.log("quesItemAdded", quesItemAdded.value);
          quesItemNotAdded.value = quesItem.value.notAdded.map(item => ({
            ...item,
            option1AndScore1: `${item.option1} (${item.score1})`,
            option1AndScore2: `${item.option2} (${item.score2})`,
            option1AndScore3: `${item.option3} (${item.score3})`,
            option1AndScore4: `${item.option4} (${item.score4})`,
            option1AndScore5: `${item.option5} (${item.score5})`
          }));
          console.log("quesItemNotAdded", quesItemNotAdded.value);
         added.value =  resp.data.result.added.length
          notAdded.value = resp.data.result.notAdded.length

        }
      })
    }

    const submitForm=(addQuesItem)=>{

      //const router = useRouter();
      ElMessageBox.confirm('确定创建新的题目？', '提示', {
        confirmButtonText: '确定',
        //cancelButtonText: '取消', // 添加取消按钮
        showCancelButton: false,
        closeOnClickModal: false,
        type: 'warning',
        draggable: true,
      }).then(()=>{

        console.log("addQuesItem", addQuesItem)
        console.log("addQuesItem", quesName.value)
        // let temp = addUser.userBirth.split('T')[0]
        //console.log("temp"+temp)

          axios.post(`/sys/addQuesItem?quesName=${quesName.value}`,addQuesItem
          ).then(resp => {
            // console.log(resp.data)
            if(resp.data.code === 200){

              ElMessageBox.confirm('创建新题目成功', '提示', {
                confirmButtonText: '确定',
                //cancelButtonText: '取消', // 添加取消按钮
                showCancelButton: false,
                closeOnClickModal: false,
                type: 'warning',
                draggable: true,
              }).then(()=>{

                resetForm()
                listQuesItem()
              }).catch(() => {
                // 用户点击了取消按钮或关闭按钮后的处理逻辑
              });



            }else{
              console.log(resp.data)
              ElMessageBox.confirm(resp.data.message, '提示', {
                confirmButtonText: '确定',
                //cancelButtonText: '取消', // 添加取消按钮
                showCancelButton: false,
                closeOnClickModal: false,
                type: 'warning',
                draggable: true,
              }).then(()=>{

              }).catch(() => {
                // 用户点击了取消按钮或关闭按钮后的处理逻辑
              });


            }
          })
              .catch(failResponse => {})



      }).catch(() => {
        // 用户点击了取消按钮或关闭按钮后的处理逻辑
      });



    }

    const deleteQuesItem =(quesItem)=>{

      ElMessageBox.confirm("确定要删除该题目吗？", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消', // 添加取消按钮
        //showCancelButton: false,
        closeOnClickModal: false,
        type: 'warning',
        draggable: true,
      }).then(()=>{
        console.log("题目",quesItem)
        axios.put('/sys/deleteQuesItemRead',
            quesItem
        ).then(resp =>{
          if(resp &&  resp.data.code === 200){

            ElMessage({
              message: '题目删除成功！',
              type: 'success',
            })



listQuesItem()
          }
        })
      }).catch(() => {
        // 用户点击了取消按钮或关闭按钮后的处理逻辑
      });


    }
    const AddQuesItemNotAdded =(quesItem)=>{

      ElMessageBox.confirm("确定添加该题目吗？", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消', // 添加取消按钮
        //showCancelButton: false,
        closeOnClickModal: false,
        type: 'warning',
        draggable: true,
      }).then(()=>{
        console.log("题目",quesItem)
        axios.put(`/sys/addQuesItemRead?quesName=${quesName.value}`,
            quesItem
        ).then(resp =>{
          if(resp &&  resp.data.code === 200){

            ElMessage({
              message: '题目添加成功！',
              type: 'success',
            })



            listQuesItem()
          }
        })
      }).catch(() => {
        // 用户点击了取消按钮或关闭按钮后的处理逻辑
      });


    }

    const deleteBatchQuesItem = () =>{
      ElMessageBox.confirm("确定要删除这些问题吗？", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消', // 添加取消按钮
        //showCancelButton: false,
        closeOnClickModal: false,
        type: 'warning',
        draggable: true,
      }).then(()=>{
console.log("quesItemAddedSelection.value", quesItemAddedSelection.value)
        axios.put('/sys/batchDeleteQuesItemAddedRead',
            quesItemAddedSelection.value
        ).then(resp =>{
          if(resp &&  resp.data.code === 200){
            ElMessage({
              message: '题目删除成功！',
              type: 'success',
            })
            listQuesItem()
          }
        })
      }).catch(() => {
        // 用户点击了取消按钮或关闭按钮后的处理逻辑
      });

    }


    const addBatchQuesItem = () =>{
      ElMessageBox.confirm("确定要添加这些问题吗？", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消', // 添加取消按钮
        //showCancelButton: false,
        closeOnClickModal: false,
        type: 'warning',
        draggable: true,
      }).then(()=>{
        console.log("quesItemNotAddedSelection.value", quesItemNotAddedSelection.value)
        axios.put(`/sys/batchAddQuesItemNotAddedRead?quesName=${quesName.value}`,
            quesItemNotAddedSelection.value
        ).then(resp =>{
          if(resp &&  resp.data.code === 200){
            ElMessage({
              message: '题目添加成功！',
              type: 'success',
            })
            listQuesItem()
          }
        })
      }).catch(() => {
        // 用户点击了取消按钮或关闭按钮后的处理逻辑
      });

    }

    const editHandleDialogClose=(done)=> {

      done();

      dataForm.value.resetFields();
    }
    const submitEditForm=(addQuesItem)=>{

      //const router = useRouter();
      ElMessageBox.confirm('确定修改该题目？', '提示', {
        confirmButtonText: '确定',
        //cancelButtonText: '取消', // 添加取消按钮
        showCancelButton: false,
        closeOnClickModal: false,
        type: 'warning',
        draggable: true,
      }).then(()=>{

        axios.put(`/sys/editQuesItem?quesItemName=${quesItemName.value}`,addQuesItem
        ).then(resp => {
          // console.log(resp.data)
          if(resp.data.code === 200){

            ElMessageBox.confirm('修改题目成功', '提示', {
              confirmButtonText: '确定',
              //cancelButtonText: '取消', // 添加取消按钮
              showCancelButton: false,
              closeOnClickModal: false,
              type: 'warning',
              draggable: true,
            }).then(()=>{

              editVisible.value = false
              listQuesItem()
            }).catch(() => {
              // 用户点击了取消按钮或关闭按钮后的处理逻辑
            });



          }else{
            console.log(resp.data)
            ElMessageBox.confirm(resp.data.message, '提示', {
              confirmButtonText: '确定',
              //cancelButtonText: '取消', // 添加取消按钮
              showCancelButton: false,
              closeOnClickModal: false,
              type: 'warning',
              draggable: true,
            }).then(()=>{

            }).catch(() => {
              // 用户点击了取消按钮或关闭按钮后的处理逻辑
            });


          }
        })
            .catch(failResponse => {})



      }).catch(() => {
        // 用户点击了取消按钮或关闭按钮后的处理逻辑
      });



    }

    return{
      dialogFormVisible,
      AddQuesItem,
      resetForm,
      handleDialogClose,
      rules,
      dataForm,
      selectOptions,
      listQuesItem,
      quesItemAdded,
      quesItemNotAdded,
      submitForm,
      quesName,
      added,
      notAdded,
      quesItem,
      deleteQuesItem,
      deleteBatchQuesItem,
      handleSelectionChangeAdded,
      handleSelectionChangeNotAdded,
      AddQuesItemNotAdded,
      addBatchQuesItem,
      editQuesItem,
      editVisible,
      selectedQuesItem,
      selectedQuesItemBackup,
      editHandleDialogClose,
      submitEditForm
    }
  }
}
</script>

<style scoped>
.input {
  margin-left: 30px; /* input 左侧外边距 */
}
.button {
  display: flex;
  align-items: flex-start;
  margin-left: 130px;
  margin-bottom: 45px;

}
.left-aligned {
  text-align: left;
  display: flex;
  justify-content: flex-start;
}
</style>