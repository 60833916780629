<template>
  <el-card>
    <!--点击编辑按钮后呈现的界面  最开始默认是不可见的    -->
    <el-dialog
        title="修改专业信息"
        v-model="dialogFormVisible"
        :before-close="handleDialogClose">

      <!--selectedUser：被选中的用户(在点击编辑按钮时通过获得)     prop对应属性 -->
      <el-form :v-model="selectedSpec" style="text-align: left" ref="dataForm" label-width="100px">

        <el-form-item prop="name" label="专业名称" class="item">
          <el-input v-model="selectedSpec.name" autocomplete="off" class="input"></el-input>
          <!--        <el-input v-model="selectedUser.uname" autocomplete="off" class="input"></el-input>-->
        </el-form-item>



        <el-form-item prop="kemu" label="专业要求" class="item">
<!--          <el-input v-model="selectedSpec.kemu" autocomplete="off" class="input"></el-input>-->
          <div>
            <el-checkbox-group v-model="selectedSpec.kemu" class="input">
              <el-checkbox label="物理"  size="large"/>
              <el-checkbox label="化学"  size="large"/>
              <el-checkbox label="生物"  size="large"/>
              <el-checkbox label="政治"  size="large"/>
              <el-checkbox label="地理"  size="large"/>
              <el-checkbox label="历史"  size="large"/>

            </el-checkbox-group>
          </div>
        </el-form-item>



        <el-form-item class="button">
          <el-button @click="submitForm(selectedSpec)" color="#178071" size="large">
            <el-icon><Document /></el-icon>保存
          </el-button>

          <el-button @click="resetForm()" color="#d1c48f" size="large">
            <el-icon><Flag /></el-icon>重置
          </el-button>

        </el-form-item>


      </el-form>

    </el-dialog>




    <div style="margin-bottom: 30px" class="align-left-container">
      <el-input v-model="inputName" placeholder="输入专业名称" clearable style="width: 200px;margin-right: 5px"/>
      <el-input v-model="inputKemu" placeholder="输入专业要求" clearable style="width: 200px;margin-right: 5px"/>

      <el-button @click="searchForm()"  size="default" type="primary">
        <el-icon><Search /></el-icon>搜索
      </el-button>
    </div>
    <el-divider />




    <div class="button1">
      <AddSpeciality></AddSpeciality>
      <!--  <el-button @click="addUser()" color="#67b18e" size="large">-->
      <!--    <el-icon><Plus /></el-icon>新增普通用户-->
      <!--  </el-button>-->

      <el-button @click="deleteSpecs()" color="#cb5765" size="large">
        <el-icon><Delete /></el-icon>批量删除
      </el-button>
    </div>

    <div >
<!--      @selection-change="handleSelectionChange"-->
      <el-table :data="speciality" border stripe @selection-change="handleSelectionChange"
                 >
        <el-table-column type="selection" min-width="3%" max-width="3%" class="my-table"/>
        <el-table-column prop="Id"  label="Id" width="60" class="my-table"/>

        <el-table-column prop="name" label="专业名称" min-width="43.5%" max-width="43.5%" class="my-table"/>

        <el-table-column prop="kemu" label="专业要求" min-width="43.5%" max-width="43.5%" class="my-table"/>

        <el-table-column
            label="操作"

            min-width="10%" max-width="10%" class="my-table">
          <template #default="{ row }">

            <el-button
                @click="editSpec(row)"
                type="text"
                size="default">
              编辑
            </el-button>

            <el-button
                @click="deleteSpec(row)"
                type="text"
                size="default">
              移除
            </el-button>

          </template>
        </el-table-column>

      </el-table>
    </div>
<!--    @current-change="handleCurrentChange"-->
    <el-pagination style="float: right;margin-top: 50px;margin-bottom: 80px"
                   background
                   layout="total, prev, pager, next, jumper"
                   @current-change="handleCurrentChange"
                   :page-size="pageSize"

                   :total="parseInt(total)">
    </el-pagination>
  </el-card>
</template>

<script>
import {onMounted, reactive, ref, toRefs} from "vue";
import axios from "axios";
import {ElMessageBox} from "element-plus";
import AddUser from "@/components/back/admin/user/AddUser";
import AddSpeciality from "@/components/back/admin/sys/AddSpeciality";
export default {
  name: "Speciality",
  components: {AddSpeciality, AddUser},
  setup(){

    let speciality = ref([]);


    const fit = ref('scale-down')
    let total = ref(0)
    let pageSize = ref(4)
    let dialogFormVisible = ref(false)
    let search = ref(0)
    let inputName = ref('');
    let inputKemu = ref('');
    let specSelection = ref([])
    let selectedSpec = ref({});
    const selectedSpecBackup = ref({});
    let kemuArray = ref([])




    const submitForm =(spec)=>{
      ElMessageBox.confirm("确定要修改专业信息吗？", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消', // 添加取消按钮
        //showCancelButton: false,
        closeOnClickModal: false,
        type: 'warning',
        draggable: true,
      }).then(()=>{
        console.log("科目",spec)
        const medium = spec
        spec.value = {
          ...medium,
          kemu: medium.kemu.join(",")
        }
        console.log("修改后科目",spec)
        axios.put(`/sys/updateSpecInformation?name=${selectedSpecBackup.value.name}`,
            spec.value
        ).then(resp =>{
          if(resp &&  resp.data.code === 200){
            ElMessageBox.confirm(resp.data.result, '提示', {
              confirmButtonText: '确定',
              //cancelButtonText: '取消', // 添加取消按钮
              showCancelButton: false,
              closeOnClickModal: false,
              type: 'warning',
              draggable: true,
            }).then(()=>{

              dialogFormVisible.value = false
              window.location.reload()
            }).catch(() => {
              // 用户点击了取消按钮或关闭按钮后的处理逻辑
            });
          }
        })
      }).catch(() => {
        // 用户点击了取消按钮或关闭按钮后的处理逻辑
      });

    }

    const resetForm =()=>{

      //  Object.assign(selectedSpec, selectedSpecBackup);
    // selectedSpec.value = selectedSpecBackup.value
      Object.assign(selectedSpec.value, selectedSpecBackup.value);
    }
    const editSpec =(Spec)=>{
      dialogFormVisible.value = true

      // 处理Spec.kemu，假设Spec.kemu是一个逗号分隔的字符串
      kemuArray = Spec.kemu.split(","); // 将逗号分隔的字符串分割成数组
      // 创建一个新的对象，包含处理后的kemu
      selectedSpec.value = {
        ...Spec,
        kemu: kemuArray
      };
     // selectedSpec.value = { ...Spec } // 使用对象扩展运算符将 user 的属性值复制到新的对象中
      selectedSpecBackup.value ={
        ...Spec,
        kemu: kemuArray
      };


    }
    const handleDialogClose=(done)=> {

      done(); // 关闭弹出框

    }
    const handleSelectionChange = (val) => {
      specSelection.value = val.map(row => row.name);
      console.log(specSelection.value)
    }
    const deleteSpecs = () =>{
      ElMessageBox.confirm("确定要删除这些专业吗？", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消', // 添加取消按钮
        //showCancelButton: false,
        closeOnClickModal: false,
        type: 'warning',
        draggable: true,
      }).then(()=>{

        axios.put('/sys/batchDeleteSpecs',
            specSelection.value
        ).then(resp =>{
          if(resp &&  resp.data.code === 200){
            ElMessageBox.confirm(resp.data.result, '提示', {
              confirmButtonText: '确定',
              //cancelButtonText: '取消', // 添加取消按钮
              showCancelButton: false,
              closeOnClickModal: false,
              type: 'warning',
              draggable: true,
            }).then(()=>{

              window.location.reload()
            }).catch(() => {
              // 用户点击了取消按钮或关闭按钮后的处理逻辑
            });
          }
        })
      }).catch(() => {
        // 用户点击了取消按钮或关闭按钮后的处理逻辑
      });

    }
    const deleteSpec =(spec)=>{

      ElMessageBox.confirm("确定要删除该专业吗？", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消', // 添加取消按钮
        //showCancelButton: false,
        closeOnClickModal: false,
        type: 'warning',
        draggable: true,
      }).then(()=>{
        console.log("修改专业",spec)
        axios.put('/sys/deleteSpec',
            {name: spec.name}
        ).then(resp =>{
          if(resp &&  resp.data.code === 200){
            ElMessageBox.confirm(resp.data.result, '提示', {
              confirmButtonText: '确定',
              //cancelButtonText: '取消', // 添加取消按钮
              showCancelButton: false,
              closeOnClickModal: false,
              type: 'warning',
              draggable: true,
            }).then(()=>{

              window.location.reload()
            }).catch(() => {
              // 用户点击了取消按钮或关闭按钮后的处理逻辑
            });
          }
        })
      }).catch(() => {
        // 用户点击了取消按钮或关闭按钮后的处理逻辑
      });


    }


    const searchForm=()=>{
      search.value = 1
      console.log(inputName.value, inputKemu.value)
      axios.get('/sys/searchSpec/' + pageSize.value + '/1', {
        params: {
          name: inputName.value,
          kemu: inputKemu.value,

        }}).then(resp => {
        if(resp &&  resp.data.code === 200){


          speciality.value = resp.data.result.content
          total.value = resp.data.result.totalElements

          speciality.value.forEach((item, index) => {
            item.Id = index + 1;
          });
          console.log("普通用户")
          console.log(resp.data.result)
        }
      })
    }

    const handleCurrentChange=(page)=>{
      if(search.value === 0){
        axios.get('/sys/specInformation/' + pageSize.value + '/' + page).then(resp =>{
          if(resp &&  resp.data.code === 200){
            speciality.value = resp.data.result.content
            total.value = resp.data.result.totalElements
            //console.log(resp.data.result)

            speciality.value.forEach((item, index) => {
              item.Id = (page - 1) * pageSize.value + index + 1;
            });
          }
        })
      }
      else if(search.value === 1){
        console.log(inputName.value, inputKemu.value)
        axios.get('/sys/searchSpec/' + pageSize.value + '/' + page, {
          params: {
            name: inputName.value,
            kemu: inputKemu.value,
          }}).then(resp => {
          if(resp &&  resp.data.code === 200){


            speciality.value = resp.data.result.content
            total.value = resp.data.result.totalElements

            speciality.value.forEach((item, index) => {
              item.Id = (page - 1) * pageSize.value + index + 1;
            });
            console.log("普通用户")
            console.log(resp.data.result)
          }
        })
      }

    }

    const listSpeciality=()=>{

      axios.get('/sys/specInformation/' + pageSize.value + '/1').then(resp => {
        if(resp &&  resp.data.code === 200){
          console.log("返回数据")
          console.log(resp.data)
          speciality.value = resp.data.result.content
          total.value = resp.data.result.totalElements

          speciality.value.forEach((item, index) => {
            item.Id = index + 1;
          });


        }
      })
    }

    onMounted(() => {
      listSpeciality();
    });

    return{
      speciality,
      listSpeciality,
      fit,
      search,
handleCurrentChange,
      pageSize,
      total,
      dialogFormVisible,
inputName,
      inputKemu,
      specSelection,
      searchForm,
      deleteSpec,
      deleteSpecs,
      handleSelectionChange,
      handleDialogClose,
      selectedSpec,
      editSpec,
      resetForm,
      kemuArray,
      submitForm


    }
  }
}
</script>

<style scoped>
.item{
  width: 600px;
}
.input {
  margin-left: 30px; /* input 左侧外边距 */
}
.input1 {
  margin-left: 40px; /* input 左侧外边距 */
}
.button {
  display: flex;
  align-items: flex-start;
  margin-left: 30px;
  margin-bottom: 45px;

}
.button1 {
  display: flex;
  align-items: flex-start;
  /*margin-left: 30px;*/
  margin-bottom: 20px;

}
.my-table {
  height: 200px; /* 你想要的行高值 */

}
.align-left-container {
  display: flex;
  align-items: flex-start;
}
</style>