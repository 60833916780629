<!--底下的空白区域-->
<template>
  <div class="empty-space"></div>
</template>

<script>
export default {
  name: "Space"
}
</script>

<style scoped>
.empty-space {
  margin-top: 200px; /* 设置空白区域的高度 */
}
</style>