<!--文章详情页面，正确渲染 html，-->

<template>
  <div class="qxk-area">
    <el-card style="text-align: left">
      <table>

        <tr>
          <td>
            <el-row class="mb-4">

              <el-button type="danger" class="fixed-button">
                <div class="subunit">
                  <base-counter :time="countDownTime" v-slot="timeObj" >
                    <div class="count-down">
                      <div class="icon"></div>
                      <!--            {{timeObj.d}}天{{timeObj.hh}}小时-->
                      <!--                需要判断的是timeObj.mm，因为countDownTime是一个常量-->
                      {{ timeObj.h > 0 ? timeObj.hh + 'h' + timeObj.mm + 'min' + timeObj.ss + 's' : (timeObj.mm < 1 ? timeObj.ss + 's' : timeObj.mm + 'min' + timeObj.ss + 's') }}

                      <!--                {{timeObj.mm}}min{{timeObj.ss}}s-->
                    </div>
                  </base-counter>
                </div>
              </el-button>
            </el-row>
          </td>
          <span class="spacer"></span>
          <td style="padding-left: 60px;">
            <div v-for="(qxkItem, index) in qxkItems" :key="qxkItem.id">
              <div style="display: flex; align-items: center;">
                <div style=" width: 120%;height: 100px;">
                  <strong>{{index + 1}}.</strong>
                  <span class="spacer"></span>
                  <strong>{{qxkItem.name}}</strong>
                  <div class="mb-2 flex items-center text-sm">
                    <el-radio-group v-model="radio[index].value" class="ml-4">
                      <el-radio :label="qxkItem.score1"  size="large">{{qxkItem.option1}}</el-radio>
                      <el-radio :label="qxkItem.score2" size="large">{{qxkItem.option2}}</el-radio>
                      <el-radio :label="qxkItem.score3" size="large">{{qxkItem.option3}}</el-radio>
                      <el-radio :label="qxkItem.score4" size="large">{{qxkItem.option4}}</el-radio>
                      <el-radio :label="qxkItem.score5" size="large">{{qxkItem.option5}}</el-radio>
                    </el-radio-group>
                  </div>
                </div>
              </div>

            </div>
          </td>
        </tr>
      </table>
      <el-divider ></el-divider>
      <el-button type="primary" round style="width: 10%;" v-on:click="submit">提交测评</el-button>

    </el-card>
  </div>
</template>

<script>
import BaseCounter from '@/components/front/common/CountDown.vue'
export default {
  components: { BaseCounter },
  name: 'GetQxkItem',
  data () {
    return {
      flag : 0,
      countDownTime: parseInt(this.$route.query.shi) * 60,
      qxkItems: [],
      radio: [],
      score: {qxkId: this.$route.query.id},
    }
  },
  created() {
    this.timer = setInterval(this.updateCountdown, 1000);
  },
  beforeDestroy() {
    // 在组件销毁前清除定时器
    clearInterval(this.timer);
  },
  mounted () {
    this.flag = 0,
    this.loadQuesItem()

    // const currentDate = new Date();
    // console.log(currentDate.toLocaleString()) // 根据需要格式化时间

    // setTimeout(() => {
    //   this.back(); // 自动执行 back 方法
    // }, 1000 * this.countDownTime);
    // setInterval(
    //   this.countDownTime = this.countDownTime - 1
    //
    // , 1000);

    //立即加载
  },
  methods: {
    // back(){
    //
    //
    //
    //   this.$confirm('您已超时！', '提示', {
    //     confirmButtonText: '确定',
    //     showCancelButton: false,
    //     type: 'warning'
    //   }).then(() => {
    //     this.$router.go(-1);
    //   })
    //
    //
    // },
    updateCountdown() {
      if (this.countDownTime > 0) {
        this.countDownTime--;
        console.log(this.$route.path);
        if(this.$route.path !== "/listQxk/getQxkItem"){
          clearInterval(this.timer);
        }
      } else {
        this.$alert('您已超时, 立即交卷！', '提示', {
          confirmButtonText: '确定',
          showCancelButton: false,
          showClose: false,
          type: 'warning',
          callback: () => {

            //this.$router.go(-1);
            // this.$router.push('/listQues/getQuesItem');
            //location.reload();
            this.flag = 1;
            this.submit();
          }
        });
        clearInterval(this.timer); // 倒计时结束，清除定时器
      }
    }
    ,
    loadQuesItem () {
      var _this = this
      //从文章列表页跳转到这个详情页
      // 立即加载这个方法
      // 详情页面获取到该参数this.$route.query.id
      // 并向数据库发送请求，获取到文章，渲染HTML
      this.$axios.get('/com/getQxkItem/' + this.$route.query.id).then(resp => {
        if (resp && resp.data.code === 200) {
          console.log(resp.data.result)
          _this.qxkItems = resp.data.result//result：article
          this.initializeRadioArray()
        }
      })
    },
    initializeRadioArray() {
      this.radio = this.qxkItems.map(() => ({ value: null }));
    },
    submit(){
      //console.log(this.radio);
      if ((this.radio.some(option => option.value === null)) && this.flag === 0) {
        alert('请回答完所有问题！');

      }else if(this.flag === 1 && (this.radio.every(option => option.value === null))){
        this.$message({
          message: `高考6选3试卷未作答，返回试题列表！`,
          type: 'success'
        });
        this.$router.push('/listQxk');

      }else {
        console.log("else 有问题");
        for(let i = 0; i < this.radio.length; i++){
          //this.score[`score_${this.qxkItems[i].id}`] = this.radio[i].value;
          if(this.radio[i].value !== null){
            this.score[`score_${this.qxkItems[i].id}`] = this.radio[i].value;
          }else{
            this.score[`score_${this.qxkItems[i].id}`] = 0;
          }
        }
        //console.log(this.score)

// // 打印score的值
//         for (const key in this.score) {
//           const value = this.score[key];
//           console.log(`Value for ${key}: ${value}`);
//         }


        this.$axios.put('/com/addQxkUser/',this.score).then(resp => {
          console.log(this.score)
          if (resp && resp.data.code === 200) {

            this.$message({
              message: `测评试卷提交成功`,
              type: 'success'
            });
            this.$router.push({ path: '/listQxk/getQxkItem/qxkResult',
              query: { result: JSON.stringify(resp.data.result) } });
            // this.$router.push('/listQues/getQues/quesResult');
            // $bus.emit('data1', resp.data.result)
            // $bus.emit('data2', resp.data.result)



            this.resetvalue()
          }else{
            this.$message({
              message: `提交失败！`,
              type: 'error'
            });
          }
        })
      }
    },
    resetvalue(){
      this.radio.forEach(option =>{
        option.value = null;
      })
    }
  }
}
</script>

<style scoped>
.qxk-area {
  width: 84%; /* 设置文本区域宽度 */
  /*margin: 0 auto; !* 居中文本区域 *!*/
  /*padding: 10px; !* 可选：添加内边距来制造空白 *!*/
  /*height: 750px;*/
  margin-left: auto;
  margin-right: auto;
}
.fixed-button {
  position: fixed;
  top: 400px; /* 距离底部的距离，根据需要调整 */
  left:130px; /* 距离右侧的距离，根据需要调整 */
  z-index: 999; /* 控制按钮在堆叠顺序中的显示 */
}
.spacer {
  margin-left: 20px; /* 调整间距大小 */
}

</style>
