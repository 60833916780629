<template>
<el-card>

  <el-dialog
      title="修改问题信息"
      v-model="dialogFormVisible"
      >

    <!--selectedUser：被选中的用户(在点击编辑按钮时通过获得)     prop对应属性 -->
    <el-form :v-model="selectedNote" style="text-align: left" ref="dataForm" label-width="100px">

      <el-form-item prop="name" label="问题" class="item">
        <el-input v-model="selectedNote.name" autocomplete="off" class="input"></el-input>
        <!--        <el-input v-model="selectedUser.uname" autocomplete="off" class="input"></el-input>-->
      </el-form-item>

      <el-form-item prop="name" label="回答" class="item">
        <el-input v-model="selectedNote.note" autocomplete="off" class="input"></el-input>
        <!--        <el-input v-model="selectedUser.uname" autocomplete="off" class="input"></el-input>-->
      </el-form-item>





      <el-form-item class="button">
        <el-button @click="submitForm(selectedNote)" color="#178071" size="large">
          <el-icon><Document /></el-icon>保存
        </el-button>

        <el-button @click="resetForm()" color="#d1c48f" size="large">
          <el-icon><Flag /></el-icon>重置
        </el-button>

      </el-form-item>


    </el-form>

  </el-dialog>

  <div style="margin-bottom: 30px" class="align-left-container">
    <el-input v-model="inputName" placeholder="输入问题名称" clearable style="width: 200px;margin-right: 5px"/>


    <el-button @click="searchForm()"  size="default" type="primary">
      <el-icon><Search /></el-icon>搜索
    </el-button>
  </div>
  <el-divider />


  <div class="button1">
    <AddQues></AddQues>
    <!--  <el-button @click="addUser()" color="#67b18e" size="large">-->
    <!--    <el-icon><Plus /></el-icon>新增普通用户-->
    <!--  </el-button>-->

    <el-button @click="deleteNotes()" color="#cb5765" size="large">
      <el-icon><Delete /></el-icon>批量删除
    </el-button>
  </div>

  <div >
    <!--      @selection-change="handleSelectionChange"-->
    <el-table :data="notes" border stripe @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" min-width="3%" max-width="3%" class="my-table"/>

      <el-table-column prop="Id"  label="Id" width="60" class="my-table"/>
      <el-table-column prop="name" label="问题" min-width="43.5%" max-width="43.5%" class="my-table"/>

      <el-table-column prop="note" label="回答" min-width="43.5%" max-width="43.5%" class="my-table"/>

      <el-table-column
          label="操作"

          min-width="10%" max-width="10%" class="my-table">
        <template #default="{ row }">

          <el-button
              @click="editNote(row)"
              type="text"
              size="default">
            编辑
          </el-button>

          <el-button
              @click="deleteNote(row)"
              type="text"
              size="default">
            移除
          </el-button>

        </template>
      </el-table-column>

    </el-table>
  </div>
  <!--    @current-change="handleCurrentChange"-->
  <el-pagination style="float: right;margin-top: 50px;margin-bottom: 80px"
                 background
                 layout="total, prev, pager, next, jumper"
                 @current-change="handleCurrentChange"
                 :page-size="pageSize"

                 :total="parseInt(total)">
  </el-pagination>
</el-card>
</template>

<script>
import {onMounted, ref} from "vue";
import axios from "axios";
import {ElMessageBox} from "element-plus";
import AddQues from "@/components/back/admin/sys/AddQues";

export default {
  name: "QuesAndAnswers",
  components: {AddQues},
  setup(){
    let notes = ref([]);
    let total = ref(0)
    let pageSize = ref(4)
    let search = ref(0)
    let inputName = ref('');
    let noteSelection = ref([])
    let selectedNote = ref({});
    const selectedNoteBackup = ref({});
    let dialogFormVisible = ref(false)



    const searchForm=()=>{
      search.value = 1
      console.log(inputName.value)
      axios.get('/sys/searchNote/' + pageSize.value + '/1', {
        params: {
          name: inputName.value,
        }}).then(resp => {
        if(resp &&  resp.data.code === 200){


          notes.value = resp.data.result.content
          total.value = resp.data.result.totalElements

          notes.value.forEach((item, index) => {
            item.Id = index + 1;
          });

          console.log("普通用户")
          console.log(resp.data.result)
        }
      })
    }

    const handleCurrentChange=(page)=>{
      if(search.value === 0){
        axios.get('/sys/noteInformation/' + pageSize.value + '/' + page).then(resp =>{
          if(resp &&  resp.data.code === 200){
            notes.value = resp.data.result.content
            total.value = resp.data.result.totalElements
            //console.log(resp.data.result)

            notes.value.forEach((item, index) => {
              item.Id = (page - 1) * pageSize.value + index + 1;
            });
          }
        })
      }
      else if(search.value === 1){
        console.log(inputName.value)
        axios.get('/sys/searchNote/' + pageSize.value + '/' + page, {
          params: {
            name: inputName.value,

          }}).then(resp => {
          if(resp &&  resp.data.code === 200){


            notes.value = resp.data.result.content
            total.value = resp.data.result.totalElements

            notes.value.forEach((item, index) => {
              item.Id = (page - 1) * pageSize.value + index + 1;
            });
            console.log("普通用户")
            console.log(resp.data.result)
          }
        })
      }

    }
    const listNotes=()=>{

      axios.get('/sys/noteInformation/' + pageSize.value + '/1').then(resp => {
        if(resp &&  resp.data.code === 200){
          console.log("返回数据")
          console.log(resp.data)
          notes.value = resp.data.result.content
          total.value = resp.data.result.totalElements

          notes.value.forEach((item, index) => {
            item.Id = index + 1;
          });


        }
      })
    }

    onMounted(() => {
      listNotes()

    });

    const deleteNote =(note)=>{

      ElMessageBox.confirm("确定要删除该问题吗？", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消', // 添加取消按钮
        //showCancelButton: false,
        closeOnClickModal: false,
        type: 'warning',
        draggable: true,
      }).then(()=>{
        console.log("修改问题",note)
        axios.put('/sys/deleteNote',
            {name: note.name}
        ).then(resp =>{
          if(resp &&  resp.data.code === 200){
            ElMessageBox.confirm(resp.data.result, '提示', {
              confirmButtonText: '确定',
              //cancelButtonText: '取消', // 添加取消按钮
              showCancelButton: false,
              closeOnClickModal: false,
              type: 'warning',
              draggable: true,
            }).then(()=>{

              window.location.reload()
            }).catch(() => {
              // 用户点击了取消按钮或关闭按钮后的处理逻辑
            });
          }
        })
      }).catch(() => {
        // 用户点击了取消按钮或关闭按钮后的处理逻辑
      });


    }

    const handleSelectionChange = (val) => {
      noteSelection.value = val.map(row => row.name);
      console.log(noteSelection.value)
    }



    const deleteNotes = () =>{
      ElMessageBox.confirm("确定要删除这些问题吗？", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消', // 添加取消按钮
        //showCancelButton: false,
        closeOnClickModal: false,
        type: 'warning',
        draggable: true,
      }).then(()=>{

        axios.put('/sys/batchDeleteNotes',
            noteSelection.value
        ).then(resp =>{
          if(resp &&  resp.data.code === 200){
            ElMessageBox.confirm(resp.data.result, '提示', {
              confirmButtonText: '确定',
              //cancelButtonText: '取消', // 添加取消按钮
              showCancelButton: false,
              closeOnClickModal: false,
              type: 'warning',
              draggable: true,
            }).then(()=>{

              window.location.reload()
            }).catch(() => {
              // 用户点击了取消按钮或关闭按钮后的处理逻辑
            });
          }
        })
      }).catch(() => {
        // 用户点击了取消按钮或关闭按钮后的处理逻辑
      });

    }
    const editNote =(Note)=>{
      dialogFormVisible.value = true

      selectedNote.value = {
        ...Note
      }
      // selectedSpec.value = { ...Spec } // 使用对象扩展运算符将 user 的属性值复制到新的对象中
      selectedNoteBackup.value ={
        ...Note
      }


    }
    const resetForm =()=>{

      Object.assign(selectedNote.value, selectedNoteBackup.value);
    }

    const submitForm =(note)=>{
console.log(note)
      ElMessageBox.confirm("确定要修改问题信息吗？", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消', // 添加取消按钮
        //showCancelButton: false,
        closeOnClickModal: false,
        type: 'warning',
        draggable: true,
      }).then(()=>{
        console.log("问题",note.value)
        console.log("问题",selectedNoteBackup.value.name)

        axios.put(`/sys/updateNoteInformation?name=${selectedNoteBackup.value.name}`,
            note
        ).then(resp =>{
          if(resp &&  resp.data.code === 200){
            ElMessageBox.confirm(resp.data.result, '提示', {
              confirmButtonText: '确定',
              //cancelButtonText: '取消', // 添加取消按钮
              showCancelButton: false,
              closeOnClickModal: false,
              type: 'warning',
              draggable: true,
            }).then(()=>{

              dialogFormVisible.value = false
              window.location.reload()
            }).catch(() => {
              // 用户点击了取消按钮或关闭按钮后的处理逻辑
            });
          }
        })
      }).catch(() => {
        // 用户点击了取消按钮或关闭按钮后的处理逻辑
      });

    }




    return{
listNotes,
      notes,
      pageSize,
      total,
      handleCurrentChange,
      inputName,
      searchForm,
      deleteNotes,
      handleSelectionChange,
      noteSelection,
      deleteNote,
      selectedNoteBackup,
      selectedNote,
      editNote,
      dialogFormVisible,
      resetForm,
      submitForm

    }
  }
}
</script>

<style scoped>
.item{
  width: 600px;
}
.input {
  margin-left: 30px; /* input 左侧外边距 */
}
.input1 {
  margin-left: 40px; /* input 左侧外边距 */
}
.button {
  display: flex;
  align-items: flex-start;
  margin-left: 30px;
  margin-bottom: 45px;

}
.button1 {
  display: flex;
  align-items: flex-start;
  /*margin-left: 30px;*/
  margin-bottom: 20px;

}
.my-table {
  height: 200px; /* 你想要的行高值 */

}
.align-left-container {
  display: flex;
  align-items: flex-start;
}
</style>