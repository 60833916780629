<template>
  <el-button color="#67b18e" size="large" @click="dialogFormVisible = true" style="margin-right: 5px"
  >
    <el-icon><Plus /></el-icon>批量添加测评题目
  </el-button>

  <el-dialog
      title="添加测评题目"
      v-model="dialogFormVisible"
      :before-close="addHandleDialogClose"
  >

    <!--User：被选中的用户(在点击编辑按钮时通过获得)     prop对应属性 -->
    <el-form :model="quesItem" style="text-align: left" ref="dataForm" label-width="100px"
             :rules="rules" status-icon>


      <el-form-item prop="coursesel" label="科目" >
        <el-select :teleported="false" v-model="quesItem.coursesel" clearable placeholder="选择科目" class="input">

          <el-option
              v-for="item in selectOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          />
        </el-select>
      </el-form-item>

      <el-form-item prop="quesName" label="所属测评" >
        <el-select :teleported="false" v-model="quesItem.quesName" clearable placeholder="选择测评" class="input">

          <el-option
              v-for="item in selectQuesOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          />
        </el-select>
      </el-form-item>


      <el-form-item prop="name" label="题目" class="item">
        <el-input v-model="quesItem.name" autocomplete="off" class="input" clearable></el-input>
        <!--        <el-input v-model="User.uname" autocomplete="off" class="input"></el-input>-->
      </el-form-item>


      <el-form-item prop="option1" label="选项1" class="item">
        <el-input v-model="quesItem.option1" autocomplete="off" class="input" clearable></el-input>
        <!--        <el-input v-model="User.uname" autocomplete="off" class="input"></el-input>-->
      </el-form-item>
      <el-form-item prop="score1" label="分值1" class="item">
        <el-input v-model="quesItem.score1" autocomplete="off" class="input" clearable></el-input>
        <!--        <el-input v-model="User.uname" autocomplete="off" class="input"></el-input>-->
      </el-form-item>

      <el-form-item prop="option2" label="选项2" class="item">
        <el-input v-model="quesItem.option2" autocomplete="off" class="input" clearable></el-input>
        <!--        <el-input v-model="User.uname" autocomplete="off" class="input"></el-input>-->
      </el-form-item>
      <el-form-item prop="score2" label="分值2" class="item">
        <el-input v-model="quesItem.score2" autocomplete="off" class="input" clearable></el-input>
        <!--        <el-input v-model="User.uname" autocomplete="off" class="input"></el-input>-->
      </el-form-item>

      <el-form-item prop="option3" label="选项3" class="item">
        <el-input v-model="quesItem.option3" autocomplete="off" class="input" clearable></el-input>
        <!--        <el-input v-model="User.uname" autocomplete="off" class="input"></el-input>-->
      </el-form-item>
      <el-form-item prop="score3" label="分值3" class="item">
        <el-input v-model="quesItem.score3" autocomplete="off" class="input" clearable></el-input>
        <!--        <el-input v-model="User.uname" autocomplete="off" class="input"></el-input>-->
      </el-form-item>

      <el-form-item prop="option4" label="选项4" class="item">
        <el-input v-model="quesItem.option4" autocomplete="off" class="input" clearable></el-input>
        <!--        <el-input v-model="User.uname" autocomplete="off" class="input"></el-input>-->
      </el-form-item>
      <el-form-item prop="score4" label="分值4" class="item">
        <el-input v-model="quesItem.score4" autocomplete="off" class="input" clearable></el-input>
        <!--        <el-input v-model="User.uname" autocomplete="off" class="input"></el-input>-->
      </el-form-item>

      <el-form-item prop="option5" label="选项5" class="item">
        <el-input v-model="quesItem.option5" autocomplete="off" class="input" clearable></el-input>
        <!--        <el-input v-model="User.uname" autocomplete="off" class="input"></el-input>-->
      </el-form-item>
      <el-form-item prop="score5" label="分值5" class="item">
        <el-input v-model="quesItem.score5" autocomplete="off" class="input" clearable></el-input>
        <!--        <el-input v-model="User.uname" autocomplete="off" class="input"></el-input>-->
      </el-form-item>

      <el-form-item class="button">
        <el-button @click="submitAddForm(quesItem)" color="#178071" size="large">
          <el-icon><Document /></el-icon>保存
        </el-button>

        <el-button @click="resetForm()" color="#d1c48f" size="large">
          <el-icon><Flag /></el-icon>重置
        </el-button>

      </el-form-item>
    </el-form>





  </el-dialog>
</template>

<script>
const {ref, onMounted, reactive, watch} = require("vue");
import axios from "axios";
import {ElMessageBox} from "element-plus";
export default {
  name: "AddQuesItem",
  props: {

    message: String,
  },
  setup(props){

    let dialogFormVisible = ref(false)
    let quesItem = ref({})



    const selectOptions = [
      {
        value: '物理',
        label: '物理',
      },
      {
        value: '化学',
        label: '化学',
      },
      {
        value: '生物',
        label: '生物',
      },
      {
        value: '政治',
        label: '政治',
      }
      ,{
        value: '地理',
        label: '地理',
      },
      {
        value: '历史',
        label: '历史',
      }
    ]
    let selectQuesOptions = ref(props.message)

    watch(() => props.message, (newValue) => {
      console.log(props.message);
      selectQuesOptions.value = newValue;
      console.log("测评所属",selectQuesOptions.value)
      // listQuesItem()
    });
    const validateCoursesel = (rule, value, callback) => {
      //手机号码校验(第一位为1，后十位为0-9任意数字)
      if (value.length === 0) {
        callback(new Error('请选择至少一个科目')); // 至少选择一个选项
      } else {
        callback(); // 验证通过
      }

    }
    const rules = reactive({
      name:[
        {required:true, message: '请输入题目', trigger: 'blur'},
        {min:2, message: '长度应该在2个字符之上',trigger: ['blur', 'change']},
      ],
      coursesel: [
        {required:true, message: '请选择科目', trigger: 'blur'},
        {
          validator: validateCoursesel,
          trigger: 'change', // 触发验证的时机
        },
      ],
      option1:[
        {required:true, message: '请输入选项1', trigger: 'blur'},
        {min:1, message: '长度应该在1个字符之上',trigger: ['blur', 'change']},
      ],
      score1:[
        {required:true, message: '请输入分值1', trigger: 'blur'},
        {min:1, message: '长度应该在1个字符之上',trigger: ['blur', 'change']},
      ],
      option2:[
        {required:true, message: '请输入选项2', trigger: 'blur'},
        {min:1, message: '长度应该在1个字符之上',trigger: ['blur', 'change']},
      ],
      score2:[
        {required:true, message: '请输入分值2', trigger: 'blur'},
        {min:1, message: '长度应该在1个字符之上',trigger: ['blur', 'change']},
      ],
      option3:[
        {required:true, message: '请输入选项3', trigger: 'blur'},
        {min:1, message: '长度应该在1个字符之上',trigger: ['blur', 'change']},
      ],
      score3:[
        {required:true, message: '请输入分值3', trigger: 'blur'},
        {min:1, message: '长度应该在1个字符之上',trigger: ['blur', 'change']},
      ],
      option4:[
        {required:true, message: '请输入选项4', trigger: 'blur'},
        {min:1, message: '长度应该在1个字符之上',trigger: ['blur', 'change']},
      ],
      score4:[
        {required:true, message: '请输入分值4', trigger: 'blur'},
        {min:1, message: '长度应该在1个字符之上',trigger: ['blur', 'change']},
      ],
      option5:[
        {required:true, message: '请输入选项5', trigger: 'blur'},
        {min:1, message: '长度应该在1个字符之上',trigger: ['blur', 'change']},
      ],
      score5:[
        {required:true, message: '请输入分值5', trigger: 'blur'},
        {min:1, message: '长度应该在1个字符之上',trigger: ['blur', 'change']},
      ]
    })
    const validateAllFields = (addQuesItem) => {
      const fields = Object.keys(rules);

      for (const field of fields) {
        const rules1 = rules[field];

        for (const rule of rules1) {
          if (rule.required) {
            if (!addQuesItem[field]) {
              return false; // 如果有字段未填写，返回 false
            }
          }
        }
      }

      return true; // 所有字段都填写完整，返回 true
    };

    const dataForm = ref(null);
    const resetForm = () => {
      // AddQuesItem ={
      //
      //
      // };
      // 重置表单并清除验证错误
      dataForm.value.resetFields();

    }
    const addHandleDialogClose=(done)=> {

      done();

      dataForm.value.resetFields();
    }
    const submitAddForm=(addQuesItem)=>{
      if (validateAllFields(addQuesItem)) {
        // 进行提交操作
        // ...
        //const router = useRouter();
        ElMessageBox.confirm('确定添加新题目？', '提示', {
          confirmButtonText: '确定',
          //cancelButtonText: '取消', // 添加取消按钮
          showCancelButton: false,
          closeOnClickModal: false,
          type: 'warning',
          draggable: true,
        }).then(()=>{

          console.log("addQuesItem.value",addQuesItem)
          axios.post(`/sys/addNewQuesItem`,addQuesItem
          ).then(resp => {

            // console.log("进来了")
            if(resp.data.code === 200){
              dialogFormVisible.value = false
              ElMessageBox.confirm('添加题目成功', '提示', {
                confirmButtonText: '确定',
                //cancelButtonText: '取消', // 添加取消按钮
                showCancelButton: false,
                closeOnClickModal: false,
                type: 'warning',
                draggable: true,
              }).then(()=>{

                window.location.reload()
              }).catch(() => {
                // 用户点击了取消按钮或关闭按钮后的处理逻辑
              });



            }else{
              console.log(resp.data)
              ElMessageBox.confirm(resp.data.message, '提示', {
                confirmButtonText: '确定',
                //cancelButtonText: '取消', // 添加取消按钮
                showCancelButton: false,
                closeOnClickModal: false,
                type: 'warning',
                draggable: true,
              }).then(()=>{

              }).catch(() => {
                // 用户点击了取消按钮或关闭按钮后的处理逻辑
              });


            }
          })
              .catch(failResponse => {})



        }).catch(() => {
          // 用户点击了取消按钮或关闭按钮后的处理逻辑
        });
      } else {
        // 提示用户有字段未填写
        // ...

        ElMessageBox.confirm("请填写所有表项！！！", '提示', {
          confirmButtonText: '确定',
          //cancelButtonText: '取消', // 添加取消按钮
          showCancelButton: false,
          closeOnClickModal: false,
          type: 'warning',
          draggable: true,
        })
      }




    }
    return{
      dialogFormVisible,
      rules,
      quesItem,
      selectOptions,
      selectQuesOptions,
      resetForm,
      dataForm,
      addHandleDialogClose,
      submitAddForm
    }
  }
}
</script>

<style scoped>

</style>