
<!--登录页面组件-->
<template>

  <body id="paper">

  <!--  表单-->
  <el-form :model="loginForm" :rules="rules" class="login-container" label-position="left" label-width="0px" v-loading="loading">

    <h3 class="login_title">系统登录</h3>
    <!--用户名、密码    -->
    <el-form-item prop="username">
      <el-input type="text" v-model="loginForm.username" auto-complete="off" placeholder="账号"></el-input>
    </el-form-item>

    <el-form-item prop="password">
      <el-input type="password" v-model="loginForm.password" auto-complete="off" placeholder="密码"></el-input>
    </el-form-item>

    <el-form-item prop="checkCode" style="margin-top: 0px; margin-bottom: 15px;width: 200%">
      <div style="display: flex; align-items: center; justify-content: space-between;">
        <div style="display: flex; align-items: center;width: 250px;">
          <el-input type="text" v-model="loginForm.checkCode" auto-complete="off" placeholder="验证码"></el-input>
        </div>
<!--        <div style="width: 60px;"></div>-->
        <div style="display: flex; align-items: center;">
          <el-image style="width: 100px; height: 30px;" :src="'http://localhost:8443/checkcode'"
           title="点击刷新"  :fit="fitMode" onclick="this.src = 'http://localhost:8443/checkcode?' + Math.random();"/>
        </div>
      </div>
    </el-form-item>


<!--    <div style="margin-top: 0px; margin-bottom: 15px; display: flex; justify-content: space-between;">-->
<!--      <div style="display: flex; align-items: flex-start;">-->
<!--        <el-avatar-->
<!--            src="/seqi.png"-->
<!--        />-->
<!--      </div>-->
<!--      <div style="display: flex; justify-content: flex-end;">-->
<!--        <el-radio-group v-model="loginForm.loginType" class="ml-4">-->
<!--          <el-radio label="SysUser" border>管理员</el-radio>-->
<!--          <el-radio label="SimpleUser" border>普通用户</el-radio>-->
<!--        </el-radio-group>-->
<!--      </div>-->
<!--    </div>-->

    <!--    没用-->
<!--    <el-checkbox class="login_remember" v-model="checked" label-position="left">-->
<!--      <span style="color: #505458">记住密码</span>-->
<!--    </el-checkbox>-->

    <el-form-item style="width:100%">

      <el-button type="primary" style="width: 30%;background: #505458;border: none" class="login-button" v-on:click="login">登录</el-button>

      <el-space :size="-5" spacer="|" style="height: 50%;">
        <router-link class="qxk-link" :to="{path:'/index'}">
          <el-button type="success" style="width: 80%;height: 80%; border: none;" >返回首页</el-button>
        </router-link>

        <router-link class="qxk-link" :to="{path:'/register'}">
          <el-button type="primary" style="width: 80%;height: 80%; border: none;" >立即注册</el-button>
        </router-link>
      </el-space>


    </el-form-item>

    <el-form-item prop="rememberMe" style="width:100%; margin-top: -15px">
    <el-checkbox v-model="loginForm.rememberMe" label="记住我" size="large" />
    </el-form-item>
  </el-form>
  </body>

</template>
<script>
import { ref } from 'vue';
import echarts from 'echarts';
import axios from 'axios';
import { ElMessage, ElMessageBox } from 'element-plus'
import {useRoute, useRouter} from "vue-router";
import { useStore } from 'vuex'

const {ElLoading} = require("element-plus");
export default {
  name: "Login",
  setup() {
    const fitMode = ref('scale-down');

    const rules = {
      username: [{ required: true, message: '用户名不能为空', trigger: 'blur' }],
      password: [{ required: true, message: '密码不能为空', trigger: 'blur' }],
      checkCode:[{ required: true, message: '验证码不能为空', trigger: 'blur' }],
    };

    const checked = ref(true);
    const username = ref('');
    let loginForm = ref({
      username: '',
      password: '',
      checkCode: '',
      rememberMe: false
      //loginType: ''
    });
    const customSvg = `
        <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
          <path d="
            M 30 15
            L 28 17
            M 25.61 25.61
            A 15 15, 0, 0, 1, 15 30
            A 15 15, 0, 1, 1, 27.99 7.5
            L 15 15
          " style="stroke-width: 4px; fill: rgba(0, 0, 0, 0)"/>
        </svg>
      `;
    const store = useStore()
    const loading = ref(false);
    const _this = this; // 保存 this 对象
    const router = useRouter();
    const route = useRoute()
    const login = async () => {
      // 登录方法的代码
      await axios.post('/com/login', {
        uname: loginForm.value.username,
        userPassword: loginForm.value.password,
        checkCode: loginForm.value.checkCode,
        rememberMe: loginForm.value.rememberMe
        // type: loginForm.value.loginType

      })
          .then(resp => {
            console.log(resp.data.result)
            if (resp.data.code === 200) {
              const data = resp.data.result;
              store.commit('login', data)
              let loadingInstance; // 用于保存 Loading 实例
              ElMessageBox.confirm(
                  resp.data.result + "登录成功",
                  'Success',
                  {
                    confirmButtonText: 'OK',
                    showCancelButton: false,
                    type: 'success',
                    draggable: true,

                  }
              ).then((action) => {
                // ElMessageBox.close();
                let loadingInstance;
                // 显示 Loading
                loadingInstance = ElLoading.service({
                  lock: true,
                  text: '正在登录中！',
                  customClass: 'custom-loading', // 设置自定义类名
                  customLoader: customSvg, // 设置自定义的 SVG 图标
                  background: 'rgba(255, 255, 255, 0.7)',
                });
                setTimeout(() => {
                  if (loadingInstance) {
                    loadingInstance.close();
                  }
                  // 在这里添加一个延时操作
                  // ...
                  var path = route.query.redirect;

                  // 在延时操作完成后执行你的逻辑
                  const newPath = path === '/' || path === undefined ? '/admin/welcome' : path;
                  window.location.href = newPath; // 更改路由路径并刷新页面
                }, 1500); // 在这里添加一个2秒的延时操作
              })
                //router.replace({path: path === '/' || path === undefined ? '/admin/welcome' : path})

                //router.replace('/sys/backIndex')


              //ResultFactory.buildSuccessResult(username);
              //public Result(int code, String message, Object data){
              //this.result = data; data就是用户名
              //_this.$store.commit('login', data)
              // this.$alert(resp.data.message, '提示',{
              //   confirmButtonText: '测试'
              // })

              //之前要转到但被登录页面拦截的地址
              //var path = _this.$route.query.redirect

              //this.$router.replace()，用于进行路由跳转
              //path是一个变量，表示目标路径，如果path等于'/'或者未定义（undefined），则将路径设置为'/admin/dashboard'；否则，
              // 将路径设置为path的值。
              //直接访问/login或未登录时点击个人中心

              //_this.$router.replace({path: path === '/' || path === undefined ? '/index': path})

            } else {
              ElMessageBox.confirm(
                  resp.data.message,
                  'warning',
                  {
                    confirmButtonText: 'OK',
                    showCancelButton: false,
                    type: 'error',
                    draggable: true,
                  }
              ).then(() => {
                loginForm.value.username = '';
                loginForm.value.password = '';
                loginForm.value.checkCode = '';
                //loginForm.value.loginType = '';
              })


            }
          })


    };

    return {
      fitMode,
      rules,
      checked,
      loginForm,
      loading,
      login,
      username,
    };
  }
};
</script>

<!--<script>-->
<!--import { ref } from 'vue'; // 导入 Vue 3 的 ref 函数-->
<!--//import { contain } from 'echarts/types/src/scale/helper'; // 导-->
<!--import echarts from 'echarts';-->
<!--export default {-->

<!--  name: "Login",-->

<!--  data(){-->

<!--    return{-->
<!--      fitMode: ref(echarts.graphic.contain),-->
<!--      rules:{-->
<!--        username: [{required: true, message: '用户名不能为空', trigger: 'blur'}],-->
<!--        password: [{required: true, message: '密码不能为空', trigger: 'blur'}]-->
<!--      },-->
<!--      checked: true,-->
<!--      loginForm:{-->
<!--        username: '',-->
<!--        password: '',-->
<!--        loginType: '',-->
<!--        checkCode: '',-->
<!--      },-->

<!--      loading: false,-->

<!--    }-->
<!--  },-->
<!--  methods: {-->
<!--    //登录方法-->
<!--    login(){-->
<!--      var _this = this-->

<!--      this.$axios-->
<!--          .post('/login',{-->
<!--            username: this.loginForm.username,-->
<!--            password: this.loginForm.password,-->

<!--          })-->
<!--          .then(resp => {-->
<!--            if(resp.data.code === 200){-->

<!--              var data = resp.data.result-->
<!--              // var data = resp.data.result-->

<!--              //ResultFactory.buildSuccessResult(username);-->
<!--              //public Result(int code, String message, Object data){-->
<!--              //this.result = data; data就是用户名-->
<!--              _this.$store.commit('login', data)-->
<!--              // this.$alert(resp.data.message, '提示',{-->
<!--              //   confirmButtonText: '测试'-->
<!--              // })-->

<!--              //之前要转到但被登录页面拦截的地址-->
<!--              var path = _this.$route.query.redirect-->

<!--              //this.$router.replace()，用于进行路由跳转-->
<!--              //path是一个变量，表示目标路径，如果path等于'/'或者未定义（undefined），则将路径设置为'/admin/dashboard'；否则，-->
<!--              // 将路径设置为path的值。-->
<!--              //直接访问/login或未登录时点击个人中心-->
<!--              _this.$router.replace({path: path === '/' || path === undefined ? '/admin/dashboard': path})-->

<!--            }else{-->
<!--              this.$alert(resp.data.message, '提示',{-->
<!--                confirmButtonText: '确定'-->
<!--              })-->
<!--            }-->
<!--          })-->
<!--          .catch(failResponse => {-->

<!--          })-->



<!--    }-->
<!--  }-->
<!--}-->
<!--</script>-->
<style>
#paper {
  background: url("../../../public/login.png") no-repeat center;
  height: 100%;
  width: 100%;
  background-size: cover;
  position: fixed;
}
body{
  margin: 0;
}
.login-container {
  border-radius: 15px;
  background-clip: padding-box;
  margin: 90px auto;
  width: 350px;
  padding: 35px 35px 15px 35px;
  background: #fff;
  border: 1px solid #eaeaea;
  box-shadow: 0 0 25px #cac6c6;
}
.login_title {
  margin: 0px auto 40px auto;
  text-align: center;
  color: #505458;
}
.login-button {
  background: #505458;
  border: none;
  margin-right: 84px; /* 可选：为按钮之间添加一些间距 */
}
.login_remember {
  margin: 0px 0px 35px 0px;
  text-align: left;
}
</style>
