<template>
<!--  <el-button class="add-button" type="success" @click="dialogFormVisible = true">批量添加用户</el-button>-->
    <el-button color="#67b18e" size="large" @click="dialogFormVisible = true" style="margin-right: 15px">
      <el-icon><Plus /></el-icon>批量添加用户
    </el-button>

  <!--点击编辑按钮后呈现的界面  最开始默认是不可见的    -->
  <el-dialog
      title="新增普通用户"
      v-model="dialogFormVisible"
      :before-close="handleDialogClose">

    <!--selectedUser：被选中的用户(在点击编辑按钮时通过获得)     prop对应属性 -->
    <el-form :model="addUser" style="text-align: left" ref="dataForm" label-width="100px"
             :rules="rules" status-icon>
      <el-form-item prop="photo" label="默认头像"  class="item">
        <el-image :src="addUser.photo" :fit="fit" class="input"

                  :style="{ 'max-width': '100px', 'max-height': '100px' }"/>
<!--        <el-button type="primary" @click="resetPhoto(addUser)" class="input">{{notify_photo}}</el-button>-->
      </el-form-item>
      <el-form-item prop="uname" label="账号" class="item">
        <el-input v-model="addUser.uname" autocomplete="off" class="input" clearable></el-input>
        <!--        <el-input v-model="selectedUser.uname" autocomplete="off" class="input"></el-input>-->
      </el-form-item>

      <el-form-item prop="userName" label="姓名" class="item">
        <el-input v-model="addUser.userName" autocomplete="off" class="input" clearable></el-input>
      </el-form-item>

      <!--        重置密码功能-->
      <el-form-item label="密码"  prop="userPassword" class="item" >
        <el-input v-model="addUser.userPassword" autocomplete="off" class="input" clearable></el-input>
<!--        <el-button type="warning" @click="resetPassword(addUser.uname)" class="input">{{notify_password}}</el-button>-->
      </el-form-item>

      <el-form-item prop="userGender" label="性别" class="item">

        <el-select v-model="addUser.userGender"
                   :teleported="false" placeholder="选择性别" style="margin-right: 10px" class="input" >
          <el-option label="男" value="男" />
          <el-option label="女" value="女" />
        </el-select>
      </el-form-item>

      <el-form-item prop="userPhone" label="电话" class="item">
        <el-input v-model="addUser.userPhone" autocomplete="off" class="input" clearable></el-input>
      </el-form-item>
      <el-form-item prop="userBirth" label="出生日期" class="item">

        <el-date-picker
            v-model="addUser.userBirth"
            type="date"
            label="Pick a date"
            placeholder="请选择出生日期"
            style="width: 26%;margin-left: 30px"
            format="YYYY/MM/DD"
            value-format="YYYY-MM-DD"
        />
      </el-form-item>
      <el-form-item prop="userEmail" label="邮箱" class="item">
        <el-input v-model="addUser.userEmail" autocomplete="off" class="input" clearable></el-input>
      </el-form-item>
      <el-form-item prop="userAddress" label="地址" class="item">
        <el-input v-model="addUser.userAddress" autocomplete="off" class="input" clearable></el-input>
      </el-form-item>

<!--      <el-form-item class="button">-->
<!--        <el-button @click="submitForm(selectedUser)" color="#178071" size="large">-->
<!--          <el-icon><Document /></el-icon>保存-->
<!--        </el-button>-->

<!--        <el-button @click="resetForm(selectedUser)" color="#d1c48f" size="large">-->
<!--          <el-icon><Flag /></el-icon>重置-->
<!--        </el-button>-->

<!--      </el-form-item>-->

      <!--      <el-form-item label="修改用户角色" label-width="120px" prop="roles">-->
      <!--        &lt;!&ndash;<el-checkbox-group> 是多选框组来实现角色的显示与编辑，selectedRolesIds 是与多选框双向绑定的，我们通过点选就可以改变这个 Array 的值          &ndash;&gt;-->
      <!--        <div style="margin-top: 20px">-->
      <!--          <el-radio-group v-model="selectedUser.rid">-->
      <!--            <el-radio label="1" border>普通用户</el-radio>-->
      <!--            <el-radio label="2" border>管理员</el-radio>-->
      <!--          </el-radio-group>-->
      <!--        </div>-->
      <!--      </el-form-item>-->



    <!--取消和确定按钮-->
    <!--    <div slot="footer" class="dialog-footer">-->
    <!--      <el-button @click="dialogFormVisible = false">取 消</el-button>-->
    <!--      <el-button type="primary" @click="onSubmit(selectedUser)">确 定</el-button>-->
    <!--    </div>-->
    <el-form-item class="button">
      <el-button @click="submitForm(addUser)" color="#178071" size="large">
        <el-icon><Document /></el-icon>保存
      </el-button>

      <el-button @click="resetForm()" color="#d1c48f" size="large">
        <el-icon><Flag /></el-icon>重置
      </el-button>

    </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import {reactive, ref,onMounted } from "vue";
import {ElMessageBox} from "element-plus";
import axios from "axios";
import { defineProps } from 'vue';
export default {
  name: "AddUser",
  props: {
    message: String, // 声明接收名为 "message" 的 prop
  },
  setup(props){
    //const message = defineProps();
    let dialogFormVisible = ref(false)
    const fit = ref('scale-down')
    let addUser = reactive({
      photo: "http://localhost:8443/user/photo/default.png",
      userPassword: "123456",
      uname: '',
      userName: '',
      userGender: '',
      userPhone: '',
      userEmail: '',
      userBirth: '',
      userAddress: '',


    });
    const defaultUser = ref({
      photo: "http://localhost:8443/user/photo/default.png",
      userPassword: "123456",

    });
    const selectOptions = [
      {
        value: '男',
        label: '男',
      },
      {
        value: '女',
        label: '女',
      }]

    const validatePhone = (rule, value, callback) => {
      //手机号码校验(第一位为1，后十位为0-9任意数字)
      const reg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;

      if (!reg.test(value)) {
        callback(new Error('手机号码格式错误'))
      }else {
        // 如果密码不为空，则触发回调函数，表示验证通过
        callback();
      }
    }
    const rules = reactive({
      uname:[
        {required:true, message: '请输入用户名', trigger: 'blur'},
        {min:3, max: 7, message: '长度应该在3-7个字符之间',trigger: 'blur'},
      ],
      userGender:[
        {required:true, message: '请选择性别', trigger: ['blur', 'change']},

      ],userPassword:[
        {required:true, message: '请输入密码', trigger: 'blur'},

      ],
      userName:[
        {required:true, message: '请输入姓名', trigger: 'blur'},
        {min:2, max: 10, message: '长度应该在4-10个字符之间',trigger: 'blur'},
      ],
      userPhone: [
        {required:true, message: '请输入联系电话', trigger: 'blur'},
        { validator: validatePhone, trigger: ['blur', 'change'] },

      ],
      userBirth:[{required:true, message: '请选择出生日期', trigger: 'blur'},],
      userEmail:[
        {required:true, message: '请输入邮箱地址', trigger: 'blur'},
        { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
      ],
      userAddress:[
        {required:true, message: '请输入联系地址', trigger: 'blur'},
        {min:2, max: 50, message: '长度应该在4-50个字符之间',trigger: ['blur', 'change']},
      ]
    })
    const validateAllFields = (addUser) => {
      const fields = Object.keys(rules);

      for (const field of fields) {
        const rules1 = rules[field];

        for (const rule of rules1) {
          if (rule.required) {
            if (!addUser[field]) {
              return false; // 如果有字段未填写，返回 false
            }
          }
        }
      }

      return true; // 所有字段都填写完整，返回 true
    };
    const resetForm = () => {
      addUser.value = {
        photo: "http://localhost:8443/user/photo/default.png",
        userPassword: "123456"
      }
      // 重置表单并清除验证错误
      dataForm.value.resetFields();

     }

const dataForm = ref(null);

    // 在组件渲染后获取对表单组件的引用
    // onMounted(() => {
    //   dataForm.value = this.$refs.dataForm;
    // });
    const handleDialogClose=(done)=> {

      done(); // 关闭弹出框
      // notify_password.value ='重置密码'
      // notify_photo.value = '重置头像'
      addUser.value = defaultUser.value
      // Object.keys(rules).forEach((prop) => {
      //   dataForm.value.clearValidate(prop);
      // }); // 清除表单验证错误信息
      dataForm.value.resetFields();
    }

    const submitForm=(addUser)=>{

      if(validateAllFields(addUser)){
        //const router = useRouter();
        ElMessageBox.confirm('确定创建新的用户？', '提示', {
          confirmButtonText: '确定',
          //cancelButtonText: '取消', // 添加取消按钮
          showCancelButton: false,
          closeOnClickModal: false,
          type: 'warning',
          draggable: true,
        }).then(()=>{

          console.log("adduser", addUser)
          // let temp = addUser.userBirth.split('T')[0]
          //console.log("temp"+temp)
          if(props.message === "Hello from Parent"){
            axios.post('/user/registerAdministrators', {
              uname : addUser.uname,
              userName : addUser.userName,
              userPassword: addUser.userPassword,
              userGender : addUser.userGender,
              userPhone : addUser.userPhone,
              userEmail : addUser.userEmail,
              userBirth : addUser.userBirth,
              userAddress : addUser.userAddress,
              photo : addUser.photo

            }).then(resp => {
              // console.log(resp.data)
              if(resp.data.code === 200){
                dialogFormVisible.value = false
                ElMessageBox.confirm('创建管理员用户成功', '提示', {
                  confirmButtonText: '确定',
                  //cancelButtonText: '取消', // 添加取消按钮
                  showCancelButton: false,
                  closeOnClickModal: false,
                  type: 'warning',
                  draggable: true,
                }).then(()=>{

                  addUser.value = defaultUser.value
                  Object.keys(rules).forEach((prop) => {
                    dataForm.value.clearValidate(prop);
                  }); // 清除表单验证错误信息
                  window.location.reload()
                }).catch(() => {
                  // 用户点击了取消按钮或关闭按钮后的处理逻辑
                });
              }else{
                console.log(resp.data)
                ElMessageBox.confirm(resp.data.message, '提示', {
                  confirmButtonText: '确定',
                  //cancelButtonText: '取消', // 添加取消按钮
                  showCancelButton: false,
                  closeOnClickModal: false,
                  type: 'warning',
                  draggable: true,
                }).then(()=>{

                }).catch(() => {
                  // 用户点击了取消按钮或关闭按钮后的处理逻辑
                });


              }
            })
                .catch(failResponse => {})
          }else{
            axios.post('/user/registerOrdinaryUser', {
              uname : addUser.uname,
              userName : addUser.userName,
              userPassword: addUser.userPassword,
              userGender : addUser.userGender,
              userPhone : addUser.userPhone,
              userEmail : addUser.userEmail,
              userBirth : addUser.userBirth,
              userAddress : addUser.userAddress,
              photo : addUser.photo

            }).then(resp => {
              // console.log(resp.data)
              if(resp.data.code === 200){
                dialogFormVisible.value = false
                ElMessageBox.confirm('创建新用户成功', '提示', {
                  confirmButtonText: '确定',
                  //cancelButtonText: '取消', // 添加取消按钮
                  showCancelButton: false,
                  closeOnClickModal: false,
                  type: 'warning',
                  draggable: true,
                }).then(()=>{
                  // dialogFormVisible = false
                  addUser.value = defaultUser.value
                  Object.keys(rules).forEach((prop) => {
                    dataForm.value.clearValidate(prop);
                  }); // 清除表单验证错误信息
                  window.location.reload()
                }).catch(() => {
                  // 用户点击了取消按钮或关闭按钮后的处理逻辑
                });



              }else{
                console.log(resp.data)
                ElMessageBox.confirm(resp.data.message, '提示', {
                  confirmButtonText: '确定',
                  //cancelButtonText: '取消', // 添加取消按钮
                  showCancelButton: false,
                  closeOnClickModal: false,
                  type: 'warning',
                  draggable: true,
                }).then(()=>{

                }).catch(() => {
                  // 用户点击了取消按钮或关闭按钮后的处理逻辑
                });


              }
            })
                .catch(failResponse => {})
          }


        }).catch(() => {
          // 用户点击了取消按钮或关闭按钮后的处理逻辑
        });
      }else{
        ElMessageBox.confirm("请填写所有表项！！！", '提示', {
          confirmButtonText: '确定',
          //cancelButtonText: '取消', // 添加取消按钮
          showCancelButton: false,
          closeOnClickModal: false,
          type: 'warning',
          draggable: true,
        })
      }



    }


    return{
dialogFormVisible,
      handleDialogClose,
      fit,
      addUser,
      selectOptions,
      rules,
      validatePhone,
      defaultUser,
      dataForm,
resetForm,
      submitForm,

    }
  }
}
</script>

<style scoped>
.input {
  margin-left: 30px; /* input 左侧外边距 */
}
.button {
  display: flex;
  align-items: flex-start;
  margin-left: 130px;
  margin-bottom: 45px;

}
</style>