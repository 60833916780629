<template>
  <body id="paper">
  <div class="container">
    <el-card class="error">

      <div>
        <div class="button flex justify-center items-center">

            <el-text tag="b" type="danger" size="large" >
              抱歉！无权限访问该页面……<br>
              <router-link to="/admin/welcome">
              点击返回欢迎页
              </router-link>
            </el-text>

        </div>
      </div>

    </el-card>
  </div>
  </body>
</template>

<script>
export default {
  name: "Limit",
  setup() {
    return {};
  },
};
</script>

<style scoped>
#paper {
  background: url("../../../public/limit.jpg") no-repeat center;
  height: 100%;
  width: 100%;
  background-size: cover;
  position: fixed;
}

body {
  margin: 0;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* 可根据需要设置容器高度 */
}

.error {
  border-radius: 15px;
  border: 1px solid #eaeaea;
  box-shadow: 0 0 25px #cac6c6;
  width: 25%;
  height: 25%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.button {
  margin-top: auto; /* 将按钮在垂直方向上放置在 el-card 的底部中间 */
  margin-bottom: auto; /* 将按钮在垂直方向上放置在 el-card 的顶部中间 */
}
</style>