<template>
  <el-card  shadow="always">

    <div style="display: flex; flex-direction: column; align-items: flex-start;">
      <span style="font-size: 20px; font-weight: bold;">欢迎使用 高中学业选科系统</span>

      <p class="info-line">
        登录角色：&nbsp;&nbsp;{{ role }}
        <el-divider border-style="dashed" />
      </p>
      <p class="info-line">登录用户：&nbsp;&nbsp;{{userName}}
        <el-divider border-style="dashed" />
      </p>

      <div style="display: flex; align-items: center; ">
        <p style="margin: 0;">用户头像：&nbsp;&nbsp;</p>
        <el-avatar :src="img" :fit="fit"/>
      </div>
    </div>

  </el-card>

</template>

<script>
import {onMounted, ref} from "vue";
import axios from "axios";

export default {
  name: "Welcome",
  setup(){
const role = ref("")
    const userName = ref("")
    const img =  ref("")
    const fit = ref('scale-down');
    const getInformation = async () => {
      console.log("获取信息",img.value)
      try {
        const resp = await axios.get('/com/information');
        if (resp && resp.data.code === 200) {
console.log("图片进入",img.value)
          userName.value = resp.data.result.userName
          role.value = resp.data.result.nameZh
          img.value = resp.data.result.photo
//           console.log("back")
// console.log(userName.value)
//           console.log(role.value)
        }else{
          console.log("图片获取失败",img.value)
        }
      } catch (error) {
        // 处理错误
      }
    };

    onMounted(() => {
      getInformation();
    });
return{
  role,
  userName,
  img,
  fit
}
  }
}
</script>

<style scoped>
.info-line {
  margin-top: 15px; /* 调整段落元素上下边距 */
  margin-bottom: -10px;
}
</style>