<template>
  <el-menu
      class="menu"
      :default-active="currentPath"
      router
      mode="horizontal"
      active-text-color="#ffd04b"
      background-color="#545c64"
      text-color="#fff"
  >


<!--     :style="{ minWidth: '407px', width: '84%' }"-->
    <span class="hide-on-small-screen" style="color: #fff; position: absolute;
    padding-top: 13px; right: 40%;font-size: 20px; font-weight: bold">
      高中学业选科系统
    </span>

    <el-menu-item v-for="(item, i) in navList1" :key="i" :index="item.name">
      <div>{{ item.navItem }}</div>
    </el-menu-item>

    <div class="hide-on-small-screen2" v-if="!username">
      <el-menu-item    index="/register" style="position: absolute;right: 70px;">
        <div >注册</div>
      </el-menu-item>

      <el-menu-item    index="/login"  style="position: absolute;right: 0%;">
        <div >登录</div>
      </el-menu-item>

    </div>

<!--        const navList2 = [
      { name: '/register', navItem: '注册' },
      { name: '/login', navItem: '登录' },
    ];-->

<!--<div class="hide-on-small-screen2">-->
<!--    <el-menu-item  v-for="(item, i) in (username ? [] : navList2)" :key="i" :index="item.name"-->
<!--                  :style="{ position: 'absolute', right: i === 0 ? '4%' : '0%'}">-->
<!--      <div >-->
<!--        {{ item.navItem }}-->
<!--      </div>-->
<!--    </el-menu-item>-->

<!--    </div>-->
<!--    const logout1 = [{ name: '', navItem: '退出登录' }];-->
<!--    const img = ref([{ name: '', navItem: '欢迎您:', imgSrc: "", path:"admin/welcome"}]);-->


    <div class="hide-on-small-screen3" v-if="username">
      <!-- 第一个菜单项，显示在右侧 -->
      <el-menu-item  index="/admin/welcome" style=" position: absolute;right: 90px;"  >

          <el-image :src="img.imgSrc"  :fit="fitMode" style="margin-bottom: 8px;margin-right: 8px;"
                    :style="{ 'max-width': '50px', 'max-height': '50px' }"/>


          {{ img.navItem }}{{ img.name }}

      </el-menu-item>


      <!-- 第二个菜单项，显示在右侧 -->
      <el-menu-item  :index="logout1.name"  @click="logout" style="position: absolute;right: 0%;">
        <div>{{ logout1.navItem }}</div>
      </el-menu-item>

    </div>
<!--    <div class="right-aligned-container" v-if="windowWidth >= 500">-->
<!--      &lt;!&ndash; 第一个菜单项，显示在右侧 &ndash;&gt;-->
<!--      <el-menu-item v-for="(item, i) in (username ? img : [])" :key="i" :index="item.path" class="menu-item">-->
<!--        <div class="menu-item-content">-->
<!--          <el-image :src="item.imgSrc" class="menu-item-image" :fit="fitMode" style="margin-bottom: 8px; margin-right: 8px" :style="{ 'max-width': '50px', 'max-height': '50px' }"/>-->
<!--        </div>-->
<!--        <div class="menu-item-text">-->
<!--          {{ item.navItem }}{{ item.name }}-->
<!--        </div>-->
<!--      </el-menu-item>-->
<!--    </div>-->
<!--    <div class="right-aligned-container1" v-if="windowWidth >= 1000">-->
<!--      &lt;!&ndash; 第二个菜单项，显示在右侧 &ndash;&gt;-->
<!--      <el-menu-item v-for="(item, i) in (username ? logout1 : [])" :key="i" :index="item.name" class="menu-item" @click="logout">-->
<!--        <div>{{ item.navItem }}</div>-->
<!--      </el-menu-item>-->
<!--    </div>-->

  </el-menu>
</template>

<script>
import { useRoute, useRouter } from 'vue-router';
import { mapState } from 'vuex';
import {useStore} from 'vuex'
import { ref, onMounted } from 'vue';
import axios from 'axios';
import {ElMessage,  ElMessageBox } from 'element-plus';
import store from '@/store';

export default {
  name: 'NavMenu',
  setup() {

    // const windowWidth = ref(window.innerWidth);
    // const handleResize = () => {
    //   windowWidth.value = window.innerWidth;
    // };
    // window.addEventListener('resize', handleResize);

    const fitMode = ref('scale-down');
    const username = ref(store.state.username);
    console.log(2)
    console.log(username)
    const avtivecolor = ref({});
    const navList1 = [
      { name: '/index', navItem: '首页' },
      { name: '/listQues', navItem: '测评' },
      { name: '/listQxk', navItem: '高考6选3' },
      { name: '/listNotes', navItem: '咨询' },
      { name: '/about', navItem: '关于我们' },
    ];
    const navList2 = [
      { name: '/register', navItem: '注册' },
      { name: '/login', navItem: '登录' },
    ];
    const loginList = [
      { name: '/index', navItem: '首页' },
      { name: '/listQues', navItem: '测评' },
      { name: '/listQxk', navItem: '高考6选3' },
      { name: '/listNotes', navItem: '咨询' },
      { name: '/about', navItem: '关于我们' },
    ];
    const logout1 = { name: '', navItem: '退出登录' };
    const img = ref({ name: '', navItem: '欢迎您:', imgSrc: ""});

    const currentPath = useRoute().path;
    const router = useRouter();
    const store1 = useStore()

    const logout = () => {

      ElMessageBox.confirm('确认退出系统吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消', // 添加取消按钮
        // showCancelButton: false,
        type: 'warning',

      }).then(() => {
        axios.get('/com/logout').then(resp => {
          if (resp && resp.data.code === 200) {
            //resp.data.result
            store1.commit('logout');
            store1.state.username = ''
            store1.state.Menus = ''
            store1.state.flag = false
            router.push('/index');
            location.reload();
          }
        }).catch(failResponse => {
        });

      }).catch(() => {
        ElMessage({
          type: 'info',
          message: '取消退出账户',
        })
      })


    };
    // const logout = () => {
    //   axios.get('/com/logout').then(resp => {
    //     if (resp && resp.data.code === 200) {
    //       ElMessageBox.confirm(resp.data.result, 'Success', {
    //         confirmButtonText: '确定',
    //         cancelButtonText: '取消', // 添加取消按钮
    //         // showCancelButton: false,
    //         type: 'success',
    //         draggable: true,
    //       }).then(() => {
    //         store.commit('logout');
    //         router.push('/index');
    //         location.reload();
    //       }).catch(() => {
    //         ElMessage({
    //           type: 'info',
    //           message: '取消退出账户',
    //         })
    //       })
    //     }
    //   }).catch(failResponse => {});
    // };

    // 获取数据的方法
    const getTypeAndImg = async () => {
      try {
        const resp = await axios.get('/com/status');
        if (resp && resp.data.code === 200) {
          console.log(resp.data);
          img.value.name = resp.data.result.name
          img.value.imgSrc = resp.data.result.img
          console.log("aaaaa")
          // 更新响应式变量的值
          username.value = resp.data.result.name;
        }
      } catch (error) {
        // 处理错误
      }
    };

    onMounted(() => {
      getTypeAndImg();
    });

    return {
      avtivecolor,
      navList1,
      navList2,
      loginList,
      logout1,
      img,
      currentPath,
      logout,
      username,
      fitMode,
      //windowWidth
    };
  },
};
</script>

<style scoped>
.menu {
  width: 84%;
  margin: 0 auto;
  /*position: relative;*/
}

.right-aligned-container {
  position: absolute;
  top: 0;
  right: 9%;
  display: flex;
  align-items: center;
}
.right-aligned-container1 {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
}
.menu-item {
  display: flex;
  align-items: center;
  color: #eebe77;
}
@media (max-width: 1200px) {
  .hide-on-small-screen {
    display: none;
  }
}
@media (max-width: 680px) {
  .hide-on-small-screen2 {
    display: none;
  }
}
@media (max-width: 780px) {
  .hide-on-small-screen3 {
    display: none;
  }
}
/*!* 适用于小屏幕 *!*/
/*@media (max-width: 800px) {*/
/*  .right-aligned-container {*/
/*    display: none;*/
/*  }*/
/*}*/

</style>

<!--<template>-->
<!--<el-menu-->
<!--    class="menu"-->
<!--    :default-active="currentPath"-->
<!--    router-->
<!--    mode="horizontal"-->
<!--    active-text-color="#ffd04b"-->
<!--    background-color="#545c64"-->
<!--    text-color="#fff"-->
<!--    style="min-width: 1000px;"-->
<!--&gt;-->
<!--&lt;!&ndash;background-color="white" text-color="#222"active-text-color="red"&ndash;&gt;-->
<!--  <span style= " color: #fff;position: absolute;padding-top: 13px;right: 40%;font-size: 20px;font-weight: bold">高中学业选课系统</span>-->

<!--  <el-menu-item  v-for="(item,i) in  (username ? loginList : navList1)"  :key="i" :index="item.name"-->
<!--                  >-->
<!--    <div   >-->
<!--      {{item.navItem}}-->
<!--    </div>-->
<!--  </el-menu-item>-->
<!--  <el-menu-item  v-for="(item,i) in  (username ? [] : navList2)"  :key="i" :index="item.name"-->
<!--                 style= " left: 1000px;"           >-->
<!--    <div   >-->
<!--      {{item.navItem}}-->
<!--    </div>-->
<!--  </el-menu-item>-->
<!--  <el-menu-item  v-for="(item,i) in  (username ? img : [])"  :key="i" :index="item.name"-->
<!--                 style= " color: #eebe77;left: 900px;"        >-->
<!--    -->
<!--    <div   >-->
<!--      {{item.navItem}}{{item.name}}-->
<!--    </div>-->
<!--  </el-menu-item>-->
<!--  <el-menu-item  v-for="(item,i) in  (username ? logout1 : [])"  :key="i" :index="item.name"-->
<!--                 style= " color: #eebe77;left: 960px;"   @click="logout"         >-->
<!--    <div   >-->
<!--      {{item.navItem}}-->
<!--    </div>-->
<!--  </el-menu-item>-->


<!--</el-menu>-->
<!--</template>-->

<!--<script>-->
<!--import {getCurrentInstance} from "vue";-->
<!--import {useRoute} from "vue-router";-->
<!--import { mapState } from 'vuex';-->
<!--import axios from "axios";-->
<!--import {ElMessageBox} from "element-plus";-->
<!--import store from "@/store";-->
<!--export default {-->
<!--  name: "NavMenu",-->
<!--  data(){-->
<!--    return{-->
<!--      avtivecolor: {},-->
<!--      navList1:[-->
<!--        {name: '/index', navItem:'首页'},-->
<!--        {name: '/listQues', navItem:'测评'},-->
<!--        {name: '/listQxk', navItem:'高考6选3'},-->
<!--        {name: '/listNotes', navItem:'咨询'},-->
<!--        {name: '/about', navItem:'关于我们'},-->

<!--      ],-->
<!--      navList2:[-->
<!--        {name: '/register', navItem:'注册'},-->
<!--        {name: '/login', navItem:'登录'},-->
<!--      ],-->
<!--      loginList:[-->
<!--        {name: '/index', navItem:'首页'},-->
<!--        {name: '/listQues', navItem:'测评'},-->
<!--        {name: '/listQxk', navItem:'高考6选3'},-->
<!--        {name: '/listNotes', navItem:'咨询'},-->
<!--        {name: '/about', navItem:'关于我们'},-->
<!--      ],-->
<!--      logout1:[-->
<!--        {name: '', navItem:'退出登录'},-->
<!--      ],-->
<!--      img:[-->
<!--        {name: '', navItem:'欢迎您:'},-->
<!--      ]-->

<!--    }-->
<!--  },-->
<!--  computed:{-->
<!--    ...mapState(['username']),-->
<!--    currentPath(){-->
<!--      const route = useRoute()-->
<!--      var x = route.path.indexOf('/', 1)-->
<!--      if(x != -1){-->
<!--        return route.path.substring(0, x)-->
<!--      }else{-->
<!--        return route.path-->
<!--      }-->
<!--    }-->
<!--  },-->
<!--  mounted() {-->
<!--    this.getTypeAndImg();-->
<!--  },-->
<!--  methods: {-->
<!--    logout(){-->
<!--      var _this = this-->
<!--      axios.get('/com/logout').then(resp => {-->
<!--        if(resp && resp.data.code === 200){-->
<!--          ElMessageBox.confirm(-->
<!--              resp.data.result,-->
<!--              'Success',-->
<!--              {-->
<!--                confirmButtonText: 'OK',-->
<!--                showCancelButton: false,-->
<!--                type: 'success',-->
<!--                draggable: true,-->
<!--              }-->
<!--          ).then(resp =>{-->


<!--            store.commit('logout')-->

<!--            //_this.$router.replace('/index')-->
<!--            _this.$router.push('/index')-->
<!--            location.reload()-->
<!--            //location.reload()-->
<!--          })-->

<!--          // 清空路由，防止路由重复加载-->
<!--          // const newRouter = createRouter()-->
<!--          // router.matcher = newRouter.matcher-->
<!--        }-->
<!--      }).catch(failResponse => {})-->
<!--    },-->
<!--    getTypeAndImg(){-->
<!--      var _this = this-->


<!--          axios.get('/com/status').then(resp => {-->
<!--            if(resp && resp.data.code === 200){-->
<!--              console.log(resp.data)-->
<!--              this.img[0].name = resp.data.result.name-->
<!--            }-->


<!--          }).catch(failResponse => {})-->



<!--        },-->

<!--// 鼠标"悬停"触发此方法-->
<!--    Mouseover(i) {-->
<!--      // console.log("进入 悬停区域");-->
<!--      this.avtivecolor[i] = true; // 进入 悬停区域,更改文字颜色-->
<!--    },-->

<!--// 鼠标"离开"触发此方法-->
<!--    Mouseleave(i) {-->
<!--      //console.log("离开 悬停区域");-->
<!--      this.avtivecolor[i] = false; // 离开 悬停区域,文字颜色复原-->
<!--    },-->
<!--    getClassNames(i) {-->
<!--      return {-->
<!--        'hovered': this.avtivecolor[i]-->
<!--      };-->

<!--    }-->

<!--  }-->
<!--}-->
<!--</script>-->

<!--<style scoped>-->
<!--.menu {-->
<!--  width: 84%; /* 设置文本区域宽度 */-->
<!--  margin: 0 auto; /* 居中文本区域 */-->
<!--  /*padding: 10px; !* 可选：添加内边距来制造空白 *!*/-->

<!--}-->
<!--/*!* 子按钮的默认样式 *!*/-->
<!--/*.menu-item {*/-->
<!--/*  color: black;*/-->
<!--/*}*/-->
<!--/*.hovered {*/-->
<!--/*  background-color: #f0f0f0;*/-->
<!--/*  !*color: blue;*!*/-->
<!--/*}*/-->


<!--</style>-->