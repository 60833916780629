<template>
  <el-button color="#67b18e" size="large" @click="dialogFormVisible = true" style="margin-right: 15px">
    <el-icon><Plus /></el-icon>批量添加专业
  </el-button>

  <!--点击编辑按钮后呈现的界面  最开始默认是不可见的    -->
  <el-dialog
      title="新增选科专业"
      v-model="dialogFormVisible"
      @opened="onDialogOpened"
      :before-close="handleDialogClose">

    <!--selectedUser：被选中的用户(在点击编辑按钮时通过获得)     prop对应属性 -->
    <el-form :model="addSpeciality" style="text-align: left" ref="dataForm" label-width="100px"
             :rules="rules" status-icon>

      <el-form-item prop="name" label="专业名称" class="item">
        <el-input v-model="addSpeciality.name" autocomplete="off" class="input"></el-input>
        <!--        <el-input v-model="selectedUser.uname" autocomplete="off" class="input"></el-input>-->
      </el-form-item>



      <el-form-item prop="kemu" label="专业要求" class="item">
        <!--          <el-input v-model="selectedSpec.kemu" autocomplete="off" class="input"></el-input>-->
        <div>
          <el-checkbox-group v-model="addSpeciality.kemu" class="input">
            <el-checkbox label="物理"  size="large"/>
            <el-checkbox label="化学"  size="large"/>
            <el-checkbox label="生物"  size="large"/>
            <el-checkbox label="政治"  size="large"/>
            <el-checkbox label="地理"  size="large"/>
            <el-checkbox label="历史"  size="large"/>

          </el-checkbox-group>
        </div>
      </el-form-item>


      <el-form-item class="button">
        <el-button @click="submitForm(addSpeciality)" color="#178071" size="large">
          <el-icon><Document /></el-icon>保存
        </el-button>

        <el-button @click="resetForm()" color="#d1c48f" size="large">
          <el-icon><Flag /></el-icon>重置
        </el-button>

      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import Vue, {onMounted, reactive, ref} from "vue";
import {ElMessageBox} from "element-plus";
import axios from "axios";

export default {
  name: "AddSpeciality",
  setup(){
    let dialogFormVisible = ref(false)

    let addSpeciality = ref({
      name:"",
      kemu:[]
    });


    const submitForm=(addSpeciality)=>{

     if(addSpeciality.name !== "" && addSpeciality.kemu.length !== 0){
       //const router = useRouter();
       ElMessageBox.confirm('确定创建新的选科专业？', '提示', {
         confirmButtonText: '确定',
         //cancelButtonText: '取消', // 添加取消按钮
         showCancelButton: false,
         closeOnClickModal: false,
         type: 'warning',
         draggable: true,
       }).then(()=>{

         console.log("addSpeciality", addSpeciality)
         // let temp = addUser.userBirth.split('T')[0]
         //console.log("temp"+temp)
         const medium = addSpeciality
         addSpeciality.value = {
           ...medium,
           kemu: medium.kemu.join(",")
         }

         axios.post('/sys/registerSpeciality', addSpeciality.value).then(resp => {
           // console.log(resp.data)
           if(resp.data.code === 200){
             dialogFormVisible.value = false
             console.log(resp.data)
             ElMessageBox.confirm(resp.data.result, '提示', {
               confirmButtonText: '确定',
               //cancelButtonText: '取消', // 添加取消按钮
               showCancelButton: false,
               closeOnClickModal: false,
               type: 'warning',
               draggable: true,
             }).then(()=>{

               addSpeciality ={
                 name:"",
                 kemu:[]
               }
               Object.keys(rules).forEach((prop) => {
                 dataForm.value.clearValidate(prop);
               }); // 清除表单验证错误信息
               window.location.reload()
             }).catch(() => {
               // 用户点击了取消按钮或关闭按钮后的处理逻辑
             });



           }else{
             console.log(resp.data)
             ElMessageBox.confirm(resp.data.message, '提示', {
               confirmButtonText: '确定',
               //cancelButtonText: '取消', // 添加取消按钮
               showCancelButton: false,
               closeOnClickModal: false,
               type: 'warning',
               draggable: true,
             }).then(()=>{

             }).catch(() => {
               // 用户点击了取消按钮或关闭按钮后的处理逻辑
             });


           }
         })
             .catch(failResponse => {})



       }).catch(() => {
         // 用户点击了取消按钮或关闭按钮后的处理逻辑
       });
     }else{
       ElMessageBox.confirm("请填写所有表项！！！", '提示', {
         confirmButtonText: '确定',
         //cancelButtonText: '取消', // 添加取消按钮
         showCancelButton: false,
         closeOnClickModal: false,
         type: 'warning',
         draggable: true,
       })
     }



    }


    const resetForm = () => {
      addSpeciality ={
        name:"",
        kemu:[]
      };
      // 重置表单并清除验证错误
      dataForm.value.resetFields();

    }
    const onDialogOpened= () => {


      //resetForm()
    };

    const dataForm = ref(null);
// addSpeciality.kemu = ["物理","化学","生物","","",""]; // 清空 kemu 数组
    const handleDialogClose=(done)=> {

      done();

      addSpeciality = ref({
        name: '',
        kemu: [],
      });

      // addSpeciality.name = ""; // 清空 name 字段
      // addSpeciality.kemu = []; // 清空 kemu 数组

      // Object.keys(rules).forEach((prop) => {
      //   dataForm.value.clearValidate(prop);
      // }); // 清除表单验证错误信息
      dataForm.value.resetFields();
    }

    const validateKemu = (rule, value, callback) => {
      //手机号码校验(第一位为1，后十位为0-9任意数字)
      if (value.length === 0) {
        callback(new Error('请选择至少一个专业')); // 至少选择一个选项
      } else {
        callback(); // 验证通过
      }

    }
    const rules = reactive({
      name:[
        {required:true, message: '请输入专业名称', trigger: 'blur'},
        {min:3, max: 7, message: '长度应该在3-7个字符之间',trigger: ['blur', 'change']},
      ],
      kemu: [
        {required:true, message: '请选择科目', trigger: 'blur'},
        {
          validator: validateKemu,
          trigger: 'change', // 触发验证的时机
        },
      ]
    })

    return{
      dialogFormVisible,
rules,
      addSpeciality,
      handleDialogClose,
      dataForm,
      resetForm,
      submitForm,
      onDialogOpened
    }
  }
}
</script>

<style scoped>
.input {
  margin-left: 30px; /* input 左侧外边距 */
}
.button {
  display: flex;
  align-items: flex-start;
  margin-left: 130px;
  margin-bottom: 45px;

}
</style>