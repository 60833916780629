<template>
  <el-table v-if="display" :data="myMessage" border style="width: 100%">
    <el-table-column prop="Id" label="id" width="60"/>
    <el-table-column prop="name" label="问题"/>
    <el-table-column prop="note" label="回答" />
  </el-table>
</template>

<script>
export default {
  name: "NoteResult",
  props:['myMessage', 'display'],
}
</script>

<style scoped>

</style>