<template>
  <el-card  shadow="always">
    <div class="header">
      <el-text class="align-left" type="primary">个人信息</el-text>
    </div >
    <el-divider/>
    <el-form
        ref="ruleFormRef"
        :model="ruleForm"
        :rules="rules"
        label-position="left"
        label-width="100px"
        class="demo-ruleForm"
        :size="formSize"
        status-icon

    >
<!--      <el-form-item label="头像上传" prop="photo">-->

<!--        <div class="upload-container">-->
<!--        <el-upload-->

<!--        list-type="picture-card"-->
<!--        :auto-upload="false"-->


<!--        >-->
<!--&lt;!&ndash;          <el-icon><Plus/></el-icon>&ndash;&gt;-->
<!--          <el-icon class="el-icon&#45;&#45;upload"><upload-filled /></el-icon>-->

<!--&lt;!&ndash;          <template #tip>&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="el-upload__tip">&ndash;&gt;-->
<!--&lt;!&ndash;              jpg/png 文件的大小要小于 500kb&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;          </template>&ndash;&gt;-->
<!--        </el-upload>-->
<!--          <div class="el-upload__text">-->
<!--            拖拽文件到此处或 <em>点击此处上传</em>-->
<!--          </div>-->
<!--          <el-image-->
<!--              v-if="previewUrl"-->
<!--              style="width: 100px; height: 100px"-->
<!--              :src="previewUrl"-->
<!--              :fit="fit"-->
<!--          />-->
<!--        </div>-->
<!--        <span style="margin-left: 40px; margin-right: 40px; margin-top: -210px">头像显示</span>-->
<!--        <el-image style="width: 200px; height: 200px;margin-top: -50px" :src="ruleForm.photo" :fit="fit" />-->

<!--      </el-form-item>-->

      <el-form-item label="照片:" prop="photo" class="avatar-uploader">
        <UploadImg @onUpload="uploadImg" ref="imgUpload"></UploadImg>



      </el-form-item>
      <el-image :src="ruleForm.photo" :fit="fit"

                :style="{ 'max-width': '200px', 'max-height': '200px' }"/>



      <el-form-item label="用户名" prop="uname">
        <el-input v-model="ruleForm.uname" clearable/>
      </el-form-item>
      <el-form-item label="姓名" prop="userName">
        <el-input v-model="ruleForm.userName" clearable/>
      </el-form-item>
      <el-form-item label="性别" prop="userGender">
        <el-select v-model="ruleForm.userGender" placeholder="请选择性别">
          <el-option label="男" value="男" />
          <el-option label="女" value="女" />
        </el-select>
      </el-form-item>
      <el-form-item label="联系电话" prop="userPhone">
        <el-input v-model="ruleForm.userPhone" clearable/>
      </el-form-item>
      <el-form-item label="邮箱" prop="userEmail">
        <el-input v-model="ruleForm.userEmail" clearable/>
      </el-form-item>

      <el-form-item label="出生日期" prop="userBirth" required>

        <el-date-picker
            v-model="ruleForm.userBirth"
            type="date"
            label="Pick a date"
            placeholder="请选择出生日期"
            style="width: 100%"
            format="YYYY/MM/DD"
            value-format="YYYY-MM-DD"
        />
      </el-form-item>
      <el-form-item label="联系地址" prop="userAddress">
        <el-input v-model="ruleForm.userAddress" clearable/>
      </el-form-item>
      <el-form-item class="button">
        <el-button @click="submitForm()" color="#178071" size="large">
          <el-icon><Document /></el-icon>保存
        </el-button>

        <el-button @click="resetForm()" color="#d1c48f" size="large">
          <el-icon><Flag /></el-icon>重置
        </el-button>

      </el-form-item>
    </el-form>



  </el-card>
</template>

<script>
import {onMounted, reactive, ref} from "vue";
import {ElMessageBox} from "element-plus";
import axios from "axios";
import UploadImg from "@/components/back/common/uploadImg";

export default {
  name: "Profile",
  components: {UploadImg},
  setup(){


    const fit = ref('scale-down')
const formSize = ref('default')

    const fileList = ref([]);
    const ruleForm = reactive({
     uname: '',
      userName: '',
      userGender: '',
      userPhone: '',
      userEmail: '',
      userBirth: '',
      userAddress: '',
      file:[],
      photo:'',
    })

const uploadImg= (data)=>{

      ruleForm.photo = data
  console.log("url",ruleForm.photo)
    }
    const handleImageUploaded=(data)=>{
ruleForm.file = data
      console.log("图片",ruleForm.file)
    }





    const getInformation=()=>{
      axios.get('/user/getInformation').then(resp => {
        // console.log(resp.data)
        if(resp.data.code === 200){
          ruleForm.uname = resp.data.result.uname
          ruleForm.userName = resp.data.result.userName
          ruleForm.userGender = resp.data.result.userGender
          ruleForm.userPhone = resp.data.result.userPhone
          ruleForm.userEmail = resp.data.result.userEmail
          ruleForm.userBirth = resp.data.result.userBirth
          ruleForm.userAddress = resp.data.result.userAddress
          ruleForm.photo = resp.data.result.photo
        }else{
          ElMessageBox.confirm("加载失败", '提示', {
            confirmButtonText: '确定',
            //cancelButtonText: '取消', // 添加取消按钮
            showCancelButton: false,
            closeOnClickModal: false,
            type: 'warning',
            draggable: true,
          }).then(()=>{
          }).catch(() => {
            // 用户点击了取消按钮或关闭按钮后的处理逻辑
          });
        }
      })
          .catch(failResponse => {})
    }
    onMounted(() => {
      getInformation();
    });


    const validatePhone = (rule, value, callback) => {
  //手机号码校验(第一位为1，后十位为0-9任意数字)
      const reg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;

      if (!reg.test(value)) {
        callback(new Error('手机号码格式错误'))
      }else {
        // 如果密码不为空，则触发回调函数，表示验证通过
        callback();
      }
    }
    const resetForm =()=>{
      console.log("photo")
      console.log(ruleForm.photo)
      getInformation();
    }

    const previewUrl = ref('');



    const rules = reactive({
     uname:[
       {required:true, message: '请输入用户名', trigger: 'blur'},
       {min:3, max: 7, message: '长度应该在3-7个字符之间',trigger: 'blur'},
     ],
      userGender:[
        {required:true, message: '请选择性别', trigger: 'blur'},

      ],
      userName:[
        {required:true, message: '请输入姓名', trigger: 'blur'},
        {min:2, max: 10, message: '长度应该在4-10个字符之间',trigger: 'blur'},
      ],
      userPhone: [
        {required:true, message: '请输入联系电话', trigger: 'blur'},
          { validator: validatePhone, trigger: ['blur', 'change'] },

      ],
      userBirth:[{required:true, message: '请选择出生日期', trigger: 'blur'},],
      userEmail:[
        {required:true, message: '请输入邮箱地址', trigger: 'blur'},
        { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
      ],
      userAddress:[
        {required:true, message: '请输入联系地址', trigger: 'blur'},
        {min:2, max: 50, message: '长度应该在4-50个字符之间',trigger: ['blur', 'change']},
      ],
      photo:[
        {required:true, message: '请上传用户头像', trigger: ['blur', 'change']},
      ]
    })

    const submitForm=()=>{

      //const router = useRouter();
      ElMessageBox.confirm('确定修改个人信息？', '提示', {
        confirmButtonText: '确定',
        //cancelButtonText: '取消', // 添加取消按钮
        showCancelButton: false,
        closeOnClickModal: false,
        type: 'warning',
        draggable: true,
      }).then(()=>{

        console.log(ruleForm.userBirth)
        //let temp = ruleForm.userBirth.split("T")[0]
        console.log(ruleForm)
        //console.log("temp",temp)

        axios.put('/user/modifyInformation', {
          uname : ruleForm.uname,
          userName : ruleForm.userName,
          userGender : ruleForm.userGender,
          userPhone : ruleForm.userPhone,
          userEmail : ruleForm.userEmail,
          userBirth : ruleForm.userBirth,
          userAddress : ruleForm.userAddress,
          photo : ruleForm.photo

        }).then(resp => {
          // console.log(resp.data)
          if(resp.data.code === 200){

            ElMessageBox.confirm('修改个人信息成功', '提示', {
              confirmButtonText: '确定',
              //cancelButtonText: '取消', // 添加取消按钮
              showCancelButton: false,
              closeOnClickModal: false,
              type: 'warning',
              draggable: true,
            }).then(()=>{
              window.location.reload()
            }).catch(() => {
              // 用户点击了取消按钮或关闭按钮后的处理逻辑
            });



          }else{

            ElMessageBox.confirm("提交失败", '提示', {
              confirmButtonText: '确定',
              //cancelButtonText: '取消', // 添加取消按钮
              showCancelButton: false,
              closeOnClickModal: false,
              type: 'warning',
              draggable: true,
            }).then(()=>{

            }).catch(() => {
              // 用户点击了取消按钮或关闭按钮后的处理逻辑
            });


          }
        })
            .catch(failResponse => {})

      }).catch(() => {
        // 用户点击了取消按钮或关闭按钮后的处理逻辑
      });



      }



    // const resetForm =()=>{
    //   ruleForm.oldPassword = "";
    //   ruleForm.newPassword = "";
    //   ruleForm.confirmPassword = "";
    // }
    const handleUploadSuccess = (response, file, fileList) => {
      // 提取上传成功后返回的文件 URL
      const uploadedImageUrl = response.data.url; // 请根据实际响应数据的字段名调整
      // 将 URL 赋值给 ruleForm.photo
      ruleForm.photo = uploadedImageUrl;
    };

    return{
      ruleForm,
      rules,
      formSize,
      handleUploadSuccess,
      submitForm,
      fit,
      previewUrl,
fileList,
      getInformation,
      resetForm,
      handleImageUploaded,
      uploadImg
    }
  }
}
</script>

<style scoped>
.header {
  display: flex;
  align-items: flex-start;
}
.demo-ruleForm{
  margin-left: 100px;
  width: 1000px;
}
.password {
  display: flex;
  align-items: flex-start;

  margin-bottom: 45px;

}

.button {
  display: flex;
  align-items: flex-start;
  margin-left: 100px;
  margin-bottom: 45px;

}
.align-left {
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  margin-right: 7px;
}
.input-label {
  text-align: right;
  margin-right: 30px; /* 可根据需要调整间距 */
  font-size: 16px;
  color: #81bb96;
  width: 70px;
}

.avatar-uploader {
  width: 320px;
  height: 150px !important;
  overflow: hidden;
}
</style>