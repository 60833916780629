<template>
  <el-button color="#67b18e" size="large" @click="dialogFormVisible = true" style="margin-right: 5px"
  >
    <el-icon><Plus /></el-icon>批量添加测评记录
  </el-button>


  <el-dialog
      title="添加测评记录"
      v-model="dialogFormVisible"
      :before-close="addHandleDialogClose"
  >

    <!--User：被选中的用户(在点击编辑按钮时通过获得)     prop对应属性 -->
    <el-form :model="quesUser" style="text-align: left" ref="dataForm" label-width="100px"
             :rules="rules" status-icon>


      <el-form-item prop="quesName" label="测评名称"  >
        <el-select :teleported="false" v-model="quesUser.quesName" clearable placeholder="选择测评" class="input">

          <el-option
              v-for="item in selectQuesOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          />
        </el-select>
      </el-form-item>

      <el-form-item prop="userName" label="用户" >
        <el-select :teleported="false" v-model="quesUser.userName" clearable placeholder="选择用户" class="input">

          <el-option
              v-for="item in selectUnameOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          />
        </el-select>
      </el-form-item>

      <el-form-item label="测评时间" prop="addDate" required>

        <el-date-picker
            v-model="quesUser.addDate"
            type="datetime"
            label="Pick a date and time"
            placeholder="请选择日期和时间"
            style="width: 100%"
            format="YYYY/MM/DD HH:mm:ss"
            value-format="YYYY-MM-DD HH:mm:ss"
        />
      </el-form-item>
      <el-form-item prop="score" label="测评结果" class="item">
        <el-input v-model="quesUser.score" autocomplete="off" class="input" clearable></el-input>
        <!--        <el-input v-model="selectedUser.uname" autocomplete="off" class="input"></el-input>-->
      </el-form-item>
      <el-form-item prop="result" label="专业推荐" class="item">
        <el-input v-model="quesUser.result" autocomplete="off" class="input" clearable></el-input>
        <!--        <el-input v-model="selectedUser.uname" autocomplete="off" class="input"></el-input>-->
      </el-form-item>



      <el-form-item class="button">
        <el-button @click="submitAddForm(quesUser)" color="#178071" size="large">
          <el-icon><Document /></el-icon>保存
        </el-button>

        <el-button @click="resetForm()" color="#d1c48f" size="large">
          <el-icon><Flag /></el-icon>重置
        </el-button>

      </el-form-item>
    </el-form>





  </el-dialog>






</template>

<script>
const {ref, onMounted, reactive, watch} = require("vue");
import axios from "axios";
import {ElMessageBox} from "element-plus";
export default {
  name: "AddQuesUser",
  props: {

    message1: String,
    message2: String,
  },
  setup(props){
    let dialogFormVisible = ref(false)
    let quesUser = ref({})

const dataForm = ref(null);

    let selectQuesOptions = ref(props.message1)
    let selectUnameOptions = ref(props.message2)
    watch(() => props.message1, (newValue) => {
      console.log(props.message1);
      selectQuesOptions.value = newValue;
      console.log("测评所属",selectQuesOptions.value)
      // listQuesItem()
    });

    watch(() => props.message2, (newValue) => {
      console.log(props.message2);
      selectUnameOptions.value = newValue;
      console.log("测评所属",selectUnameOptions.value)
      // listQuesItem()
    });


    const resetForm = () => {
      // AddQuesItem ={
      //
      //
      // };
      // 重置表单并清除验证错误
      dataForm.value.resetFields();

    }
    const addHandleDialogClose=(done)=> {

      done();

      dataForm.value.resetFields();
    }
    const validateQuesName = (rule, value, callback) => {
      //手机号码校验(第一位为1，后十位为0-9任意数字)
      if (value.length === 0) {
        callback(new Error('请选择至少一个测评')); // 至少选择一个选项
      } else {
        callback(); // 验证通过
      }

    }
    const validateUserName = (rule, value, callback) => {
      //手机号码校验(第一位为1，后十位为0-9任意数字)
      if (value.length === 0) {
        callback(new Error('请选择至少一个用户')); // 至少选择一个选项
      } else {
        callback(); // 验证通过
      }

    }
    const rules = reactive({
      // quesName:[
      //   {required:true, message: '请输入题目', trigger: 'blur'},
      //   {min:2, message: '长度应该在2个字符之上',trigger: ['blur', 'change']},
      // ],
      quesName:[{required:true, message: '请选择测评名称', trigger: 'blur'},
        {
          validator: validateQuesName,
          trigger: 'change', // 触发验证的时机
        },],
      userName:[{required:true, message: '请选择测评用户', trigger: 'blur'},
        {
          validator: validateUserName,
          trigger: 'change', // 触发验证的时机
        }],
      addDate:[{required:true, message: '请选择测评时间', trigger: 'blur'},],
      score:[
        {required:true, message: '请输入测评结果', trigger: 'blur'},
        {min:1, message: '长度应该在1个字符之上',trigger: ['blur', 'change']},
      ],
      result:[
        {required:true, message: '请输入专业推荐', trigger: 'blur'},
        {min:1, message: '长度应该在1个字符之上',trigger: ['blur', 'change']},
      ]

    })
    const validateAllFields = (QuesUser) => {
      const fields = Object.keys(rules);

      for (const field of fields) {
        const rules1 = rules[field];

        for (const rule of rules1) {
          if (rule.required) {
            if (!QuesUser[field]) {
              return false; // 如果有字段未填写，返回 false
            }
          }
        }
      }

      return true; // 所有字段都填写完整，返回 true
    };

    const submitAddForm=(QuesUser)=>{

      if(validateAllFields(QuesUser)){
        //const router = useRouter();
        ElMessageBox.confirm('确定添加新的记录？', '提示', {
          confirmButtonText: '确定',
          //cancelButtonText: '取消', // 添加取消按钮
          showCancelButton: false,
          closeOnClickModal: false,
          type: 'warning',
          draggable: true,
        }).then(()=>{
          console.log("QuesUser.value",QuesUser)

          axios.post(`/sys/addNewQuesUser`,QuesUser
          ).then(resp => {
            // console.log(resp.data)
            if(resp.data.code === 200){
              dialogFormVisible.value = false
              ElMessageBox.confirm('添加题目成功', '提示', {
                confirmButtonText: '确定',
                //cancelButtonText: '取消', // 添加取消按钮
                showCancelButton: false,
                closeOnClickModal: false,
                type: 'warning',
                draggable: true,
              }).then(()=>{


                window.location.reload()
              }).catch(() => {
                // 用户点击了取消按钮或关闭按钮后的处理逻辑
              });



            }else{
              console.log(resp.data)
              ElMessageBox.confirm(resp.data.message, '提示', {
                confirmButtonText: '确定',
                //cancelButtonText: '取消', // 添加取消按钮
                showCancelButton: false,
                closeOnClickModal: false,
                type: 'warning',
                draggable: true,
              }).then(()=>{

              }).catch(() => {
                // 用户点击了取消按钮或关闭按钮后的处理逻辑
              });


            }
          })
              .catch(failResponse => {})



        }).catch(() => {
          // 用户点击了取消按钮或关闭按钮后的处理逻辑
        });
      }else{
        ElMessageBox.confirm("请填写所有表项！！！", '提示', {
          confirmButtonText: '确定',
          //cancelButtonText: '取消', // 添加取消按钮
          showCancelButton: false,
          closeOnClickModal: false,
          type: 'warning',
          draggable: true,
        })
      }



    }

    return{
      quesUser,
      rules,
      selectUnameOptions,
      selectQuesOptions,
      dialogFormVisible,
      dataForm,
      resetForm,
      addHandleDialogClose,
      submitAddForm
    }
  }
}
</script>

<style scoped>

</style>